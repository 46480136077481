import React, { useRef } from 'react';
import { AlertDocument } from '../database/documents/AlertDocument';
import { translateCause } from '../utils';
import { useDispatch } from 'react-redux';
import { toggleSelectedPatientAlertResolved } from '../redux/selectedPatient/selectedPatientActions';
import { AlertTier } from '../database/schemas/Patient';

interface Props {
    className?: string;
    alert: AlertDocument;
}

export default function Alert(props: Props) {
    const { color, title } = (() => {
        const { tier } = props.alert.data;
        if (tier === AlertTier.tierTwo) {
            return { color: 'yellow', title: 'Moderate Alert' };
        } else if (tier === AlertTier.tierThree) {
            return { color: 'red', title: 'Severe Alert' };
        } else {
            return { color: 'blue', title: 'Informational Alert' };
        }
    })();
    const alertRef = useRef<HTMLDivElement>(null);
    const baseClassNames = `mr-3 font-semibold rounded-md border border-${color}-700 px-2 py-1`;
    const unresolvedClassName = `${baseClassNames} hover:text-white text-${color}-700 bg-${alert}-400 hover:bg-${color}-500`;
    const resolvedClassNames = `${baseClassNames} text-white bg-${color}-600 hover:bg-${color}-700`;
    const dispatch = useDispatch();

    const toggleResolved = () => {
        dispatch(toggleSelectedPatientAlertResolved(props.alert.id));
    };

    return (
        <div
            className={`bg-${color}-100
             border border-${color}-400 mb-2 text-${color}-900 px-4 py-3 rounded relative ${props.className}`}
            role="alert"
            ref={alertRef}
            style={{ height: '48px' }}
        >
            <b className={`font-bold mr-3 text-${color}-800`}>{title}</b>
            <span className="block sm:inline">{translateCause(props.alert.data.cause)}</span>
            <span className="absolute top-0 bottom-0 right-0 px-4 py-3 flex flex-row items-center">
                <button
                    className={props.alert.data.acknowledgedAt ? resolvedClassNames : unresolvedClassName}
                    onClick={toggleResolved}
                >
                    {props.alert.data.acknowledgedAt ? 'Mark unresolved' : 'Mark resolved'}
                </button>
            </span>
        </div>
    );
}
