import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../../redux/store';
import { selectSelectedPatientDocument } from '../../../../../redux/selectedPatient/selectedPatientSelectors';
import StorageManager from '../../../../../StorageManager';
import { downloadFile, getDateFromDateString } from '../../../../../utils';
import IconManager, { IconType } from '../../../../../components/IconManager';
import React from 'react';
import { selectSelectedOrganizationActiveOpioidAgreements } from '../../../../../redux/selectedOrganization/selectedOrganizationSelectors';
import { ReactSelectState, SelectOption } from '../../../../../types';
import { updatePatientDocumentProp } from '../../../../../redux/selectedPatient/selectedPatientActions';
import { PatientKeys } from '../../../../../database/schemas/Patient';
import Select from '../../../../../components/Select';
import dateFNSFormat from 'date-fns/format';
import { Theme } from '../../../../../theme';

export default function OpioidAgreementDownload(): JSX.Element {
    const selectedPatient = useSelector((state: ReduxState) => selectSelectedPatientDocument(state));
    const selectedOrganizationActiveOpioidAgreements = useSelector((state: ReduxState) =>
        selectSelectedOrganizationActiveOpioidAgreements(state)
    );
    const signedOpioidAgreement = selectedOrganizationActiveOpioidAgreements.find(
        ({ id }) => selectedPatient?.data.opioidAgreement?.currentOpioidAgreementId === id
    );
    const dispatch = useDispatch();

    const handleDownload = async () => {
        if (selectedPatient?.data.opioidAgreement?.pdf) {
            const pdfUrl = await StorageManager.getSignedOpioidAgreementURL(
                selectedPatient.data.opioidAgreement.pdf
            );
            await downloadFile(
                pdfUrl,
                `${selectedPatient.data.lastName.toLocaleUpperCase()}_${selectedPatient.data.firstName.toLocaleUpperCase()}_opioid agreement.pdf`
            );
        }
    };

    const opioidAgreementSelect: ReactSelectState = {
        options: selectedOrganizationActiveOpioidAgreements.map(({ data, id }) => ({
            label: data.title,
            value: id,
        })),
        get selectedOption() {
            return this.options.find(
                ({ value }) => value === selectedPatient?.data.opioidAgreement?.assignedOpioidAgreementId
            );
        },
        handleSelect(option) {
            const selectedOpioidAgreement = selectedOrganizationActiveOpioidAgreements.find(
                ({ id }) => (option as SelectOption).value === id
            );
            dispatch(
                updatePatientDocumentProp({
                    path: PatientKeys.opioidAgreement,
                    value: selectedOpioidAgreement?.updateUserAssignedAgreement(
                        selectedPatient!.data.opioidAgreement!
                    ),
                })
            );
        },
    };

    if (!selectedPatient?.data.opioidAgreement?.currentOpioidAgreementId) {
        return (
            <div className="py-2">
                <div className="flex flex-row justify-start items-center mb-1">
                    <span className="block mr-2 text-gray-700">Status:</span>
                    <span className="font-semibold">Not signed</span>
                </div>
                <div className="flex flex-row justify-start items-center md:w-5/6">
                    <span className="block mr-2 text-gray-700">Agreement Assigned:</span>
                    <Select
                        onChange={opioidAgreementSelect.handleSelect}
                        options={opioidAgreementSelect.options}
                        selectedOption={opioidAgreementSelect.selectedOption}
                        placeholder={opioidAgreementSelect.selectedOption ? undefined : 'Select agreement'}
                    />
                </div>
            </div>
        );
    } else {
        return (
            <>
                <div className="flex flex-row py-2 justify-start items-center">
                    <span className="block mr-2 text-gray-700">Status:</span>
                    <span className="font-semibold">Signed</span>
                </div>
                <div className="flex flex-row py-2 justify-start items-center">
                    <span className="block mr-2 text-gray-700">Agreement Expiration:</span>
                    <span className="font-semibold">
                        {dateFNSFormat(
                            getDateFromDateString(selectedPatient.data.opioidAgreement.expirationDate),
                            'MMMM do, yyyy'
                        )}
                    </span>
                </div>
                <button
                    title={signedOpioidAgreement?.data.title}
                    className={`block w-full justify-center md:w-3/5 flex flex-row items-center text-white rounded-md px-2 py-1 bg-${Theme.lightBlue} hover:bg-${Theme.lightBlueHover}`}
                    onClick={handleDownload}
                >
                    <IconManager type={IconType.DOCUMENT_DOWNLOAD} stroke="#fff" className="mr-1" />
                    <span>Signed opioid agreement</span>
                </button>
                <div className="flex flex-row py-2 justify-start items-center">
                    <span className="block mr-2 text-gray-700">Renewal Agreement Assigned:</span>
                    <Select
                        onChange={opioidAgreementSelect.handleSelect}
                        options={opioidAgreementSelect.options}
                        selectedOption={opioidAgreementSelect.selectedOption}
                        placeholder={opioidAgreementSelect.selectedOption ? undefined : 'Select agreement'}
                    />
                </div>
                {selectedPatient.data.opioidAgreement.currentOpioidAgreementId &&
                    opioidAgreementSelect.selectedOption?.value !==
                        selectedPatient.data.opioidAgreement.currentOpioidAgreementId && (
                        <span className="text-gray-600 text-xs flex flex-row items-center justify-start">
                            <IconManager type={IconType.INFO_CIRCLE} size={15} className="mr-1" />
                            Signed agreement differs from assigned agreement
                        </span>
                    )}
            </>
        );
    }
}
