import React, { forwardRef, Ref } from 'react';
import { ButtonClick, InputChange } from '../types';
import styled, { keyframes } from 'styled-components';
import IconManager, { IconType } from './IconManager';

interface Props {
    className?: string;
    name?: string;
    value?: string;
    onChange?: (e: InputChange) => void;
    disabled?: boolean;
    onBlur?: () => void;
    clearable?: boolean;
    clear?: (e: ButtonClick) => void;
    inValid?: boolean;
    type?: 'text' | 'email' | 'tel';
    suggestion?: string;
    setSuggestionAsValue?: () => void;
}

const TextInput = forwardRef((props: Props, ref: Ref<HTMLInputElement>) => {
    const classNames = (() => {
        const baseClassNames = 'block w-full';
        return !props.disabled
            ? `${baseClassNames} form-input focus:outline-none border border-${
                  props.inValid ? 'red-500' : 'gray-400'
              } bg-white`
            : `${baseClassNames} p-0`;
    })();

    return (
        <div className="relative w-full">
            <input
                ref={ref}
                className={`${props.className} ${classNames}`}
                type={props.type ?? 'text'}
                name={props.name}
                disabled={props.disabled}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
            />
            {props.clearable && (
                <ClearButton visible={!!props.value} onClick={props.clear}>
                    <IconManager type={IconType.CLOSE} fill="#C1C1C1" size={22} />
                </ClearButton>
            )}
            {/*<Suggestion*/}
            {/*    value={props.value}*/}
            {/*    suggestion={props.suggestion}*/}
            {/*    setSuggestionAsValue={props.setSuggestionAsValue}*/}
            {/*/>*/}
        </div>
    );
});

export default TextInput;

function Suggestion(props: Pick<Props, 'value' | 'suggestion' | 'setSuggestionAsValue'>): JSX.Element | null {
    if (props.suggestion) {
        return (
            <SuggestionButton rightPositioning={83} onClick={props.setSuggestionAsValue}>
                {props.suggestion.substr(props.value!.length, props.suggestion.length - props.value!.length)}
            </SuggestionButton>
        );
    }
    return null;
}

const SuggestionButton = styled.button.attrs({ type: 'button' })<{ rightPositioning: number }>`
    display: inline-block;
    position: absolute;
    top: 5px;
    right: ${({ rightPositioning }) => String(rightPositioning)}%;
    z-index: 30;
    color: gray;
    cursor: pointer;
`;

const ClearButton = styled.button.attrs({ type: 'button' })<{ visible: boolean }>`
    display: ${({ visible }) => (visible ? 'inline-block' : 'none')};
    position: absolute;
    top: 7px;
    right: 7px;
    z-index: 30;
    border: none;
    cursor: pointer;
    background-color: transparent;
    @supports (-moz-appearance: none) {
        background-color: transparent !important;
    }
    :focus {
        border: none;
    }
`;
