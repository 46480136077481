import React from 'react';
import { RouteProps } from '../../types';
import PageContainer from '../../components/PageContainer';
import PatientForm from './tabs/patient/PatientForm';

export default function AddPatient(_: RouteProps) {
    return (
        <PageContainer>
            <div className="max-w-2xl p-4 border mt-5 shadow border-gray-400 bg-gray-100 rounded-md">
                <PatientForm addingPatient={true} />
            </div>
        </PageContainer>
    );
}
