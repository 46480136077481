import { useContext, useEffect } from 'react';
import { CurrentUserContext } from '../context/CurrentUserContextProvider';
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearSelectedOrganization,
    getSelectedOrganizationDataForUser,
} from '../redux/selectedOrganization/selectedOrganizationActions';
import { ReduxState } from '../redux/store';
import { selectLoadingCurrentUser } from '../redux/currentSession/currentSessionSelectors';
import { UserRoles } from '../database/schemas/User';
import useNavigation from './useNavigation';
import { selectSelectedOrganization } from '../redux/selectedOrganization/selectedOrganizationSelectors';
import { useIdleTimer } from 'react-idle-timer';
import AuthManager from '../AuthManager';
import { clearToastAlerts } from '../redux/currentSession/currentSessionActions';

const allRoles = [UserRoles.provider, UserRoles.orgAdmin, UserRoles.appAdmin];

export default function isAuthed(requiredRoles: UserRoles[] = allRoles) {
    const currentUser = useContext(CurrentUserContext);
    const navigation = useNavigation();
    const loadingCurrentUser = useSelector((state: ReduxState) => selectLoadingCurrentUser(state));
    const selectedOrganization = useSelector((state: ReduxState) => selectSelectedOrganization(state));
    const dispatch = useDispatch();

    const handleOnIdle = async () => {
        if (isIdle()) {
            console.log('user logged out for stale session');
            await AuthManager.logout();
            await Promise.all([dispatch(clearSelectedOrganization()), dispatch(clearToastAlerts())]);
            await navigate(navigation.loginUrl);
        }
    };

    const { isIdle, reset } = useIdleTimer({
        timeout: 1800000, //30m in ms
        onIdle: handleOnIdle,
        onAction: () => reset(),
        debounce: 500,
    });

    useEffect(() => {
        (async () => {
            const userIsPermitted =
                requiredRoles.find(requiredRole => currentUser.claims?.currentRoles?.[requiredRole]) !==
                    undefined || currentUser.claims?.isAppAdmin;
            if (!loadingCurrentUser && !currentUser.claims) {
                await navigate(navigation.loginUrl);
            } else if (currentUser.claims?.isPatient || (!userIsPermitted && !loadingCurrentUser)) {
                await navigate(navigation.unauthorizedUrl);
            } else if (currentUser.claims?.currentOrgId && !selectedOrganization) {
                //this check will go ahead and fetch all the data for the user if they are
                // both an org admin and provider
                dispatch(getSelectedOrganizationDataForUser({ currentUserClaims: currentUser.claims }));
            }
        })();
    }, [currentUser.claims?.currentOrgId]);
}
