import React from 'react';
import Modal from '../components/Modal';
import { Theme } from '../theme';
import { FormSubmission } from '../types';
import { Props as ModalProps } from './Modal';

interface Props extends ModalProps {
    onConfirm: (e: FormSubmission) => void;
}

export default function ConfirmationModal(props: Props) {
    return (
        <Modal isOpen={props.isOpen} closeModal={props.closeModal}>
            <form className="flex flex-col p-2 justify-center text-center mx-2" onSubmit={props.onConfirm}>
                {props.children}
                <div className="flex flex-row items-center mt-3 justify-center">
                    <button
                        className="text-gray-600 hover:text-gray-700 px-2 py-1 mr-1"
                        onClick={e => {
                            e.preventDefault();
                            props.closeModal();
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className={`font-semibold text-white px-2 py-1 rounded-md focus:outline-none bg-${Theme.lightBlue} hover:bg-${Theme.lightBlueHover}`}
                    >
                        Yes
                    </button>
                </div>
            </form>
        </Modal>
    );
}
