import React from 'react';
import { BlueVariants, buttonStyles, Theme } from '../theme';
import { Link } from '@reach/router';

interface Props {
    children: string;
    className?: string;
    onClick?: () => void;
    linkTo?: string;
    disabled?: boolean;
    color?: BlueVariants;
    type?: 'button' | 'submit';
}

export default function ActionButton(props: Props) {
    const variant = props.color ?? Theme.darkBlue;
    const buttonClassNames = buttonStyles.classNames({
        variant,
        additionalClassNames: props.className,
        isDisabled: props.disabled,
    });

    function Element({ children }: Pick<Props, 'children'>) {
        return props.linkTo ? (
            <Link to={props.linkTo} className={buttonClassNames}>
                {children}
            </Link>
        ) : (
            <button
                className={buttonClassNames}
                disabled={props.disabled}
                onClick={props.onClick}
                type={props.type ?? 'button'}
            >
                {children}
            </button>
        );
    }
    return <Element>{props.children}</Element>;
}
