import { Timestamps } from './Timestamps';

export interface MedicationSchema extends Timestamps {
    name: string;
    unit: string; // e.g. "mg" for pills, "mg/mL" for liquid, "mcg/hr" for patch
    delivery: DeliveryType;
    opioid: OpioidType;
    alternativeNames: string[];
}

export enum OpioidType {
    shortActing,
    longActing,
}

export enum DeliveryType {
    liquid,
    patch,
    pill,
}
