import { DeliveryType } from '../../../../database/schemas/Medication';

export const renderDoseText = (deliveryType: DeliveryType) => {
    return deliveryType === DeliveryType.liquid
        ? 'Dose'
        : deliveryType === DeliveryType.patch
        ? 'Patches per dose'
        : 'Pills per dose';
};

export const renderCountText = (deliveryType: DeliveryType) => {
    return deliveryType === DeliveryType.liquid
        ? 'Quantity'
        : deliveryType === DeliveryType.patch
        ? 'Patches'
        : 'Pill count';
};

export const renderPillSizeText = (deliveryType: DeliveryType) => {
    return deliveryType === DeliveryType.liquid
        ? 'Concentration'
        : deliveryType === DeliveryType.patch
        ? 'Patch dose'
        : 'Pill Size';
};
