import React, { useEffect } from 'react';
import TextInput from '../../../../components/TextInput';
import { convertDocToSchema, isFirestoreDocument, toPascalCase } from '../../../../utils';
import { UserMedicationDocument } from '../../../../database/documents/UserMedicationDocument';
import { Theme } from '../../../../theme';
import { MedicationFormKeys, MedicationFormOnChange } from './medicationTypes';
import { MedicationFormType } from '../../../../redux/selectedPatient/selectedPatientReducer';
import Select from '../../../../components/Select';
import { MedicationDocument } from '../../../../database/documents/MedicationDocument';
import { InputChange, ReactSelectState, SelectOption, ValidatableString } from '../../../../types';
import { useImmer } from 'use-immer';
import { Prescription, UserMedicationSchema } from '../../../../database/schemas/Patient';
import { DeliveryType } from '../../../../database/schemas/Medication';
import { renderCountText, renderDoseText, renderPillSizeText } from './medicationFormLabels';

interface Props {
    medicationForm?: MedicationFormType;
    medicationOptions: MedicationDocument[];
    setFormProp: MedicationFormOnChange;
    addingMedication?: boolean;
    action: () => void;
    cancelAction: () => void;
    deleteMedicationModalVisible?: boolean;
    presentDeleteMedicationModal?: () => void;
    showRemoveButton: boolean;
    resetMedicationForm: () => void;
}

interface State {
    isUpdatingPreviousMedication: boolean;
    pillsPerDose: ValidatableString; //intermediary value for storing dose.min & dose.max as string
    pillSizeOrConcentration: ValidatableString;
    formValid: boolean;
}

function MedicationForm(props: Props) {
    const form = convertDocToSchema<UserMedicationDocument, UserMedicationSchema>(props.medicationForm);
    const [state, updateState] = useImmer<State>({
        isUpdatingPreviousMedication: false,
        //intermediary value for storing dose.min & dose.max as string
        pillsPerDose: {
            value: form.prescription?.dose ? formatPillsPerDose(form.prescription.dose) : '',
            isValid: form.prescription?.dose ? true : undefined,
        },
        pillSizeOrConcentration: {
            value: String(form.unit?.amount ?? ''),
            isValid: form.prescription?.count ? true : undefined,
        },
        formValid: false,
    });
    const decimalRegex = new RegExp('^\\d*(\\.([0|5])?)?$');
    const wholeNumberRegex = new RegExp('^d+$');

    useEffect(() => {
        updateState(draft => {
            draft.formValid =
                !!form.name &&
                !!form.prescription &&
                Object.values(form.prescription).every(value => value !== undefined) &&
                form.prescription.dose.min > 0 &&
                form.prescription.dose.max > 0 &&
                form.unit.amount > 0 &&
                !!form.unit.measure &&
                form.delivery !== null &&
                form.prescription.frequency > 0 &&
                form.prescription.count?.prescribed > 0;
        });
    }, [
        form.prescription?.dose,
        form.prescription?.prn,
        form.prescription?.frequency,
        form.prescription?.count,
        form.delivery,
        form.name,
        form.unit?.measure,
        form.unit?.amount,
        form.prescription?.frequency,
        form.prescription?.count?.prescribed,
    ]);

    const validatePillsPerDose = () => {
        updateState(draft => {
            const { value } = draft.pillsPerDose;
            const [min, max] = value.split('-');

            const regexToTest = form.delivery === DeliveryType.patch ? wholeNumberRegex : decimalRegex;

            if (!max) {
                draft.pillsPerDose.isValid = regexToTest.test(value);
            } else {
                draft.pillsPerDose.isValid =
                    regexToTest.test(min) && regexToTest.test(max) && Number(min) < Number(max);
            }
        });
    };

    const validatePillConcentration = () => {
        updateState(draft => {
            draft.pillSizeOrConcentration.isValid = decimalRegex.test(draft.pillSizeOrConcentration.value);
        });
    };

    function formatPillsPerDose(dose: Prescription['dose']): string {
        return dose.min === dose.max ? String(dose.min) : `${dose.min}-${dose.max}`;
    }

    const medicationSelect: ReactSelectState = {
        options: props.medicationOptions.map(({ id, data }) => ({ label: data.name, value: id })),
        get selectedOption() {
            return this.options.find(({ label }) => form.name === label);
        },
        handleSelect(option) {
            const selectedOption = props.medicationOptions.find(
                ({ data }) => data.name === (option as SelectOption).label
            );
            props.resetMedicationForm();
            updateState(draft => {
                draft.pillsPerDose = { value: '' };
                draft.pillSizeOrConcentration = { value: '' };
            });
            props.setFormProp({ prop: MedicationFormKeys.name, value: (option as SelectOption).label });
            props.setFormProp({
                prop: MedicationFormKeys.unitMeasure,
                //ensure dosage unit is set for selected medication
                value: selectedOption?.data.unit,
            });
            props.setFormProp({ prop: MedicationFormKeys.delivery, value: selectedOption?.data.delivery });
        },
    };

    const handleInput = (e: InputChange) => {
        e.persist();
        if (isFirestoreDocument<UserMedicationDocument>(props.medicationForm)) {
            updateState(draft => void (draft.isUpdatingPreviousMedication = true));
        }
        updateState(draft => {
            draft.pillSizeOrConcentration.isValid = undefined;
            draft.pillsPerDose.isValid = undefined;
        });
        switch (e.target.name) {
            case MedicationFormKeys.prescriptionPrn:
                return props.setFormProp({
                    prop: e.target.name as MedicationFormKeys,
                    value: !form.prescription?.prn,
                });
            case MedicationFormKeys.dose:
                const { min, max } = convertPillsPerDoseToMinMax(e.target.value);
                updateState(draft => void (draft.pillsPerDose.value = e.target.value));
                props.setFormProp({ prop: MedicationFormKeys.doseMin, value: min });
                return props.setFormProp({ prop: MedicationFormKeys.doseMax, value: max });
            case MedicationFormKeys.unitAmount:
                updateState(draft => {
                    draft.pillSizeOrConcentration = {
                        value: e.target.value,
                    };
                });
                return props.setFormProp({ prop: MedicationFormKeys.unitAmount, value: e.target.value });
            default:
                props.setFormProp({ prop: e.target.name as MedicationFormKeys, value: e.target.value });
        }
    };

    const convertPillsPerDoseToMinMax = (dose: string): Prescription['dose'] => {
        let doseMin: number, doseMax: number;
        if (dose.includes('-')) {
            const [min, max] = dose.split('-');
            doseMin = parseFloat(min);
            doseMax = parseFloat(max);
        } else {
            doseMin = parseFloat(dose);
            doseMax = parseFloat(dose);
        }
        return { min: doseMin, max: doseMax };
    };

    const handleAction = async (action: () => void): Promise<void> => {
        if (state.formValid) {
            action();
            updateState(draft => void (draft.isUpdatingPreviousMedication = false));
        }
    };

    const isPreexistingMedication = isFirestoreDocument<UserMedicationDocument>(props.medicationForm);

    return (
        <div className="w-full p-3">
            <div className="flex flex-row items-center justify-between mb-6">
                <div className="mr-1 w-1/5">
                    {isPreexistingMedication ? (
                        <label className="block">
                            <span className="text-gray-700 font-semibold">Medication name</span>
                            <TextInput disabled={true} className="py-1" value={form.name ?? ''} />
                        </label>
                    ) : (
                        <Select
                            label="Medication name"
                            options={medicationSelect.options}
                            onChange={medicationSelect.handleSelect}
                            selectedOption={medicationSelect.selectedOption}
                            placeholder={medicationSelect.selectedOption ? undefined : 'Select medication'}
                        />
                    )}
                </div>
                <div style={{ width: '9%' }}>
                    <label className="block" htmlFor={MedicationFormKeys.delivery}>
                        <span className="text-gray-700 font-semibold">Method</span>
                        <div className="flex flex-row relative">
                            <TextInput
                                name={MedicationFormKeys.delivery}
                                value={toPascalCase(DeliveryType[form.delivery]) ?? ''}
                                onChange={handleInput}
                                disabled={isPreexistingMedication}
                                className="py-1"
                            />
                        </div>
                    </label>
                </div>
                <div className="mr-5" style={{ width: '12%' }}>
                    <label className="block" htmlFor={MedicationFormKeys.unitAmount}>
                        <span className="text-gray-700 font-semibold">
                            {renderPillSizeText(form.delivery)}
                        </span>
                        <div className="flex flex-row items-stretch w-full relative">
                            <TextInput
                                name={MedicationFormKeys.unitAmount}
                                //todo: clean this up 🤦🏻‍♂️
                                value={
                                    form.unit
                                        ? isPreexistingMedication
                                            ? form.unit.amount
                                                ? `${form.unit.amount}${form.unit.measure}`
                                                : ''
                                            : state.pillSizeOrConcentration.value
                                        : ''
                                }
                                onChange={handleInput}
                                onBlur={validatePillConcentration}
                                disabled={isPreexistingMedication}
                                className="py-1"
                            />
                            {!isPreexistingMedication && (
                                <span className="flex items-center leading-normal font-semibold p-1 whitespace-no-wrap text-gray-700 text-sm">
                                    {form.unit?.measure}
                                </span>
                            )}
                            {state.pillSizeOrConcentration.isValid === false && (
                                <span
                                    className="text-xs w-full text-red-500 absolute"
                                    style={{ bottom: '-35px' }}
                                >
                                    Please enter a valid{' '}
                                    {renderPillSizeText(form.delivery).toLocaleLowerCase()}
                                </span>
                            )}
                        </div>
                    </label>
                </div>
                <div className="mr-6 relative" style={{ width: '12%' }}>
                    <label className="block" htmlFor={MedicationFormKeys.dose}>
                        <span className="text-gray-700 font-semibold">{renderDoseText(form.delivery)}</span>
                        <div className="flex flex-row items-stretch w-full relative">
                            <TextInput
                                name={MedicationFormKeys.dose}
                                //todo: adjust for adding new medication
                                value={state.pillsPerDose.value}
                                onChange={handleInput}
                                onBlur={validatePillsPerDose}
                                inValid={state.pillsPerDose.isValid === false}
                                className="py-1"
                            />
                            {form.delivery === DeliveryType.liquid && (
                                <span className="flex items-center leading-normal font-semibold p-1 whitespace-no-wrap text-gray-700 text-sm">
                                    mL
                                </span>
                            )}
                        </div>
                    </label>
                    {state.pillsPerDose.isValid === false && (
                        <span
                            className="text-xs w-full text-red-500 absolute text-center bg-white rounded-md shadow-md z-50 p-2 border border-dashed border-red-500"
                            style={{ bottom: '-85px' }}
                        >
                            {form.delivery === DeliveryType.patch
                                ? 'Please enter a valid range with only whole values'
                                : 'Please enter a valid range with only whole or half values.'}
                        </span>
                    )}
                </div>
                <div className="text-left" style={{ width: '10%' }}>
                    <label htmlFor={MedicationFormKeys.prescriptionFrequency} className="block">
                        <span className="text-gray-700 font-semibold">Interval</span>
                        <div className="w-full flex flex-row items-center justify-start">
                            <TextInput
                                className={`form-input focus:outline-none border py-1 border-gray-400 w-1/12 block`}
                                value={String(form.prescription?.frequency ?? '') ?? ''}
                                name={MedicationFormKeys.prescriptionFrequency}
                                onChange={handleInput}
                            />
                            <span className="text-gray-600 ml-2">hours</span>
                        </div>
                    </label>
                </div>
                <div style={{ width: '5%' }}>
                    {form.delivery !== DeliveryType.patch && (
                        <label
                            className="flex flex-col justify-center relative"
                            htmlFor={MedicationFormKeys.prescriptionPrn}
                        >
                            <span className="text-gray-700 font-semibold absolute" style={{ top: '-28px' }}>
                                PRN
                            </span>
                            <input
                                type="checkbox"
                                checked={form.prescription?.prn}
                                name={MedicationFormKeys.prescriptionPrn}
                                className={`absolute py-1 form-checkbox top-0 block text-3xl text-${Theme.darkBlue} hover:text-${Theme.darkBlueHover} focus:outline-none border border-gray-400`}
                                onChange={handleInput}
                            />
                        </label>
                    )}
                </div>
                <div className="mr-2" style={{ width: '8%' }}>
                    <label className="block" htmlFor={MedicationFormKeys.prescriptionCountPrescribed}>
                        <span className="text-gray-700 font-semibold">{renderCountText(form.delivery)}</span>
                        <TextInput
                            name={MedicationFormKeys.prescriptionCountPrescribed}
                            value={String(form?.prescription?.count?.prescribed ?? '') ?? ''}
                            onChange={handleInput}
                            className="py-1"
                        />
                    </label>
                </div>
                <ActionButtons
                    formValid={state.formValid}
                    showRemoveButton={props.showRemoveButton}
                    action={() => handleAction(props.action)}
                    cancelAction={() => handleAction(props.cancelAction)}
                    presentDeleteMedicationModal={props.presentDeleteMedicationModal}
                    isUpdatingPreviousMedication={state.isUpdatingPreviousMedication}
                    isPreexistingMedication={isPreexistingMedication}
                />
            </div>
        </div>
    );
}

export default React.memo(MedicationForm);

const ActionButtons = React.memo(function ActionButtons(
    props: Pick<Props, 'presentDeleteMedicationModal' | 'showRemoveButton' | 'action' | 'cancelAction'> & {
        isPreexistingMedication: boolean;
        formValid: boolean;
    } & Pick<State, 'isUpdatingPreviousMedication'>
): JSX.Element {
    const baseClassNames = 'px-2 py-1 rounded-md focus:outline-none';
    //store reference to original form doc b/c editing it will turn it into a schema
    return props.showRemoveButton && !props.isUpdatingPreviousMedication ? (
        <button
            onClick={props.presentDeleteMedicationModal}
            className={`${baseClassNames} bg-${Theme.darkBlue} font-semibold mt-5 text-white hover:bg=${Theme.darkBlueHover}`}
        >
            Remove
        </button>
    ) : (
        <div className="flex flex-col justify-center pt-5">
            <button
                onClick={e => {
                    e.stopPropagation();
                    props.action();
                }}
                disabled={!props.formValid}
                className={`${baseClassNames} bg-${Theme.lightBlue} hover:bg-${
                    Theme.lightBlueHover
                } text-white ${!props.formValid ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
                {props.isPreexistingMedication ? 'Update' : 'Add'}
            </button>
            <button
                onClick={e => {
                    e.stopPropagation();
                    props.cancelAction();
                }}
                className={`${baseClassNames} font-normal text-gray-600 hover:text-gray-600`}
            >
                Cancel
            </button>
        </div>
    );
});
