import React, { useCallback, useContext, useEffect } from 'react';
import { Theme } from '../theme';
import styled from 'styled-components';
import { Link, navigate } from '@reach/router';
import Navigator, { NonAuthRoutes } from '../routes';
import IconManager, { IconType } from '../components/IconManager';
import { CurrentUserContext } from '../context/CurrentUserContextProvider';
import { useImmer } from 'use-immer';
import { useDispatch, useSelector } from 'react-redux';
import { clearToastAlerts } from '../redux/currentSession/currentSessionActions';
import { clearSelectedOrganization } from '../redux/selectedOrganization/selectedOrganizationActions';
import Modal from './Modal';
import OrganizationSelect from '../pages/OrganizationSelect';
import { ReduxState } from '../redux/store';
import { selectSelectedOrganization } from '../redux/selectedOrganization/selectedOrganizationSelectors';
import useNavigation from '../hooks/useNavigation';
import { UserRoles } from '../database/schemas/User';
import AuthManager from '../AuthManager';
import { BrowserStorageKeys } from '../types';

interface State {
    userAuthedToViewNav?: boolean;
    changingOrganization: boolean;
}

export default function NavBar(): JSX.Element | null {
    const [state, updatedState] = useImmer<State>({ changingOrganization: false });
    const currentUser = useContext(CurrentUserContext);
    const selectedOrganization = useSelector((state: ReduxState) => selectSelectedOrganization(state));
    const navigation = useNavigation(currentUser.claims?.currentOrgId);
    const dispatch = useDispatch();
    const routesToHideNav = Object.values(NonAuthRoutes) as string[];
    const homeLinkForUser = (() => {
        if (
            currentUser.claims?.isOrgAdminInCurrentOrganization() ||
            currentUser.claims?.isProviderInCurrentOrganization()
        ) {
            if (currentUser.viewContext !== null) {
                return currentUser.viewContext === UserRoles.orgAdmin
                    ? navigation.providersUrl
                    : navigation.patientsUrl;
            } else {
                return currentUser.claims?.isOrgAdminInCurrentOrganization()
                    ? navigation.providersUrl
                    : navigation.patientsUrl;
            }
        } else {
            //user is super admin
            return navigation.adminOrganizationsUrl;
        }
    })();

    useEffect(() => {
        //display nav if we have a view context set for user (aka they're logged in) or the they're viewing an unauthed route
        //view context might lead to role specific navigation UI
        updatedState(draft => {
            draft.userAuthedToViewNav =
                !!currentUser.claims || !routesToHideNav.includes(window.location.pathname);
        });
    }, [currentUser.document, window.location.pathname]);

    const toggleOrganizationSelectVisible = useCallback(() => {
        updatedState(draft => void (draft.changingOrganization = !draft.changingOrganization));
    }, []);

    const handleLogout = async (): Promise<void> => {
        await AuthManager.logout();
        await Promise.all([dispatch(clearSelectedOrganization()), dispatch(clearToastAlerts())]);
        await navigate(navigation.loginUrl);
    };
    return !state.userAuthedToViewNav ? null : (
        <>
            <div
                className={`flex flex-row text-white justify-between items-center py-3 px-5 relative bg-${Theme.lightBlue}`}
            >
                <div>
                    <Link to={homeLinkForUser} className="text-3xl font-bold">
                        Symmetry
                    </Link>
                </div>
                <ViewContextSelection navigation={navigation} />
                <Nav className="font-thin text-xl flex flex-col justify-start items-end">
                    <DropDown
                        isInMultipleOrganizations={currentUser.claims?.isInMultipleOrganizations()}
                        isProvider={currentUser.claims?.isProviderInCurrentOrganization()}
                        navigation={navigation}
                        toggleOrganizationSelectVisible={toggleOrganizationSelectVisible}
                        username={`${currentUser.document?.data.firstName} ${currentUser.document?.data.lastName}`}
                        handleLogout={handleLogout}
                    />
                    {currentUser.claims?.isInOrganizations() && (
                        <span className="ml-2">{selectedOrganization?.data.name}</span>
                    )}
                </Nav>
            </div>
            <Modal isOpen={state.changingOrganization} closeModal={toggleOrganizationSelectVisible}>
                <OrganizationSelect
                    isChangingOrganization={true}
                    closeModal={toggleOrganizationSelectVisible}
                />
            </Modal>
        </>
    );
}

interface DropDownProps {
    username?: string;
    handleLogout: () => void;
    toggleOrganizationSelectVisible: () => void;
    navigation: Navigator;
    isInMultipleOrganizations?: boolean;
    isProvider?: boolean;
}

function DropDown(props: DropDownProps): JSX.Element {
    const navItemClassNames = `px-3 text-base w-full text-left flex flex-row items-center justify-start py-3 text-${Theme.darkBlue} hover:text-${Theme.darkBlueHover} border-b border-gray-400 font-medium`;
    return (
        <DropDownContainer>
            <button className="font-semibold flex flex-row items-center">
                {props.username} <IconManager type={IconType.CHEVRON_DOWN} stroke="#fff" className="ml-1" />
            </button>
            <DropDownContent>
                <Link to={props.navigation.accountUrl} className={navItemClassNames}>
                    <IconManager type={IconType.PROFILE} className="mr-1" /> Account
                </Link>
                {props.isProvider && (
                    <>
                        <Link to={props.navigation.patientsUrl} className={navItemClassNames}>
                            <IconManager type={IconType.USER} className="mr-1" /> Patients
                        </Link>
                        <Link to={props.navigation.opioidAgreementRenewalsUrl} className={navItemClassNames}>
                            <IconManager type={IconType.CLOCK} className="mr-1" /> Agreement Renewals
                        </Link>
                    </>
                )}

                {props.isInMultipleOrganizations && (
                    <button onClick={props.toggleOrganizationSelectVisible} className={navItemClassNames}>
                        <IconManager type={IconType.BUILDING} className="mr-1" /> Change Organization
                    </button>
                )}
                <button
                    type="button"
                    className={`bg-transparent flex text-base flex-row items-center justify-center w-full px-5 py-4 mx-auto text-${Theme.darkBlue} hover:text-${Theme.darkBlueHover}`}
                    onClick={props.handleLogout}
                >
                    Sign Out <IconManager type={IconType.SIGNOUT} className="ml-1" fill="#1A1D67" size={20} />
                </button>
            </DropDownContent>
        </DropDownContainer>
    );
}

function ViewContextSelection({ navigation }: { navigation: Navigator }): JSX.Element | null {
    const currentUser = useContext(CurrentUserContext);
    const roles: { label: string; value: number }[] = [];
    for (const role in UserRoles) {
        if (currentUser.claims?.currentRoles?.[role]) {
            switch (Number(role)) {
                case 0:
                    roles.push({ label: 'Organization Admin', value: 0 });
                    break;
                case 1:
                    roles.push({ label: 'Provider', value: 1 });
                    break;
                case 3:
                    roles.push({ label: 'Application Admin', value: 3 });
            }
        }
    }
    if (roles.length <= 1) {
        return null;
    }

    const setViewContext = useCallback((viewContext: UserRoles) => {
        currentUser.setViewContext(viewContext);
        localStorage.setItem(BrowserStorageKeys.VIEW_CONTEXT, String(viewContext));
    }, []);

    const routes = {
        [UserRoles.provider]: navigation.patientsUrl,
        [UserRoles.orgAdmin]: navigation.providersUrl,
    };

    return (
        <div className="flex flex-row items-center justify-end ml-auto mr-3 w-1/5">
            {roles.map(({ label, value }) => (
                <Link
                    key={value}
                    to={routes[value]}
                    onClick={() => setViewContext(value)}
                    className={`mr-2 font-thin underline cursor-pointer ${
                        currentUser.viewContext === value ? 'font-semibold' : 'font-normal'
                    }`}
                >
                    {label}
                </Link>
            ))}
        </div>
    );
}

const DropDownContent = styled.div`
    display: none;
    position: absolute;
    border-radius: 4px;
    width: 220px;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    border: 1px solid lightgray;
    padding: 0;
    right: 5px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
`;

const DropDownContainer = styled.div`
    position: relative;
    display: inline-block;
    &:hover ${DropDownContent} {
        display: flex;
    }
`;

const Nav = styled.nav`
    display: block;
    @media (max-width: 765px) {
        display: none;
    }
`;
