import React, { Ref, RefObject, useContext, useEffect, useRef } from 'react';
import { BrowserStorageKeys, ButtonClick, FormSubmission, InputChange, ValidatableString } from '../../types';
import { CurrentUserContext } from '../../context/CurrentUserContextProvider';
import { useImmer } from 'use-immer';
import Modal from '../../components/Modal';
import SubmitButton from '../../components/SubmitButton';
import AuthManager from '../../AuthManager';
import { setToastAlert, setToastError } from '../../redux/currentSession/currentSessionActions';
import { useDispatch } from 'react-redux';
import TextInput from '../../components/TextInput';
import ActionButton from '../../components/ActionButton';
import { formatPhoneNumber, isValidEmail, isValidPhoneNumber, unformatPhoneNumber } from '../../utils';
import { ProviderDocument } from '../../database/documents/ProviderDocument';
import { UserFormKeys } from '../../database/schemas/User';
import MaskablePasswordInput from '../../components/MaskablePasswordInput';
import { navigate } from '@reach/router';
import useNavigation from '../../hooks/useNavigation';
import Encouragement from '../../components/Encouragement';
import useBrowserStorage from '../../hooks/useBrowserStorage';

interface State {
    modalScenario?: ModalScenarios;
    encourageUserToSetContactNumber?: boolean;
    form: {
        firstName: string;
        lastName: string;
        newEmail: ValidatableString;
        password: string;
        newPassword: ValidatableString;
        submissionError: unknown;
        submitting: boolean;
        phoneNumber?: ValidatableString;
    };
}

const initialState = ({
    firstName,
    lastName,
    phoneNumber,
}: {
    firstName: string;
    lastName: string;
    phoneNumber?: string;
}): State => ({
    form: {
        firstName,
        lastName,
        newEmail: { value: '' },
        password: '',
        newPassword: { value: '' },
        submissionError: null,
        submitting: false,
        phoneNumber: { value: phoneNumber ?? '', isValid: phoneNumber ? true : undefined },
    },
    encourageUserToSetContactNumber: true,
});

enum ModalScenarios {
    CHANGING_EMAIL,
    CHANGING_PASSWORD,
}

const FormKeys = { ...UserFormKeys, newPassword: 'newPassword', newEmail: 'newEmail' };

export default function CurrentUserProfile() {
    const currentUser = useContext(CurrentUserContext);
    const { firstName, lastName, phoneNumber } = (() => {
        if (currentUser.document) {
            //cast as ProviderDocument to access phoneNumber
            const { firstName, lastName, phoneNumber } = (currentUser.document as ProviderDocument).data;
            return {
                firstName,
                lastName,
                phoneNumber: phoneNumber ? formatPhoneNumber(phoneNumber) : '',
            };
        }
        //fall back if current user is undefined for some reason
        return { firstName: '', lastName: '', phoneNumber: '' };
    })();
    const [state, updateState] = useImmer<State>(initialState({ firstName, lastName, phoneNumber }));
    const validatableFields = [FormKeys.phoneNumber, FormKeys.newEmail, FormKeys.newPassword];
    const navigation = useNavigation();
    const encouragementToSetContactNumber = useBrowserStorage({
        type: 'session',
        key: BrowserStorageKeys.PROVIDER_HAS_DISMISSED_CONTACT_NUMBER_ENCOURAGEMENT,
    });

    useEffect(() => {
        updateState(draft => {
            draft.encourageUserToSetContactNumber =
                !phoneNumber && !encouragementToSetContactNumber.itemExistsInStorage;
        });
    }, [phoneNumber]);

    const toggleModal = (scenario?: ModalScenarios) => {
        updateState(draft => {
            draft.modalScenario = scenario;
            draft.form = initialState({ firstName, lastName, phoneNumber }).form;
        });
    };
    const dispatch = useDispatch();

    const handleInput = (e: InputChange) => {
        e.persist();
        const isValidatableField = validatableFields.includes(e.target.name);
        updateState(draft => {
            if (isValidatableField) {
                draft.form[e.target.name] = { isValid: undefined, value: e.target.value };
            } else {
                draft.form[e.target.name] = e.target.value;
            }
        });
    };

    const updateEmail = async (): Promise<void> => {
        const { newEmail, password } = state.form;
        if (isValidEmail(state.form.newEmail.value)) {
            await AuthManager.changeEmail({
                email: currentUser.document!.data.email,
                password,
                newEmail: newEmail.value,
            });
            await AuthManager.logout();
            await navigate(navigation.loginUrl);
            dispatch(setToastAlert("We've sent you a verification email to your new email address"));
        } else {
            updateState(draft => void (draft.form.newEmail.isValid = false));
        }
    };

    const updatePassword = async (): Promise<void> => {
        const { newPassword, password } = state.form;
        if (newPassword.value.length >= 8) {
            await AuthManager.changePassword({
                email: currentUser.document!.data.email,
                newPassword: newPassword.value,
                password,
            });
            dispatch(setToastAlert('Your password has been updated'));
        } else {
            updateState(draft => void (draft.form.newPassword.isValid = false));
        }
    };

    const handleChangePasswordOrEmail = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        try {
            updateState(draft => void (draft.form.submitting = true));
            if (state.modalScenario === ModalScenarios.CHANGING_EMAIL) {
                await updateEmail();
            } else if (state.modalScenario === ModalScenarios.CHANGING_PASSWORD) {
                await updatePassword();
            }
        } catch (error) {
            console.log(error);
            dispatch(setToastError('An error occurred while attempting to update your information'));
        }
    };

    const updateName = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        const { firstName, lastName } = state.form;
        if (
            currentUser.document?.data.firstName !== firstName ||
            currentUser.document.data.lastName !== lastName
        ) {
            try {
                currentUser.updateCurrentUserProp({ prop: 'firstName', value: firstName });
                currentUser.updateCurrentUserProp({ prop: 'lastName', value: lastName });
                dispatch(setToastAlert('Your name has been successfully updated'));
            } catch (error) {
                console.log(error);
                dispatch(setToastError('An error occurred while attempting to update your name'));
            }
        }
    };

    const updatePhoneNumber = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        //at this point, we can be certain that this current user is a provider or else this component would not be rendered
        const providerDocument = currentUser.document as ProviderDocument;
        const { phoneNumber } = state.form;
        if (!state.form.phoneNumber?.value || !isValidPhoneNumber(state.form.phoneNumber.value)) {
            return updateState(draft => void (draft.form.phoneNumber!.isValid = false));
        }
        try {
            const unformattedNumber = unformatPhoneNumber(phoneNumber!.value);
            if (
                !providerDocument.data.phoneNumber ||
                unformattedNumber !== providerDocument.data.phoneNumber
            ) {
                currentUser.updateCurrentUserProp({ prop: 'phoneNumber', value: unformattedNumber });
                updateState(
                    draft =>
                        void (draft.form.phoneNumber!.value = formatPhoneNumber(
                            draft.form.phoneNumber!.value
                        )!)
                );
            }
            dispatch(setToastAlert('Your phone number has been successfully updated'));
        } catch (error) {
            console.log(error);
            dispatch(setToastError('An error occurred while attempting to update your phone number'));
        }
    };

    const dismissContactNumberEncouragement = (e: ButtonClick) => {
        e.preventDefault();
        updateState(draft => void (draft.encourageUserToSetContactNumber = undefined));
        encouragementToSetContactNumber.setInStorage();
    };

    const contactNumberInfo =
        'This will be the number your patients will be prompted to contact in the case of an emergency';

    return (
        <>
            <div className="p-3 my-2">
                <div>
                    <form
                        className="flex flex-row justify-start items-end w-full lg:w-2/3"
                        onSubmit={updateName}
                    >
                        <div className="flex flex-col w-full">
                            <label
                                className="w-full mb-2 block font-semibold"
                                htmlFor={UserFormKeys.firstName}
                            >
                                <span className="text-gray-700">First Name</span>
                                <TextInput
                                    className="py-1"
                                    name={UserFormKeys.firstName}
                                    value={state.form.firstName}
                                    onChange={handleInput}
                                />
                            </label>
                            <label className="w-full block font-semibold" htmlFor={UserFormKeys.lastName}>
                                <span className="text-gray-700">Last Name</span>
                                <TextInput
                                    className="py-1"
                                    name={UserFormKeys.lastName}
                                    value={state.form.lastName}
                                    onChange={handleInput}
                                />
                            </label>
                        </div>
                        <ActionButton
                            type="submit"
                            className="ml-2 mt-5 self-center"
                            disabled={
                                state.form.firstName === currentUser.document?.data.firstName &&
                                state.form.lastName === currentUser.document.data.lastName
                            }
                        >
                            Update
                        </ActionButton>
                    </form>
                    {currentUser.claims?.isProviderInCurrentOrganization() && (
                        <form
                            className="text-center flex flex-row justify-start items-start mt-3 lg:w-2/3 w-full"
                            onSubmit={updatePhoneNumber}
                        >
                            <label
                                className="block flex flex-col justify-start items-start w-full"
                                htmlFor={FormKeys.phoneNumber}
                            >
                                <span className="font-semibold text-gray-700">Phone Number</span>
                                <div className="flex flex-col w-full items-start">
                                    <TextInput
                                        value={state.form.phoneNumber!.value}
                                        name={FormKeys.phoneNumber}
                                        onChange={handleInput}
                                        className={`py-1 ${
                                            state.form.phoneNumber!.isValid === false
                                                ? 'border border-red-500'
                                                : state.encourageUserToSetContactNumber
                                                ? 'border border-blue-500'
                                                : ''
                                        }`}
                                    />
                                    {state.encourageUserToSetContactNumber ? (
                                        <Encouragement
                                            className="mt-3"
                                            dismiss={dismissContactNumberEncouragement}
                                            title="Set Contact Number"
                                            message={contactNumberInfo}
                                        />
                                    ) : (
                                        <p
                                            className={`text-xs text-left text-${
                                                state.form.phoneNumber!.isValid === false
                                                    ? 'red-500'
                                                    : 'gray-600'
                                            } block ml-1 mt-1`}
                                        >
                                            {state.form.phoneNumber!.isValid === false
                                                ? 'Please enter a valid phone number'
                                                : contactNumberInfo}
                                        </p>
                                    )}
                                </div>
                            </label>
                            <ActionButton
                                type="submit"
                                className="ml-2 mt-6"
                                disabled={
                                    unformatPhoneNumber(state.form.phoneNumber!.value) ===
                                    (currentUser.document as ProviderDocument)?.data.phoneNumber
                                }
                            >
                                Update
                            </ActionButton>
                        </form>
                    )}
                </div>
                <div className="flex flex-col justify-start items-start mt-3 py-3">
                    <ActionButton
                        className="w-full lg:w-1/3 mb-2"
                        onClick={() => toggleModal(ModalScenarios.CHANGING_PASSWORD)}
                    >
                        Change password
                    </ActionButton>
                    <ActionButton
                        className="w-full lg:w-1/3"
                        onClick={() => toggleModal(ModalScenarios.CHANGING_EMAIL)}
                    >
                        Change email
                    </ActionButton>
                </div>
            </div>
            <Modal isOpen={state.modalScenario !== undefined} closeModal={() => toggleModal()}>
                <form className="mx-5" onSubmit={handleChangePasswordOrEmail}>
                    {state.modalScenario === ModalScenarios.CHANGING_EMAIL ? (
                        <ChangeEmailForm
                            handleInput={handleInput}
                            newEmail={state.form.newEmail}
                            password={state.form.password}
                            submitting={state.form.submitting}
                        />
                    ) : (
                        <ChangePasswordForm
                            handleInput={handleInput}
                            password={state.form.password}
                            newPassword={state.form.newPassword}
                            submitting={state.form.submitting}
                        />
                    )}
                </form>
            </Modal>
        </>
    );
}

interface FormProps {
    submitting: boolean;
    handleInput: (e: InputChange) => void;
}

interface ChangeEmaikFormProps extends FormProps {
    password: State['form']['password'];
    newEmail: State['form']['newEmail'];
}

function ChangeEmailForm(props: ChangeEmaikFormProps): JSX.Element {
    return (
        <div className="py-4">
            <div className="mb-5">
                <label htmlFor={FormKeys.newEmail}>
                    <span className="block text-gray-700 text-sm font-semibold">New Email</span>
                    <TextInput
                        className="py-1"
                        type="email"
                        name={FormKeys.newEmail}
                        value={props.newEmail.value}
                        onChange={props.handleInput}
                    />
                </label>
                {props.newEmail.isValid === false && (
                    <p className="text-xs text-left block ml-1 mt-1 text-red-500">
                        Please enter a valid email address
                    </p>
                )}
            </div>
            <div className="mb-5">
                <label className="block mt-3" htmlFor={FormKeys.password}>
                    <span className="block text-gray-700 text-sm font-semibold">
                        Please confirm your password to authorize this change
                    </span>
                    <MaskablePasswordInput
                        name={FormKeys.password}
                        value={props.password}
                        onChange={props.handleInput}
                        type="selfControlled"
                    />
                </label>
                <p className="text-xs text-center block text-gray-600 mt-2">
                    You will be logged out after requesting verification. Please check your provided email to
                    verify the new email address before logging back in.
                </p>
            </div>
            <SubmitButton loading={props.submitting} className="w-full mt-3">
                Request Verification
            </SubmitButton>
        </div>
    );
}

interface ChangePasswordFormProps extends FormProps {
    password: State['form']['password'];
    newPassword: State['form']['newPassword'];
}

interface ChangePasswordState {
    passwordVisible: boolean;
    newPasswordVisible: boolean;
}

interface HandleToggleProps {
    e: InputChange;
    ref: RefObject<HTMLInputElement>;
    typeOfInput: 'password' | 'newPassword';
}

const initialChangePasswordState: ChangePasswordState = {
    passwordVisible: false,
    newPasswordVisible: false,
};

function ChangePasswordForm(props: ChangePasswordFormProps): JSX.Element {
    const [state, updateState] = useImmer<ChangePasswordState>(initialChangePasswordState);
    const passwordRef = useRef<HTMLInputElement>(null);
    const newPasswordRef = useRef<HTMLInputElement>(null);

    const handleToggle = ({ e, ref, typeOfInput }: HandleToggleProps) => {
        e.persist();
        if (!ref.current) return;
        ref.current.type = ref.current.type === 'password' ? 'text' : 'password';
        updateState(draft => {
            typeOfInput === 'password'
                ? (draft.passwordVisible = ref.current?.type === 'text')
                : (draft.newPasswordVisible = ref.current?.type === 'text');
        });
        ref.current.focus();
    };

    return (
        <div className="py-4">
            <div className="mb-5">
                <label className="block mb-2" htmlFor={FormKeys.password}>
                    <span className="text-gray-700 font-semibold">Current Password</span>
                    <MaskablePasswordInput
                        name={FormKeys.password}
                        value={props.password}
                        onChange={props.handleInput}
                        ref={passwordRef}
                        type="parentControlled"
                        handleToggle={(e: InputChange) =>
                            handleToggle({ e, ref: passwordRef, typeOfInput: 'password' })
                        }
                        visible={state.passwordVisible}
                        id={1}
                    />
                </label>
            </div>
            <div className="mb-5">
                <label className="block mb-2" htmlFor={FormKeys.newPassword}>
                    <span className="text-gray-700 font-semibold">New Password</span>
                    <MaskablePasswordInput
                        name={FormKeys.newPassword}
                        value={props.newPassword.value}
                        onChange={props.handleInput}
                        ref={newPasswordRef}
                        type="parentControlled"
                        handleToggle={(e: InputChange) =>
                            handleToggle({ e, ref: newPasswordRef, typeOfInput: 'newPassword' })
                        }
                        visible={state.newPasswordVisible}
                        id={2}
                    />
                </label>
                {props.newPassword.isValid === false && (
                    <p className="text-xs text-left block ml-1 mt-1 text-red-500">
                        Your password must be at least 8 characters
                    </p>
                )}
            </div>
            <SubmitButton loading={props.submitting} className="w-full mt-3">
                Change Password
            </SubmitButton>
        </div>
    );
}
