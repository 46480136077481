import React, { useContext, useEffect } from 'react';
import { isValidEmail } from '../utils';
import { FirestoreError, FormSubmission, InputChange, RouteProps } from '../types';
import SubmitButton from '../components/SubmitButton';
import ErrorMessage from '../components/ErrorMessage';
import { Theme } from '../theme';
import { Link, navigate } from '@reach/router';
import SplashPageContainer from '../components/SplashPageContainer';
import SplashPageForm from '../components/SplashPageForm';
import { useImmer } from 'use-immer';
import { CurrentUserContext } from '../context/CurrentUserContextProvider';
import OrganizationSelect from './OrganizationSelect';
import useNavigation from '../hooks/useNavigation';
import MaskablePasswordInput from '../components/MaskablePasswordInput';
import { UserFormKeys, UserRoles } from '../database/schemas/User';
import { Environments } from '../../firebaseConfig';
import TextInput from '../components/TextInput';
import AuthManager from '../AuthManager';
// @ts-ignore
import Logo from '../img/logo-white.png';

const minPasswordLength = 8;

type LoginFormType = { email: string; password: string };

interface State {
    form: LoginFormType;
    emailStatus: Credential;
    passwordStatus: Credential;
    formValid: boolean;
    submittingForm: boolean;
    formSubmissionError: FirestoreError | null;
    userMustSelectOrganization?: boolean;
}

const credentials = {
    [UserRoles.provider]: { email: 'tuckerblackwell+org2_provider@crosscomm.com', password: 'SpaceJam2019' },
    [UserRoles.orgAdmin]: { email: 'tuckerblackwell+org2_admin@crosscomm.com', password: 'SpaceJam2019' },
    [UserRoles.appAdmin]: { email: 'tuckerblackwell+app_admin@crosscomm.com', password: 'SpaceJam2019' },
};

const initialFormState = {
    [UserFormKeys.email]:
        process.env.REACT_APP_ENV === Environments.INTERNAL ? credentials[UserRoles.provider].email : '',
    [UserFormKeys.password]:
        process.env.REACT_APP_ENV === Environments.INTERNAL ? credentials[UserRoles.provider].password : '',
};

type Credential = { missing: boolean | null; valid: boolean | null };
const initialCredentialState: Credential = { missing: null, valid: null };

const initialLoginState: State = {
    form: initialFormState,
    emailStatus: initialCredentialState,
    passwordStatus: initialCredentialState,
    formValid: false,
    submittingForm: false,
    formSubmissionError: null,
};

export default function Login(_: RouteProps) {
    const [state, updateState] = useImmer<State>(initialLoginState);
    const currentUser = useContext(CurrentUserContext);
    const navigation = useNavigation(currentUser.claims?.currentOrgId);

    const _setDevCredentials = (role: UserRoles) => {
        updateState(draft => {
            draft.form.email = credentials[role].email;
            draft.form.password = credentials[role].password;
        });
    };

    const handleInput = (e: InputChange) => {
        e.persist();
        updateState(draft => {
            draft.formSubmissionError = null;
            draft.emailStatus = initialCredentialState;
            draft.passwordStatus = initialCredentialState;
            draft.form[e.target.name] = e.target.value;
        });
    };

    const handleSubmit = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        updateState(draft => {
            draft.submittingForm = true;
        });
        const { email, password } = state.form;
        try {
            await AuthManager.login({
                email: email.trim(),
                password: password.trim(),
            });
        } catch (error) {
            console.log(error);
            updateState(draft => void (draft.formSubmissionError = error));
        }
        updateState(draft => {
            draft.submittingForm = false;
        });
    };

    useEffect(() => {
        updateState(draft => {
            draft.formValid =
                !!state.form.email &&
                isValidEmail(state.form.email) &&
                !!state.form.password &&
                state.form.password.length >= minPasswordLength;
        });
    }, [state.form.email, state.form.password]);

    useEffect(() => {
        (async () => {
            //user is not logged in, skip all of this logic
            if (!currentUser.claims) {
                return;
            }
            if (currentUser.claims?.isAppAdmin) {
                await navigate(navigation.adminOrganizationsUrl);
                //user is logged in but does not have an org set
            } else if (currentUser.claims && !currentUser.claims.currentOrgId) {
                updateState(draft => void (draft.userMustSelectOrganization = true));
                //the user has an org set, so check their view context before setting their home route
            } else if (currentUser.claims?.currentOrgId && !currentUser.claims.isPatient) {
                await navigation.directUserBasedOnRole({
                    claims: currentUser.claims,
                    viewContext: currentUser.viewContext,
                });
            } else {
                await navigate(navigation.unauthorizedUrl);
            }
        })();
    }, [currentUser.claims?.currentOrgId]);

    return (
        <>
            {process.env.REACT_APP_ENV === Environments.INTERNAL && (
                <div className="flex bg-white flex-row justify-center items-center mx-auto border-b border-gray-400 rounded-md p-3">
                    <code className="block text-lg text-blue-600 mr-4">INTERNAL ENVIRONMENT</code>
                    <span className="block font-mono">Set role-based credentials 👉</span>
                    <button
                        className={`mx-2 text-blue-600 px-1 ${
                            credentials[UserRoles.provider].email === state.form.email
                                ? 'font-bold'
                                : 'font-normal'
                        }`}
                        onClick={() => _setDevCredentials(UserRoles.provider)}
                    >
                        <code>provider</code>
                    </button>
                    <button
                        className={`mx-2 text-blue-600 px-1 ${
                            credentials[UserRoles.orgAdmin].email === state.form.email
                                ? 'font-bold'
                                : 'font-normal'
                        }`}
                        onClick={() => _setDevCredentials(UserRoles.orgAdmin)}
                    >
                        <code>orgAdmin</code>
                    </button>
                    <button
                        className={`mx-2 text-blue-600 px-1 ${
                            credentials[UserRoles.appAdmin].email === state.form.email
                                ? 'font-bold'
                                : 'font-normal'
                        }`}
                        onClick={() => _setDevCredentials(UserRoles.appAdmin)}
                    >
                        <code>appAdmin</code>
                    </button>
                </div>
            )}
            <SplashPageContainer>
                <div className="mx-auto text-center">
                    <img src={Logo} alt="Symmetry logo" height={200} className="mx-auto mb-4" />
                </div>
                {state.userMustSelectOrganization ? (
                    <OrganizationSelect />
                ) : (
                    <SplashPageForm onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor={UserFormKeys.email}
                            >
                                Email
                            </label>
                            <TextInput
                                className={`py-1 ${
                                    state.emailStatus.missing ||
                                    (state.emailStatus.missing === false && !isValidEmail(state.form.email))
                                        ? 'border-red-500'
                                        : 'input-border'
                                }`}
                                type="email"
                                name={UserFormKeys.email}
                                value={state.form.email}
                                onChange={handleInput}
                                onBlur={() => {
                                    let emailStatus: Credential;
                                    if (!state.form.email) {
                                        emailStatus = { missing: true, valid: null };
                                    } else if (state.form.email && !isValidEmail(state.form.email)) {
                                        emailStatus = { missing: null, valid: false };
                                    } else {
                                        emailStatus = initialCredentialState;
                                    }
                                    updateState(draft => void (draft.emailStatus = emailStatus));
                                }}
                            />
                            {state.emailStatus.missing && (
                                <p className="text-red-500 text-xs italic">Please enter your email.</p>
                            )}
                            {state.emailStatus.missing === null && state.emailStatus.valid === false && (
                                <p className="text-red-500 text-xs italic">
                                    Please enter a valid email address.
                                </p>
                            )}
                        </div>
                        <div className="mb-6">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor={UserFormKeys.password}
                            >
                                Password
                            </label>
                            <MaskablePasswordInput
                                className={
                                    !state.passwordStatus.missing && state.passwordStatus.valid === false
                                        ? 'border-red-500'
                                        : 'input-border'
                                }
                                name={UserFormKeys.password}
                                value={state.form.password}
                                tooShort={state.passwordStatus.valid === false}
                                onChange={handleInput}
                                type="selfControlled"
                            />
                            {state.passwordStatus.missing && (
                                <p className="text-red-500 text-xs italic">Please enter a password.</p>
                            )}
                            {state.passwordStatus.missing === null &&
                                state.passwordStatus.valid === false && (
                                    <p className="text-red-500 text-xs italic">
                                        Your password must be at least 8 characters
                                    </p>
                                )}
                            {!!state.formSubmissionError && (
                                <ErrorMessage className="mt-3">
                                    {state.formSubmissionError.code === 'auth/user-not-found' ||
                                    'auth/wrong-password'
                                        ? "The credentials you've provided are invalid"
                                        : 'An unknown error has occurred. Please Check your internet connection & try again.'}
                                </ErrorMessage>
                            )}
                        </div>
                        <div className="flex items-center justify-between">
                            <SubmitButton
                                loading={state.submittingForm}
                                className={`bg-${Theme.darkBlue} hover:bg-${Theme.darkBlueHover} text-white font-bold py-1 px-2 md:py-2 md:px-4 rounded focus:outline-none focus:shadow-outline`}
                                disabled={!state.formValid}
                            >
                                Login
                            </SubmitButton>
                            <Link
                                className={`inline-block align-baseline font-bold text-xs md:text-sm text-${Theme.darkBlue} hover:text-${Theme.darkBlueHover} ml-auto pt-5`}
                                to={navigation.resetPasswordUrl}
                            >
                                Forgot Password?
                            </Link>
                        </div>
                        {/* todo: add better error messaging */}
                    </SplashPageForm>
                )}
                <p className="text-center text-white text-xs">
                    &copy;2020 Duke Symmetry. All rights reserved.
                </p>
            </SplashPageContainer>
        </>
    );
}
