import React, { useCallback, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import MedicationTab from './tabs/medication/MedicationTab';
import HistoryTab from './tabs/history/HistoryTab';
import { RouteProps } from '../../types';
import PageContainer from './../../components/PageContainer';
import PatientTab from './../../pages/patients/tabs/patient/PatientTab';
import { useImmer } from 'use-immer';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientById } from '../../redux/selectedPatient/selectedPatientActions';
import PatientDetailsHeader from './PatientDetailsHeader';
import { ReduxState } from '../../redux/store';
import isAuthed from '../../hooks/isAuthed';
import { selectSelectedPatientActiveAlerts } from '../../redux/selectedPatient/selectedPatientSelectors';
import Alert from '../../components/Alert';
import { setToastError } from '../../redux/currentSession/currentSessionActions';
import Tabulation, { Tab } from '../../components/Tabulation';
import { UrlParams } from '../../routes';
import { UserRoles } from '../../database/schemas/User';

export enum TabKeys {
    PATIENT = 'PATIENT',
    MEDICATIONS = 'MEDICATIONS',
    HISTORY = 'HISTORY',
}

interface Props extends RouteProps {
    userId?: UrlParams.userId;
}

interface State {
    error: unknown;
    loading: boolean;
    tabs: Tab[];
}

const initialTabState: Tab[] = [
    { key: TabKeys.PATIENT, active: true, Component: PatientTab, title: 'Patient', type: 'component' },
    {
        key: TabKeys.MEDICATIONS,
        active: false,
        Component: MedicationTab,
        title: 'Medications',
        type: 'component',
    },
    { key: TabKeys.HISTORY, active: false, Component: HistoryTab, title: 'History', type: 'component' },
];

export default function PatientDetails(props: Props) {
    isAuthed([UserRoles.provider]);
    const [state, updateState] = useImmer<State>({ error: null, loading: true, tabs: initialTabState });
    const selectedPatientActiveAlerts = useSelector((state: ReduxState) =>
        selectSelectedPatientActiveAlerts(state)
    );
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            if (props.userId) {
                try {
                    dispatch(getPatientById(props.userId));
                } catch (error) {
                    console.log(error);
                    updateState(draft => void (draft.error = error));
                    dispatch(setToastError('Error occurred while fetching patient'));
                }
                // add a bit of padding to the loading to account for firestore delay
                setTimeout(() => {
                    updateState(draft => void (draft.loading = false));
                }, 1000);
            }
        })();
    }, [props.userId]);

    const switchTab = useCallback((tabKey: string) => {
        updateState(draft => void draft.tabs.map(tab => (tab.active = tab.key === tabKey)));
    }, []);

    return (
        <PageContainer>
            <PatientDetailsHeader loading={state.loading} />
            {selectedPatientActiveAlerts.map(alert => (
                <Alert key={alert.id} alert={alert} />
            ))}
            <Tabulation tabs={state.tabs} switchTab={switchTab} loading={state.loading} />
        </PageContainer>
    );
}
