import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../redux/store';
import {
    selectSelectedPatientDocument,
    selectSelectedPatientUpdates,
} from '../../redux/selectedPatient/selectedPatientSelectors';
import ActionButton from '../../components/ActionButton';
import {
    cancelChangesToPatient,
    saveChangesToPatient,
} from '../../redux/selectedPatient/selectedPatientActions';
import LoadingDots from '../../components/LoadingDots';
import { PatientDocument } from '../../database/documents/PatientDocument';
import { Theme } from '../../theme';
import { useImmer } from 'use-immer';
import ConfirmationModal from '../../components/ConfirmationModal';
import IconManager, { IconType } from '../../components/IconManager';

interface Props {
    //passed as props to update parent loading state
    loading: boolean;
}

enum ModalScenarios {
    CANCELING_PATIENT_UPDATES,
}

interface State {
    modalScenario?: ModalScenarios;
    toolTipVisible: boolean;
}

export default function PatientDetailsHeader(props: Props) {
    const [state, updateState] = useImmer<State>({ toolTipVisible: false });
    const selectedPatientUpdates = useSelector((state: ReduxState) => selectSelectedPatientUpdates(state));
    const selectedPatient = useSelector((state: ReduxState) => selectSelectedPatientDocument(state));
    const dispatch = useDispatch();

    const saveChanges = useCallback(() => {
        dispatch(saveChangesToPatient());
    }, []);

    const cancelChanges = useCallback(() => {
        dispatch(cancelChangesToPatient());
        toggleModal();
    }, []);

    const derivePatientHeaderInfo = (patient: PatientDocument | null): string | null => {
        if (!patient?.data) return null;
        return `${patient.data.lastName}, ${patient.data.firstName} (#${patient.data.mrn})`;
    };

    const toggleModal = (modalScenario?: ModalScenarios) =>
        updateState(draft => void (draft.modalScenario = modalScenario));

    return (
        <div className="w-full flex flex-col mb-2">
            <div className="w-full my-3 flex flex-row justify-between">
                <div className="flex flex-row justify-start items-center w-1/2 relative">
                    <h2 className="block font-semibold text-3xl text-gray-800 mr-2">Patient Details</h2>
                    {!!selectedPatientUpdates.length && (
                        <span
                            className={`text-${Theme.mediumBlue} cursor-pointer text-sm flex flex-row items-center justify-start`}
                        >
                            <IconManager
                                type={IconType.INFO_CIRCLE}
                                size={20}
                                stroke="#2f3da8"
                                className="mr-1"
                            />
                            You have unsaved change(s)
                        </span>
                    )}
                </div>
                <div className="flex flex-row justify-end items-center">
                    <ActionButton
                        className="px-4"
                        disabled={!selectedPatientUpdates.length}
                        onClick={() => toggleModal(ModalScenarios.CANCELING_PATIENT_UPDATES)}
                        color={Theme.mediumBlue}
                    >
                        Cancel
                    </ActionButton>
                    <ActionButton
                        className="block mx-2 px-4"
                        onClick={saveChanges}
                        color={Theme.mediumBlue}
                        disabled={!selectedPatientUpdates.length}
                    >
                        Save
                    </ActionButton>
                </div>
            </div>
            <div className="h-10">
                {props.loading ? (
                    <LoadingDots className="mb-3 ml-2" />
                ) : (
                    <h1 className="text-3xl text-black">{derivePatientHeaderInfo(selectedPatient)}</h1>
                )}
            </div>
            <ConfirmationModal
                isOpen={state.modalScenario !== undefined}
                closeModal={() => toggleModal()}
                onConfirm={cancelChanges}
            >
                <p>
                    Are you sure you want to discard your unsaved change(s) to{' '}
                    {selectedPatient?.data.firstName}'s record?
                </p>
            </ConfirmationModal>
        </div>
    );
}
