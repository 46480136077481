import { ProviderDocument } from '../../database/documents/ProviderDocument';
import { PatientDocument } from '../../database/documents/PatientDocument';
import { OrganizationDocument } from '../../database/documents/OrganizationDocument';
import produce from 'immer';
import { OpioidAgreementDocument } from '../../database/documents/OpioidAgreementDocument';
import { UserDocument } from '../../database/documents/UserDocument';

export type OrganizationUser = ProviderDocument | UserDocument;

export interface SelectedOrganizationReduxState {
    orgAdmins: OrganizationUser[];
    providers: ProviderDocument[];
    patients: PatientDocument[];
    document: OrganizationDocument | null;
    loading: boolean;
    opioidAgreements: GroupedOpioidAgreements;
}

export type GroupedOpioidAgreements = {
    active: OpioidAgreementDocument[];
    archived: OpioidAgreementDocument[];
};

export const initialSelectedOrganizationReduxState: SelectedOrganizationReduxState = {
    orgAdmins: [],
    providers: [],
    patients: [],
    document: null,
    loading: true,
    opioidAgreements: { active: [], archived: [] },
};

export enum SelectedOrganizationActions {
    SET_SELECTED_ORGANIZATION_DATA_FOR_USER = 'SET_SELECTED_ORGANIZATION_DATA_FOR_USER',
    CLEAR_SELECTED_ORGANIZATION = 'CLEAR_SELECTED_ORGANIZATION',
    ADD_OPIOID_AGREEMENT = 'ADD_OPIOID_AGREEMENT',
    TOGGLE_OPIOID_AGREEMENT_ARCHIVED_STATUS = 'TOGGLE_OPIOID_AGREEMENT_ARCHIVED_STATUS',
    UPDATE_OPIOID_AGREEMENT_TITLE = 'UPDATE_OPIOID_AGREEMENT_TITLE',
    UPDATE_USER_IN_SELECTED_ORGANIZATION = 'UPDATE_USER_IN_SELECTED_ORGANIZATION',
    UPDATE_USER_ROLE_IN_SELECTED_ORGANIZATION = 'UPDATE_USER_ROLE_IN_SELECTED_ORGANIZATION',
}

export type SelectedOrganizationActionTypes =
    | { type: SelectedOrganizationActions.CLEAR_SELECTED_ORGANIZATION }
    | { type: SelectedOrganizationActions.ADD_OPIOID_AGREEMENT; payload: OpioidAgreementDocument }
    | {
          type: SelectedOrganizationActions.TOGGLE_OPIOID_AGREEMENT_ARCHIVED_STATUS;
          payload: OpioidAgreementDocument;
      }
    | {
          type: SelectedOrganizationActions.UPDATE_OPIOID_AGREEMENT_TITLE;
          payload: { opioidAgreementId: string; title: string };
      }
    | {
          type: SelectedOrganizationActions.SET_SELECTED_ORGANIZATION_DATA_FOR_USER;
          payload: Omit<SelectedOrganizationReduxState, 'loading'>;
      }
    | {
          type:
              | SelectedOrganizationActions.UPDATE_USER_IN_SELECTED_ORGANIZATION
              | SelectedOrganizationActions.UPDATE_USER_ROLE_IN_SELECTED_ORGANIZATION;
          payload: OrganizationUser;
      };

export default function selectedOrganizationReducer(
    state: SelectedOrganizationReduxState = initialSelectedOrganizationReduxState,
    action: SelectedOrganizationActionTypes
): SelectedOrganizationReduxState {
    switch (action.type) {
        case SelectedOrganizationActions.SET_SELECTED_ORGANIZATION_DATA_FOR_USER:
            return produce(state, draft => {
                draft.document = action.payload.document;
                draft.providers = action.payload.providers;
                draft.patients = action.payload.patients;
                draft.opioidAgreements = action.payload.opioidAgreements;
                draft.orgAdmins = action.payload.orgAdmins;
                draft.loading = false;
            });
        case SelectedOrganizationActions.CLEAR_SELECTED_ORGANIZATION:
            return initialSelectedOrganizationReduxState;
        case SelectedOrganizationActions.ADD_OPIOID_AGREEMENT:
            return produce(state, draft => {
                draft.opioidAgreements.active.push(action.payload);
                draft.opioidAgreements.active.sort(
                    (a, b) => a.data.createdAt.seconds - b.data.createdAt.seconds
                );
            });
        case SelectedOrganizationActions.TOGGLE_OPIOID_AGREEMENT_ARCHIVED_STATUS:
            return produce(state, draft => {
                const { archivedAt } = action.payload.data;
                const { archived, active } = draft.opioidAgreements;
                const origin = archivedAt ? archived : active;
                const destination = archivedAt ? active : archived;
                origin.splice(
                    origin.findIndex(({ id }) => id === action.payload.id),
                    1
                );
                destination.push(action.payload);
            });
        case SelectedOrganizationActions.UPDATE_OPIOID_AGREEMENT_TITLE:
            return produce(state, draft => {
                draft.opioidAgreements.active[
                    draft.opioidAgreements.active.findIndex(
                        ({ id }) => id === action.payload.opioidAgreementId
                    )
                ].data.title = action.payload.title;
            });
        case SelectedOrganizationActions.UPDATE_USER_IN_SELECTED_ORGANIZATION:
            return produce(state, draft => {
                if (action.payload.data.isOrgAdmin) {
                    draft.orgAdmins[draft.orgAdmins.findIndex(({ id }) => id === action.payload.id)] =
                        action.payload;
                }
                if (action.payload.data.isProvider) {
                    draft.providers[
                        draft.providers.findIndex(({ id }) => id === action.payload.id)
                    ] = action.payload as ProviderDocument;
                }
            });
        case SelectedOrganizationActions.UPDATE_USER_ROLE_IN_SELECTED_ORGANIZATION:
            return produce(state, draft => {
                const userAsOrgAdminIndex = draft.orgAdmins.findIndex(({ id }) => id === action.payload.id);
                const userAsProviderIndex = draft.providers.findIndex(({ id }) => id === action.payload.id);
                //if user was made a provider | org admin, remove them if they already were in redux, add them if not
                //note that right now, provider status is not being toggled
                userAsProviderIndex !== -1
                    ? draft.providers.splice(userAsProviderIndex, 1)
                    : draft.providers.push(action.payload as ProviderDocument);
                userAsOrgAdminIndex !== -1
                    ? draft.orgAdmins.splice(userAsOrgAdminIndex, 1)
                    : draft.orgAdmins.push(action.payload);
            });
        default:
            return state;
    }
}
