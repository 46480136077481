import { navigate } from '@reach/router';
import { UserRoles } from './database/schemas/User';
import { Claims } from './Claims';

export enum UrlParams {
    organizationId = ':organizationId',
    opioidAgreementId = ':opioidAgreementId',
    userId = ':userId',
}

export enum QueryParams {
    organizationId = 'organizationId',
}

export enum NonAuthRoutes {
    LOGIN = '/',
    RESET_PASSWORD = '/reset-password',
    ACCOUNT_SETUP = '/createAccount',
    UNAUTHORIZED = '/unauthorized',
    DOWNLOAD_MOBILE_APP = '/download',
}

export const AuthRoutes = {
    ACCOUNT: '/account',

    PATIENTS: `/organizations/${UrlParams.organizationId}/patients`,
    ADD_PATIENT: `/organizations/${UrlParams.organizationId}/patients/add`,
    VIEW_PATIENT: `/organizations/${UrlParams.organizationId}/patients/${UrlParams.userId}`,

    ORGANIZATIONS: '/organizations',
    OPIOID_AGREEMENT_RENEWALS: `/organizations/${UrlParams.organizationId}/opioid-agreement-renewals`,

    ADMIN_ORGANIZATIONS: '/admin/organizations',
    ADMIN_ORGANIZATIONS_VIEW_ORGANIZATION: `/admin/organizations/${UrlParams.organizationId}/org-admins`,
    ADMIN_ORGANIZATIONS_VIEW_ADMIN: `/admin/organizations/${UrlParams.organizationId}/org-admins/${UrlParams.userId}`,

    //NESTED
    VIEW_ORGANIZATION: `/organizations/${UrlParams.organizationId}`,

    PROVIDERS: '/providers',
    ORG_ADMINS: '/org-admins',
    VIEW_ORG_ADMIN: `/org-admins/${UrlParams.userId}`,
    ADD_PROVIDER: '/providers/add',
    VIEW_PROVIDER: `/providers/${UrlParams.userId}`,

    OPIOID_AGREEMENTS: '/opioid-agreements',
    ADD_OPIOID_AGREEMENT: '/opioid-agreements/add',
    VIEW_OPIOID_AGREEMENT: `/opioid-agreements/${UrlParams.opioidAgreementId}`,
};

export default class Navigator {
    private _orgId?: string;
    constructor(orgId?: string) {
        this._orgId = orgId;
    }

    set orgId(orgId: string | undefined) {
        this._orgId = orgId;
    }

    get urlContainsOrgId() {
        return new RegExp(this.selectedOrganizationUrl, 'g').test(window.location.pathname);
    }

    //NON-AUTH
    get loginUrl() {
        return NonAuthRoutes.LOGIN;
    }

    get resetPasswordUrl() {
        return NonAuthRoutes.RESET_PASSWORD;
    }

    get unauthorizedUrl() {
        return NonAuthRoutes.UNAUTHORIZED;
    }

    get selectedOrganizationUrl() {
        return `/organizations/${this._orgId}`;
    }

    //AUTH
    get accountUrl() {
        return AuthRoutes.ACCOUNT;
    }

    get adminOrganizationsUrl() {
        return AuthRoutes.ADMIN_ORGANIZATIONS;
    }

    get patientsUrl() {
        return `${this.selectedOrganizationUrl}/patients`;
    }

    getPatientsUrl(orgId: string) {
        return `${AuthRoutes.ORGANIZATIONS}/${orgId}/patients`;
    }

    get patientsAddUrl() {
        return `${AuthRoutes.ORGANIZATIONS}/${this._orgId}/patients/add`;
    }

    getOrganizationAdminsUrl(orgId: string) {
        return `${AuthRoutes.ADMIN_ORGANIZATIONS}/${orgId}/org-admins`;
    }

    getOrganizationAdminDetailsUrl({ orgId, adminId }: { orgId: string; adminId: string }) {
        return `${AuthRoutes.ADMIN_ORGANIZATIONS}/${orgId}/org-admins/${adminId}`;
    }

    //Nested route documentation for reference https://reach.tech/router/example/nested-routes
    // NESTED ROUTES -- ROOT URL === selectedOrganizationUrl

    getPatientDetailsUrl(patientId: string) {
        return `./patients/${patientId}`;
    }

    getOpioidAgreementDetailsUrl(opioidAgreementId: string) {
        return this.urlContainsOrgId ? opioidAgreementId : `./opioid-agreements/${opioidAgreementId}`;
    }

    get opioidAgreementRenewalsUrl() {
        return `${AuthRoutes.ORGANIZATIONS}/${this._orgId}/opioid-agreement-renewals`;
    }

    get providersUrl() {
        return `${AuthRoutes.ORGANIZATIONS}/${this._orgId}/providers`;
    }

    getProvidersUrl(orgId: string) {
        return `${AuthRoutes.ORGANIZATIONS}/${orgId}/providers`;
    }

    get orgAdminsUrl() {
        return './org-admins';
    }

    getProviderDetailsUrl(providerId: string) {
        return this.urlContainsOrgId ? providerId : `./providers/${providerId}`;
    }

    getOrgAdminDetailsUrl(orgAdminId: string) {
        return this.urlContainsOrgId ? orgAdminId : `./org-admins/${orgAdminId}`;
    }

    get organizationOpioidAgreementsUrl() {
        return `./opioid-agreements`;
    }

    get organizationOpioidAgreementAddUrl() {
        return 'add';
    }

    async directUserBasedOnRole({
        claims,
        viewContext,
        organizationId = claims.currentOrgId!,
    }: {
        claims: Claims;
        viewContext: UserRoles | null;
        organizationId?: string;
    }) {
        const providerHomeRoute = this.getPatientsUrl(organizationId);
        const orgAdminHomeRoute = this.getProvidersUrl(organizationId);
        if (claims.isProviderInCurrentOrganization()) {
            if (!viewContext) {
                await navigate(providerHomeRoute);
            } else {
                await navigate(viewContext === UserRoles.provider ? providerHomeRoute : orgAdminHomeRoute);
            }
        } else if (claims.isOrgAdminInCurrentOrganization()) {
            if (!viewContext) {
                await navigate(orgAdminHomeRoute);
            } else {
                await navigate(viewContext === UserRoles.provider ? providerHomeRoute : orgAdminHomeRoute);
            }
        } else {
            await navigate(orgAdminHomeRoute);
        }
    }
}
