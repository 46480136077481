import { AlertDocument } from '../../database/documents/AlertDocument';
import { PatientDocument } from '../../database/documents/PatientDocument';
import { UserMedicationDocument } from '../../database/documents/UserMedicationDocument';
import produce from 'immer';
import { EntryDocument } from '../../database/documents/EntryDocument';
import { ClientMedicationSchema } from '../../pages/patients/tabs/medication/medicationTypes';
import set from 'lodash.set';
import { InvitePatientRequest } from '../../functions/inviteTypes';
import { Gender, PatientKeys, UserMedicationSchema } from '../../database/schemas/Patient';

export type MedicationFormType = UserMedicationDocument | ClientMedicationSchema;

export const initialPatientFormState: InvitePatientRequest = {
    [PatientKeys.firstName]: '',
    [PatientKeys.lastName]: '',
    [PatientKeys.email]: '',
    [PatientKeys.dob]: '',
    [PatientKeys.mrn]: '',
    [PatientKeys.gender]: Gender.MALE,
    [PatientKeys.emergencyPhoneNumber]: '',
    [PatientKeys.assignedPrescriber]: '',
};

export type GroupedAlerts = { active: AlertDocument[]; resolved: AlertDocument[] };

export interface SelectedPatientState {
    document: PatientDocument | null;
    form: InvitePatientRequest;
    medications: MedicationFormType[];
    entries: EntryDocument[];
    alerts: GroupedAlerts;
}

export enum SelectedPatientActions {
    //DOCUMENT
    UPDATE_SELECTED_PATIENT_DOCUMENT_FIELD = 'UPDATE_SELECTED_PATIENT_DOCUMENT_FIELD',
    //MEDICATIONS
    UPDATE_SELECTED_PATIENT_MEDICATION = 'UPDATE_SELECTED_PATIENT_MEDICATION',
    REMOVE_SELECTED_PATIENT_MEDICATION = 'REMOVE_SELECTED_PATIENT_MEDICATION',
    ADD_SELECTED_PATIENT_MEDICATION = 'ADD_SELECTED_PATIENT_MEDICATION',
    SET_SELECTED_PATIENT_MEDICATIONS = 'SET_SELECTED_PATIENT_MEDICATIONS',
    //FORM
    SET_SELECTED_PATIENT_FORM = 'SET_SELECTED_PATIENT_FORM',
    UPDATE_SELECTED_PATIENT_FORM_PROP = 'UPDATE_SELECTED_PATIENT_FORM_PROP',
    CLEAR_SELECTED_PATIENT_FORM = 'CLEAR_SELECTED_PATIENT_FORM',
    //set all patient data
    SET_SELECTED_PATIENT = 'SET_SELECTED_PATIENT',
    CLEAR_SELECTED_PATIENT = 'CLEAR_SELECTED_PATIENT',
    MERGE_UPDATED_PATIENT_FIELDS = 'MERGE_UPDATED_PATIENT_FIELDS',
    //ENTRIES
    SET_SELECTED_PATIENT_ENTRIES = 'SET_SELECTED_PATIENT_ENTRIES',
    //CANCEL/SAVE
    CANCEL_CHANGES_FOR_PATIENT = 'CANCEL_CHANGES_FOR_PATIENT',
    //ALERTS
    TOGGLE_ALERT_RESOLVED = 'TOGGLE_ALERT_RESOLVED',
    SET_SELECTED_PATIENT_ALERTS = 'SET_SELECTED_PATIENT_ALERTS',
}

const initialSelectedPatientState: SelectedPatientState = {
    document: null,
    form: initialPatientFormState,
    medications: [],
    entries: [],
    alerts: { active: [], resolved: [] },
};

//updatedProperties not currently in use for this phase but will potentially aid in accomplishing more itemized changes
//as mentioned in
const initialSelectedPatientDiffState: SelectedPatientDiffState = {
    ...initialSelectedPatientState,
    updatedProperties: [],
};

type SelectedPatientDiffState = SelectedPatientState & { updatedProperties: string[] };

export interface SelectedPatientReduxState {
    original: SelectedPatientState;
    diff: SelectedPatientState & { updatedProperties: string[] };
}

export const initialSelectedPatientReduxState: SelectedPatientReduxState = {
    original: initialSelectedPatientState,
    diff: initialSelectedPatientDiffState,
};
export type UpdatedPatientFields = (keyof SelectedPatientState)[];
// export type UpdatedPatientFields = { field: keyof SelectedPatientDiffState, properties: string[] };
export type UpdatePatientArgs = { path: PatientKeys; value: any };
export type SelectedPatientActionTypes =
    | {
          type: SelectedPatientActions.UPDATE_SELECTED_PATIENT_DOCUMENT_FIELD;
          payload: UpdatePatientArgs;
      }
    | {
          type: SelectedPatientActions.SET_SELECTED_PATIENT_MEDICATIONS;
          payload: SelectedPatientState['medications'];
      }
    | {
          type: SelectedPatientActions.UPDATE_SELECTED_PATIENT_MEDICATION;
          payload: { index: number; updatedMedication: UserMedicationDocument };
      }
    | { type: SelectedPatientActions.REMOVE_SELECTED_PATIENT_MEDICATION; payload: number }
    | { type: SelectedPatientActions.ADD_SELECTED_PATIENT_MEDICATION; payload: UserMedicationSchema }
    | { type: SelectedPatientActions.SET_SELECTED_PATIENT_FORM; payload: SelectedPatientState['form'] }
    | {
          type: SelectedPatientActions.UPDATE_SELECTED_PATIENT_FORM_PROP;
          payload: UpdatePatientArgs;
      }
    | { type: SelectedPatientActions.SET_SELECTED_PATIENT; payload: SelectedPatientState }
    | { type: SelectedPatientActions.MERGE_UPDATED_PATIENT_FIELDS }
    | { type: SelectedPatientActions.SET_SELECTED_PATIENT_ALERTS; payload: GroupedAlerts }
    | { type: SelectedPatientActions.SET_SELECTED_PATIENT_ENTRIES; payload: SelectedPatientState['entries'] }
    | { type: SelectedPatientActions.CLEAR_SELECTED_PATIENT }
    | { type: SelectedPatientActions.CANCEL_CHANGES_FOR_PATIENT }
    | { type: SelectedPatientActions.CLEAR_SELECTED_PATIENT_FORM }
    | { type: SelectedPatientActions.TOGGLE_ALERT_RESOLVED; payload: string };

export default function selectedPatientReducer(
    state: SelectedPatientReduxState = initialSelectedPatientReduxState,
    action: SelectedPatientActionTypes
): SelectedPatientReduxState {
    switch (action.type) {
        //SETTING OF ORIGINAL PATIENT STATE
        case SelectedPatientActions.SET_SELECTED_PATIENT_MEDICATIONS:
            return produce(state, draft => {
                draft.original.medications = action.payload;
                draft.diff.medications = action.payload;
            });
        case SelectedPatientActions.SET_SELECTED_PATIENT:
            return produce(state, draft => {
                draft.original = action.payload;
                draft.diff = { ...action.payload, updatedProperties: [] };
            });
        case SelectedPatientActions.MERGE_UPDATED_PATIENT_FIELDS:
            return produce(state, draft => {
                const { updatedProperties, ...diff } = draft.diff;
                draft.original = diff;
            });
        case SelectedPatientActions.SET_SELECTED_PATIENT_FORM:
            return produce(state, draft => {
                draft.original.form = action.payload;
                draft.diff.form = action.payload;
            });
        case SelectedPatientActions.SET_SELECTED_PATIENT_ENTRIES:
            return produce(state, draft => {
                draft.original.entries = action.payload;
                draft.diff.entries = action.payload;
            });
        case SelectedPatientActions.SET_SELECTED_PATIENT_ALERTS:
            return produce(state, draft => {
                draft.diff.alerts = action.payload;
                draft.original.alerts = action.payload;
            });
        // UPDATES TO PATIENT DIFF
        case SelectedPatientActions.UPDATE_SELECTED_PATIENT_DOCUMENT_FIELD:
            return produce(state, draft => {
                if (draft.diff.document) {
                    draft.diff.updatedProperties.push(action.payload.path);
                    set(draft.diff.document.data, action.payload.path, action.payload.value);
                }
            });
        case SelectedPatientActions.UPDATE_SELECTED_PATIENT_MEDICATION:
            return produce(state, draft => {
                draft.diff.medications[action.payload.index] = action.payload.updatedMedication;
            });
        case SelectedPatientActions.REMOVE_SELECTED_PATIENT_MEDICATION:
            return produce(state, draft => {
                draft.diff.medications.splice(action.payload, 1);
            });
        case SelectedPatientActions.CLEAR_SELECTED_PATIENT_FORM:
            return produce(state, draft => {
                draft.diff.form = initialPatientFormState;
            });
        case SelectedPatientActions.ADD_SELECTED_PATIENT_MEDICATION:
            return produce(state, draft => {
                draft.diff.medications.push(action.payload);
            });
        case SelectedPatientActions.CLEAR_SELECTED_PATIENT:
            return produce(state, draft => {
                draft.original = initialSelectedPatientState;
                draft.diff = initialSelectedPatientDiffState;
            });
        case SelectedPatientActions.UPDATE_SELECTED_PATIENT_FORM_PROP:
            return produce(state, draft => {
                draft.diff.updatedProperties.push(action.payload.path);
                draft.diff.form[action.payload.path] = action.payload.value;
            });
        case SelectedPatientActions.CANCEL_CHANGES_FOR_PATIENT:
            return produce(state, draft => {
                draft.diff = { ...draft.original, updatedProperties: [] };
            });
        case SelectedPatientActions.TOGGLE_ALERT_RESOLVED:
            return produce(state, draft => {
                draft.diff.alerts.active.find(({ id }) => id === action.payload)?.toggleAcknowledged();
            });
        default:
            return state;
    }
}
