import React, { useCallback, useEffect } from 'react';
import IconManager, { IconType } from '../../../../components/IconManager';
import { Theme } from '../../../../theme';
import MedicationForm from './MedicationForm';
import Modal from '../../../../components/Modal';
import { UserMedicationDocument } from '../../../../database/documents/UserMedicationDocument';
import { convertDocToSchema, isFirestoreDocument, scrollPageTo } from '../../../../utils';
import { useImmer } from 'use-immer';
import { MedicationFormOnChange } from './medicationTypes';
import { MedicationFormType } from '../../../../redux/selectedPatient/selectedPatientReducer';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../redux/store';
import {
    selectSelectedPatientDocument,
    selectSelectedPatientMedications,
} from '../../../../redux/selectedPatient/selectedPatientSelectors';
import {
    addMedicationForSelectedPatient,
    removeMedicationForSelectedPatient,
    updatedMedicationForSelectedPatient,
} from '../../../../redux/selectedPatient/selectedPatientActions';
import { MedicationDocument } from '../../../../database/documents/MedicationDocument';
import DatabaseManager from '../../../../database/DatabaseManager';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import set from 'lodash.set';
import { selectToastAlert } from '../../../../redux/currentSession/currentSessionSelectors';
import ToastAlert from '../../../../components/ToastAlert';
import { UserMedicationSchema } from '../../../../database/schemas/Patient';

export interface State {
    medicationForms: MedicationFormType[];
    focusedMedicationIndex?: number;
    addingMedication: boolean;
    modalScenario?: MedicationTabModalScenarios;
    medicationOptions: MedicationDocument[];
    loading: boolean;
}

export default function MedicationTab() {
    const [state, updateState] = useImmer<State>({
        medicationForms: [],
        addingMedication: false,
        medicationOptions: [],
        loading: true,
    });
    const selectedPatient = useSelector((state: ReduxState) => selectSelectedPatientDocument(state));
    const selectedPatientMedications = useSelector((state: ReduxState) =>
        selectSelectedPatientMedications(state)
    );
    const dispatch = useDispatch();
    const toastAlert = useSelector((state: ReduxState) => selectToastAlert(state));

    useEffect(() => {
        (async () => {
            try {
                if (selectedPatientMedications) {
                    const medicationOptions = await DatabaseManager.MedicationModel.getAll();
                    updateState(draft => {
                        draft.medicationForms = selectedPatientMedications;
                        draft.medicationOptions = medicationOptions;
                    });
                }
            } catch (error) {
                console.log(error);
            }
            updateState(draft => void (draft.loading = false));
        })();
    }, [selectedPatientMedications]);

    const updateMedicationFormProp: MedicationFormOnChange = useCallback(
        formState => {
            updateState(draft => {
                if (draft.focusedMedicationIndex !== undefined) {
                    const formInFocus = convertDocToSchema<UserMedicationDocument, UserMedicationSchema>(
                        draft.medicationForms[draft.focusedMedicationIndex]
                    );
                    //MedicationFormKeys enum is designed specifically work with dot notation to
                    //update nested props on UserMedicationSchema object
                    set(formInFocus, formState.prop, formState.value);
                }
            });
        },
        [state.focusedMedicationIndex]
    );

    const resetMedicationForm = useCallback(() => {
        updateState(draft => {
            draft.medicationForms[draft.focusedMedicationIndex!] = {} as UserMedicationSchema;
        });
    }, []);

    const addMedicationForPatient = () => {
        const newMedication = {
            ...state.medicationForms[state.focusedMedicationIndex!],
            archived: false,
        } as UserMedicationSchema;
        dispatch(addMedicationForSelectedPatient(newMedication));
        updateState(draft => {
            draft.addingMedication = false;
            draft.focusedMedicationIndex = undefined;
        });
    };

    const updateMedicationForPatient = () => {
        dispatch(
            updatedMedicationForSelectedPatient({
                medicationIndex: state.focusedMedicationIndex!,
                updatedMedication: state.medicationForms[
                    state.focusedMedicationIndex!
                ] as UserMedicationDocument,
            })
        );
        updateState(draft => void (draft.focusedMedicationIndex = undefined));
    };

    const removeFocusedMedicationFromState = async () => {
        if (state.focusedMedicationIndex !== undefined) {
            const targetForm = state.medicationForms[state.focusedMedicationIndex];
            if (isFirestoreDocument<UserMedicationDocument>(targetForm)) {
                await targetForm.update({ archived: true });
            }
            dispatch(removeMedicationForSelectedPatient(state.focusedMedicationIndex));
            updateState(draft => {
                draft.medicationForms.splice(draft.focusedMedicationIndex!, 1);
                draft.addingMedication = false;
            });
        }
    };

    const handleRemove = async (): Promise<void> => {
        await removeFocusedMedicationFromState();
        toggleModal();
    };

    const toggleModal = (scenario?: MedicationTabModalScenarios) => {
        updateState(draft => void (draft.modalScenario = scenario));
    };

    const setAddingNewMedication = useCallback(() => {
        updateState(draft => {
            draft.addingMedication = true;
            draft.medicationForms.push({} as MedicationFormType);
            draft.focusedMedicationIndex = draft.medicationForms.length - 1;
        });
        scrollPageTo('bottom');
    }, []);

    const cancelAddingNewMedication = async () => {
        await removeFocusedMedicationFromState();
        scrollPageTo('top');
    };

    const cancelUpdatingMedication = () => {
        updateState(draft => {
            if (draft.focusedMedicationIndex !== undefined) {
                draft.medicationForms[draft.focusedMedicationIndex] =
                    selectedPatientMedications[draft.focusedMedicationIndex];
                draft.focusedMedicationIndex = undefined;
            }
        });
    };

    const saveOrUpdateAction = () => {
        if (state.focusedMedicationIndex !== undefined) {
            return isFirestoreDocument<UserMedicationDocument>(
                state.medicationForms[state.focusedMedicationIndex]
            )
                ? updateMedicationForPatient()
                : addMedicationForPatient();
        }
    };

    const cancelSaveOrUpdateAction = () => {
        if (state.focusedMedicationIndex !== undefined) {
            return isFirestoreDocument<UserMedicationDocument>(
                state.medicationForms[state.focusedMedicationIndex]
            )
                ? cancelUpdatingMedication()
                : cancelAddingNewMedication();
        }
    };

    const renderMedicationClassNames = (focused: boolean): string =>
        `bg-white rounded-md ${
            focused ? `border-2 border-${Theme.lightBlue}` : 'border border-gray-400'
        } my-2`;

    return (
        <>
            <div className="py-8 w-11/12 mx-auto">
                {toastAlert.visible && <ToastAlert message={toastAlert.message} />}
                <div className="py-2 flex flex-row justify-between items-center mb-3">
                    <h3 className="text-lg font-semibold text-blue-900 block mr-2">
                        {selectedPatient?.data.firstName}'s Medications
                    </h3>
                    <button
                        className={`rounded-lg px-2 mr-2 text-white flex flex-row items-center bg-${Theme.darkBlue} hover:bg-${Theme.darkBlueHover} p-1`}
                        onClick={setAddingNewMedication}
                    >
                        <IconManager
                            type={IconType.PLUS}
                            stroke="#fff"
                            size={19}
                            strokeWidth={3}
                            className="mr-1"
                        />
                        <span className="font-semibold">Add medication</span>
                    </button>
                </div>
                <MedicationsList
                    loading={state.loading}
                    patientHasZeroMedications={!state.medicationForms.length}
                >
                    <>
                        {state.medicationForms.map((medicationForm, i) => (
                            <div
                                className={renderMedicationClassNames(i === state.focusedMedicationIndex)}
                                key={i}
                                onClick={() => updateState(draft => void (draft.focusedMedicationIndex = i))}
                            >
                                <MedicationForm
                                    medicationOptions={state.medicationOptions}
                                    medicationForm={medicationForm}
                                    setFormProp={updateMedicationFormProp}
                                    cancelAction={cancelSaveOrUpdateAction}
                                    action={saveOrUpdateAction}
                                    deleteMedicationModalVisible={
                                        state.modalScenario ===
                                        MedicationTabModalScenarios.REMOVING_MEDICATION
                                    }
                                    presentDeleteMedicationModal={toggleModal}
                                    addingMedication={state.addingMedication}
                                    showRemoveButton={
                                        isFirestoreDocument<UserMedicationDocument>(medicationForm) ||
                                        selectedPatientMedications.includes(medicationForm)
                                    }
                                    resetMedicationForm={resetMedicationForm}
                                />
                            </div>
                        ))}
                    </>
                </MedicationsList>
            </div>
            <Modal isOpen={!!state.modalScenario} closeModal={() => toggleModal()}>
                <div className="px-5 flex flex-col justify-center">
                    <h3 className="text-lg text-gray-800 text-center">
                        Are you sure you want to remove {selectedPatient?.data.firstName}'s{' '}
                        <b>
                            {
                                convertDocToSchema<UserMedicationDocument, UserMedicationSchema>(
                                    state.medicationForms[state.focusedMedicationIndex!]
                                )?.name
                            }
                        </b>{' '}
                        prescription?
                    </h3>
                    <div className="mt-2 p-3 flex flex-row justify-center">
                        <button
                            className="mx-1 text-gray-600 hover:text-gray-800"
                            onClick={() => toggleModal()}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleRemove}
                            className={`mx-1 bg-${Theme.darkBlue} hover:bg-${Theme.darkBlueHover} text-white font-semibold rounded-md px-2 py-1 border-none focus:outline-none`}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

interface MedicationListProps {
    children: JSX.Element;
    loading: boolean;
    patientHasZeroMedications: boolean;
}

function MedicationsList(props: MedicationListProps): JSX.Element {
    if (props.loading) {
        return <LoadingSpinner type="page" />;
    } else if (props.patientHasZeroMedications) {
        return (
            <div className="text-left">
                <h3 className="text-gray-600">
                    This patient does not currently have any active prescriptions.
                </h3>
            </div>
        );
    } else {
        return props.children;
    }
}

export enum MedicationTabModalScenarios {
    REMOVING_MEDICATION,
}
