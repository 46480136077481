import React, { useCallback, useContext, useEffect } from 'react';
import ActionButton from '../../../components/ActionButton';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { AlertDocument } from '../../../database/documents/AlertDocument';
import {
    BrowserStorageKeys,
    ButtonClick,
    FetchRequest,
    FormSubmission,
    InputChange,
    RouteProps,
} from '../../../types';
import { navigate } from '@reach/router';
import { useImmer } from 'use-immer';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../redux/store';
import { selectSelectedPatientDocument } from '../../../redux/selectedPatient/selectedPatientSelectors';
import { clearSelectedPatient } from '../../../redux/selectedPatient/selectedPatientActions';
import {
    deriveAndFormatTierThreeAndInfoAlerts,
    deriveTableRowDataFromPatients,
} from './patientTableFormatters';
import {
    selectSelectedOrganization,
    selectSelectedOrganizationPatients,
} from '../../../redux/selectedOrganization/selectedOrganizationSelectors';
import isAuthed from '../../../hooks/isAuthed';
import { getTimestamp, toPascalCase } from '../../../utils';
import * as TableComponents from './patientTableDataComponents';
import { AlertSchema, MonitoringLevel, PatientDashboardRow } from '../../../database/schemas/Patient';
import SegmentedControl from '../../../components/SegmentedControl';
import AlertDashboard from './AlertDashboard';
import { CurrentUserContext } from '../../../context/CurrentUserContextProvider';
import TextInput from '../../../components/TextInput';
import IconManager, { IconType } from '../../../components/IconManager';
import { setToastError } from '../../../redux/currentSession/currentSessionActions';
import { selectToastAlert } from '../../../redux/currentSession/currentSessionSelectors';
import ToastAlert from '../../../components/ToastAlert';
import { PatientSortOrderBy } from '../../../database/DatabaseTypes';
import isEqual from 'lodash/isequal';
import useNavigation from '../../../hooks/useNavigation';
import { UserRoles } from '../../../database/schemas/User';
import { ProviderDocument } from '../../../database/documents/ProviderDocument';
import { Theme } from '../../../theme';
import { parseSearchQuery } from './patientQueryFormatters';
import { PatientDocument } from '../../../database/documents/PatientDocument';
import SubmitButton from '../../../components/SubmitButton';
import { PatientQueryRequest } from '../../../functions/userTypes';
import Encouragement from '../../../components/Encouragement';
import useBrowserStorage from '../../../hooks/useBrowserStorage';
import { UrlParams } from '../../../routes';
import { changeCurrentUserSelectedOrganization } from '../../../redux/selectedOrganization/selectedOrganizationActions';
import LoadMoreButton from '../../../components/LoadMoreButton';

type TableRow = {
    assignedPrescriberId: string;
    patientId: string;
};

export type AlertDashboardData = { tierThree: AlertTableRow[]; informational: AlertTableRow[] };
export type PatientTableRow = PatientDashboardRow & TableRow;
export type AlertTableRow = Omit<AlertSchema, 'updatedAt' | 'cause' | 'tier' | 'date' | 'organizationId'> &
    TableRow & {
        alerts: AlertDocument[];
        patientPhoneNumber: string;
        patientName: string;
    };
export enum SearchFilters {
    constipation = 'constipation',
    lastentry = 'lastentry',
    monitoring = 'monitoring',
    pain = 'pain',
    shortacting = 'shortacting',
}

export type SearchError = {
    type:
        | 'invalidFilterField'
        | 'invalidMonitoringLevel'
        | 'invalidPain'
        | 'invalidShortActing'
        | 'invalidConstipation'
        | 'invalidLastEntry';
};

const SearchErrorStrings = {
    invalidFilterField:
        'Invalid filter. Valid filters are monitoring:, pain:, shortacting:, constipation:, and lastentry:',
    invalidMonitoringLevel:
        'Invalid monitoring level filter. Valid filters are monitoring:none, monitoring:low, monitoring:medium, and monitoring:high.',
    invalidPain: 'Invalid pain level filter. Valid filters are pain:0 through pain:10',
    invalidShortActing:
        'Invalid short acting filter. Valid filters are shortacting:none, shortacting:low, shortacting:medium, and shortacting:high.',
    invalidConstipation:
        'Invalid constipation filter. Valid filters are constipation:1, constipation:2, etc.',
    invalidLastEntry: 'Invalid lastentry filter. Valid filters are lastentry:1, lastentry:2, etc.',
};

export interface Search {
    fetching?: boolean;
    searchString: string;
    suggestion?: SearchFilters;
    error?: SearchError;
    remainingPatientCount?: number; //number of patients that match a search that aren't in view
}
export type SortField = keyof PatientDashboardRow;
export type SortOrder = 'ascending' | 'descending';

export interface SortStatus {
    order: SortOrder;
    isActive: boolean;
    priority?: number;
}

const defaultSort: PatientSortOrderBy[] = [
    {
        field: 'lastBowelMovementDate',
        descending: false,
        active: true,
    },
    { field: 'lastAverageDayPainLevel', descending: true, active: true },
    { field: 'shortActingOpioidUsage', descending: true, active: true },
];

enum LastTierThreeOrInformationalAlert {
    tier = 'lastTierThreeOrInformationalAlert.tier',
    acknowledgedAt = 'lastTierThreeOrInformationalAlert.acknowledgedAt',
}

const initialSearchState: Search = { searchString: '' };

const initialSortState: PatientSortOrderBy[] = [
    ...defaultSort,
    { field: 'lastEntryDate', descending: true, active: false },
    { field: 'monitoringLevel', descending: true, active: false },
    { field: 'lowestRefillPercentage', descending: true, active: false },
    {
        field: LastTierThreeOrInformationalAlert.tier as any,
        descending: true,
        active: false,
    },
    {
        field: LastTierThreeOrInformationalAlert.acknowledgedAt as any,
        descending: false,
        active: false,
    },
    { field: 'lastName', descending: true, active: false },
    { field: 'firstName', descending: false, active: false },
];

type ListData<T> = {
    patients: T[];
    alerts: AlertTableRow[];
    search: Search;
    sort: PatientSortOrderBy[];
    loadingMore?: boolean;
};

interface Props extends RouteProps {
    organizationId?: UrlParams;
}

export type CurrentUserPatientsData = {
    onlyForCurrentUserPatients: boolean;
    currentUserIdAndDelegatorsIds: string[];
};

export interface State {
    providerDashboardData: FetchRequest<Pick<ListData<PatientDocument>, 'patients' | 'alerts'>>;
    currentUserDelegatorIds: string[];
    patientTableRows: ListData<PatientTableRow> & { filtering?: boolean };
    patientFilter: TargetPatients;
    toolTipVisible: boolean;
    encourageUserToSetContactNumber?: boolean;
    refreshedCurrentOrganization: boolean;
}

export enum TargetPatients {
    CURRENT_ORGANIZATION_PATIENTS,
    CURRENT_USER_PATIENTS,
}

export const initialPatientListState: State = {
    providerDashboardData: {
        fetching: true,
        data: { patients: [], alerts: [] },
        error: null,
    },
    currentUserDelegatorIds: [],
    patientFilter: sessionStorage.getItem(
        BrowserStorageKeys.PROVIDER_HAS_SELECTED_THEIR_PATIENTS_IN_PATIENT_LIST
    )
        ? TargetPatients.CURRENT_USER_PATIENTS
        : TargetPatients.CURRENT_ORGANIZATION_PATIENTS,
    patientTableRows: {
        patients: [],
        alerts: [],
        sort: initialSortState,
        search: initialSearchState,
    },
    toolTipVisible: false,
    refreshedCurrentOrganization: false,
};

export default function PatientsList(props: Props) {
    isAuthed([UserRoles.provider]);
    const currentUser = useContext(CurrentUserContext);
    const [state, updateState] = useImmer<State>(initialPatientListState);
    const selectedOrganizationPatients = useSelector((state: ReduxState) =>
        selectSelectedOrganizationPatients(state)
    );
    const selectedPatient = useSelector((state: ReduxState) => selectSelectedPatientDocument(state));
    const selectedOrganization = useSelector((state: ReduxState) => selectSelectedOrganization(state));
    const dispatch = useDispatch();
    const toastAlert = useSelector((state: ReduxState) => selectToastAlert(state));
    const navigation = useNavigation(currentUser.claims?.currentOrgId);
    //session set variables for user settings
    const encouragementToSetContactNumber = useBrowserStorage({
        type: 'session',
        key: BrowserStorageKeys.PROVIDER_HAS_DISMISSED_CONTACT_NUMBER_ENCOURAGEMENT,
    });
    const providerSelectedTheirPatients = useBrowserStorage({
        type: 'session',
        key: BrowserStorageKeys.PROVIDER_HAS_SELECTED_THEIR_PATIENTS_IN_PATIENT_LIST,
    });

    const encourageProviderToSetContactNumber =
        currentUser.claims?.isProviderInCurrentOrganization() &&
        !(currentUser.document as ProviderDocument).data.phoneNumber &&
        !encouragementToSetContactNumber.itemExistsInStorage;

    /*
     * USE EFFECTS
     * */

    // fetch initial page data on mount
    useEffect(() => {
        (async () => {
            if (selectedOrganization && currentUser.claims?.currentOrgId) {
                //the organizationId on user's custom claims differs from what is set in redux so dispatch a change to update it
                if (
                    props.organizationId &&
                    props.organizationId !== currentUser.claims.currentOrgId &&
                    !state.refreshedCurrentOrganization
                ) {
                    dispatch(
                        changeCurrentUserSelectedOrganization({
                            currentUserClaims: currentUser.claims,
                            newOrganizationId: props.organizationId,
                        })
                    );
                    updateState(draft => void (draft.refreshedCurrentOrganization = true));
                } else {
                    try {
                        const delegatorIds = await (currentUser.document as ProviderDocument).getDelegatorsIds(
                            currentUser.claims.currentOrgId
                        );
                        const [patientPageInfo, alerts] = await deriveDashboardData({
                            onlyForCurrentUserPatients:
                                state.patientFilter === TargetPatients.CURRENT_USER_PATIENTS,
                            currentUserIdAndDelegatorsIds: [currentUser.document?.id, ...delegatorIds],
                        });
                        updateState(draft => {
                            draft.currentUserDelegatorIds = delegatorIds;
                            draft.encourageUserToSetContactNumber = encourageProviderToSetContactNumber;
                            draft.patientTableRows.search.remainingPatientCount = patientPageInfo.remaining;
                            draft.providerDashboardData.data.patients = patientPageInfo.patients;
                            draft.providerDashboardData.data.alerts = alerts;
                            draft.patientTableRows.patients = deriveTableRowDataFromPatients(
                                patientPageInfo.patients
                            );
                            draft.patientTableRows.alerts = alerts;
                        });
                    } catch (error) {
                        console.log(error);
                        dispatch(setToastError('An error occurred while gathering patient data'));
                    }
                }
                updateState(draft => void (draft.providerDashboardData.fetching = false));
            }
        })();
    }, [selectedOrganization?.id]);

    // listen for changes to sort & query patients accordingly (not fired on initial mount)
    useEffect(() => {
        (async () => {
            if (selectedOrganization && currentUser.document) {
                try {
                    //check to make sure selected sort is not default sort before firing query
                    if (!isEqual(state.patientTableRows.sort, defaultSort)) {
                        const { queries, search } = parseSearchQuery(
                            state.patientTableRows.search.searchString
                        );
                        const { patients, remaining } = await selectedOrganization.queryPatients({
                            orderBy: Sort.queryOrderBy,
                            queries,
                            search,
                            ...Search.currentUserPatientsData,
                        });
                        updateState(draft => {
                            draft.patientTableRows.patients = deriveTableRowDataFromPatients(patients);
                            draft.patientTableRows.search.remainingPatientCount = remaining;
                        });
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        })();
    }, [state.patientTableRows.sort]);

    // make sure there is not a selected patient in redux state
    useEffect(() => {
        (async () => {
            if (selectedPatient?.data) {
                dispatch(clearSelectedPatient());
            }
        })();
    }, [selectedPatient?.data]);

    /*
     * END USE EFFECTS
     * */

    const deriveDashboardData = async (
        currentUserPatientsData: CurrentUserPatientsData = Search.currentUserPatientsData
    ) => {
        return await Promise.all([
            selectedOrganization!.queryPatients({
                orderBy: Sort.queryOrderBy,
                ...currentUserPatientsData,
            }),
            deriveAndFormatTierThreeAndInfoAlerts({
                selectedOrganization: selectedOrganization!,
                ...currentUserPatientsData,
            }),
        ]);
    };

    const handlePatientContactedCheckbox = async (patientId: string) => {
        updateState(draft => {
            const selectedAlert = draft.patientTableRows.alerts.find(alert => patientId === alert.patientId);
            if (selectedAlert) {
                selectedAlert.acknowledgedAt = selectedAlert.acknowledgedAt ? null : getTimestamp();
            }
        });
    };

    const toggleToolTipVisible = useCallback(() => {
        updateState(draft => void (draft.toolTipVisible = !draft.toolTipVisible));
    }, []);

    const Search = {
        get searchString() {
            return state.patientTableRows.search.searchString;
        },
        get remainingPatientCount() {
            return state.patientTableRows.search.remainingPatientCount;
        },
        get currentUserPatientsData(): CurrentUserPatientsData {
            return {
                currentUserIdAndDelegatorsIds: [currentUser.document!.id, ...state.currentUserDelegatorIds],
                onlyForCurrentUserPatients: state.patientFilter === TargetPatients.CURRENT_USER_PATIENTS,
            };
        },
        async fireQuery(e: FormSubmission): Promise<void> {
            e.preventDefault();
            try {
                updateState(draft => void (draft.patientTableRows.search.fetching = true));
                const { queries, search } = parseSearchQuery(state.patientTableRows.search.searchString);
                const { patients, remaining } = await selectedOrganization!.queryPatients({
                    queries,
                    search,
                    orderBy: Sort.queryOrderBy,
                    ...Search.currentUserPatientsData,
                });
                updateState(draft => {
                    draft.patientTableRows.search.remainingPatientCount = remaining;
                    draft.patientTableRows.patients = deriveTableRowDataFromPatients(patients);
                });
            } catch (error) {
                console.log(error);
                updateState(draft => void (draft.patientTableRows.search.error = error));
            }
            updateState(draft => void (draft.patientTableRows.search.fetching = false));
        },
        async clear(e: ButtonClick): Promise<void> {
            e.preventDefault();
            const { patients, remaining } = await selectedOrganization!.queryPatients({
                orderBy: Sort.queryOrderBy,
                ...Search.currentUserPatientsData,
            });
            updateState(draft => {
                draft.patientTableRows.search = { searchString: '', remainingPatientCount: remaining };
                draft.patientTableRows.patients = deriveTableRowDataFromPatients(patients);
            });
        },
        handleInput(e: InputChange) {
            e.persist();
            updateState(draft => {
                for (const key in SearchFilters) {
                    if (!!e.target.value && SearchFilters[key].startsWith(e.target.value)) {
                        draft.patientTableRows.search.suggestion = SearchFilters[key];
                    }
                }
                draft.patientTableRows.search.searchString = e.target.value;
            });
        },
        async filterPatients(e: InputChange): Promise<void> {
            e.persist();
            updateState(draft => void (draft.patientTableRows.filtering = true));
            const filter = Number(e.target.value) as TargetPatients;
            filter === TargetPatients.CURRENT_USER_PATIENTS
                ? providerSelectedTheirPatients.setInStorage()
                : providerSelectedTheirPatients.clearFromStorage();
            const [patientPageInfo, alerts] = await deriveDashboardData({
                onlyForCurrentUserPatients: filter === TargetPatients.CURRENT_USER_PATIENTS,
                currentUserIdAndDelegatorsIds: Search.currentUserPatientsData.currentUserIdAndDelegatorsIds,
            });
            updateState(draft => {
                draft.patientFilter = filter;
                draft.patientTableRows.patients = deriveTableRowDataFromPatients(patientPageInfo.patients);
                draft.patientTableRows.search.remainingPatientCount = patientPageInfo.remaining;
                draft.patientTableRows.alerts = alerts;
                draft.patientTableRows.filtering = false;
            });
        },
    };

    const Sort = {
        get sort() {
            return state.patientTableRows.sort;
        },
        get isDefaultSort() {
            return (
                this.isFieldActive('lastBowelMovementDate') &&
                this.isFieldActive('lastAverageDayPainLevel') &&
                this.isFieldActive('shortActingOpioidUsage')
            );
        },
        get queryOrderBy(): PatientQueryRequest['orderBy'] {
            return state.patientTableRows.sort
                .filter(({ active }) => active)
                .map(({ field, descending }) => [field, descending ? 'descending' : 'ascending']);
        },
        reset() {
            updateState(draft => {
                draft.patientTableRows.sort = initialSortState;
                draft.patientTableRows.patients = deriveTableRowDataFromPatients(
                    draft.providerDashboardData.data.patients as PatientDocument[]
                );
            });
        },
        orderForField(sortField: SortField): SortOrder {
            return this.sort.find(({ field }) => field === sortField)?.descending
                ? 'descending'
                : 'ascending';
        },
        isFieldActive(sortField: SortField): boolean {
            return this.sort.find(({ field }) => field === sortField)?.active ?? false;
        },
        priorityForField(sortField: SortField): number | undefined {
            if (this.isFieldActive(sortField)) {
                switch (sortField) {
                    case 'lastBowelMovementDate':
                        return this.isDefaultSort ? 1 : undefined;
                    case 'lastAverageDayPainLevel':
                        return this.isDefaultSort ? 2 : undefined;
                    case 'shortActingOpioidUsage':
                        return this.isDefaultSort ? 3 : undefined;
                }
            }
        },
        toggleOrder(sortField: SortField): void {
            updateState(draft => {
                draft.patientTableRows.sort.map(sort => {
                    if (sort.field === sortField) {
                        sort.active = true;
                        sort.descending = !sort.descending;
                    } else {
                        sort.active = false;
                        sort.descending = true;
                    }
                });
            });
        },
        getStatusForField(sortField: SortField): SortStatus {
            return {
                isActive: this.isFieldActive(sortField),
                order: this.orderForField(sortField),
                priority: this.priorityForField(sortField),
            };
        },
        toggleNameOrder() {
            updateState(draft => {
                draft.patientTableRows.sort.map(sort => {
                    if (sort.field === 'lastName') {
                        sort.active = true;
                        sort.descending = !sort.descending;
                    } else if (sort.field === 'firstName') {
                        sort.active = true;
                        sort.descending = false;
                    } else {
                        sort.active = false;
                        sort.descending = true;
                    }
                });
            });
        },
        toggleLastAlertOrder() {
            updateState(draft => {
                draft.patientTableRows.sort.map(sort => {
                    if (
                        sort.field ===
                        ((LastTierThreeOrInformationalAlert.tier as any) as keyof PatientDashboardRow)
                    ) {
                        sort.active = true;
                        sort.descending = !sort.descending;
                    } else if (
                        sort.field ===
                        ((LastTierThreeOrInformationalAlert.acknowledgedAt as any) as keyof PatientDashboardRow)
                    ) {
                        sort.active = true;
                        sort.descending = false;
                    } else {
                        sort.active = false;
                        sort.descending = true;
                    }
                });
            });
        },
    };

    const loadMorePatients = async () => {
        updateState(draft => void (draft.patientTableRows.loadingMore = true));
        const lastPatientTableRow =
            state.patientTableRows.patients[state.patientTableRows.patients.length - 1];
        try {
            const { patients, remaining } = await selectedOrganization!.queryPatients({
                orderBy: Sort.queryOrderBy,
                startAfter: lastPatientTableRow.patientId,
                ...Search.currentUserPatientsData,
            });
            updateState(draft => {
                draft.patientTableRows.search.remainingPatientCount = remaining;
                draft.providerDashboardData.data.patients.push(...patients);
                draft.patientTableRows.patients.push(...deriveTableRowDataFromPatients(patients));
            });
        } catch (error) {
            console.log(error);
            dispatch(setToastError('An error occurred while trying to gather more patients'));
        }
        updateState(draft => void (draft.patientTableRows.loadingMore = false));
    };

    const dismissContactNumberEncouragement = (e: ButtonClick) => {
        e.preventDefault();
        updateState(draft => void (draft.encourageUserToSetContactNumber = undefined));
        encouragementToSetContactNumber.setInStorage();
    };

    const { remainingPatientCount } = state.patientTableRows.search;

    return (
        <>
            {state.providerDashboardData.fetching ? (
                <LoadingSpinner type="page" />
            ) : (
                <>
                    {toastAlert.visible && <ToastAlert message={toastAlert.message} />}
                    <div className="pt-6 w-11/12 mx-auto flex flex-row justify-end items-center">
                        {state.encourageUserToSetContactNumber && (
                            <div className="w-3/5 self-center mr-auto">
                                <Encouragement
                                    dismiss={dismissContactNumberEncouragement}
                                    title="Set Contact Number"
                                    message="This will be the number your patients will be prompted to contact in the case of an emergency"
                                    link={{
                                        to: navigation.accountUrl,
                                        label: 'Edit Your Account',
                                    }}
                                />
                            </div>
                        )}
                        <SegmentedControl
                            size="xl"
                            options={[
                                {
                                    label: 'All Patients',
                                    value: TargetPatients.CURRENT_ORGANIZATION_PATIENTS,
                                    checked:
                                        state.patientFilter === TargetPatients.CURRENT_ORGANIZATION_PATIENTS,
                                },
                                {
                                    label: 'My Patients',
                                    value: TargetPatients.CURRENT_USER_PATIENTS,
                                    checked: state.patientFilter === TargetPatients.CURRENT_USER_PATIENTS,
                                },
                            ]}
                            handleInput={Search.filterPatients}
                        />
                    </div>
                    {state.patientTableRows.filtering ? (
                        <LoadingSpinner type="page" />
                    ) : (
                        <>
                            <div style={{ maxWidth: '68rem' }} className="w-11/12 mx-auto mb-16">
                                {!!state.patientTableRows.alerts.length && (
                                    <AlertDashboard
                                        patientFilter={state.patientFilter}
                                        alerts={state.patientTableRows.alerts}
                                        handlePatientContactedCheckbox={handlePatientContactedCheckbox}
                                    />
                                )}
                            </div>
                            <div className="mx-auto mb-4" style={{ width: '95%' }}>
                                <div className="w-full mb-4 flex flex-row justify-between items-center">
                                    <div className="flex flex-row justify-start items-center">
                                        <h2 className="block text-3xl text-gray-900 mr-2">Patient List</h2>
                                        {!Sort.isDefaultSort && (
                                            <button
                                                onClick={Sort.reset}
                                                className={`mt-1 text-xs text-${Theme.lightBlue} hover:text-${Theme.lightBlueHover}`}
                                            >
                                                Reset sort
                                            </button>
                                        )}
                                    </div>
                                    <form
                                        className="flex flex-row w-1/3 justify-center items-center self-center relative"
                                        onSubmit={Search.fireQuery}
                                    >
                                        <div className="relative w-2/3">
                                            <TextInput
                                                value={Search.searchString}
                                                onChange={Search.handleInput}
                                                suggestion={state.patientTableRows.search.suggestion}
                                                setSuggestionAsValue={() =>
                                                    updateState(draft => {
                                                        draft.patientTableRows.search.searchString = String(
                                                            draft.patientTableRows.search.suggestion
                                                        );
                                                    })
                                                }
                                                className={`py-1 ${
                                                    state.patientTableRows.search.error
                                                        ? 'border border-red-500'
                                                        : ''
                                                }`}
                                                clearable={true}
                                                clear={Search.clear}
                                            />
                                            {state.patientTableRows.search.error && (
                                                <div className="w-full absolute left-0 mt-2 text-center bg-white rounded-md shadow-md z-50 p-2 border border-dashed border-red-500">
                                                    <p className="text-xs text-red-500 block">
                                                        {
                                                            SearchErrorStrings[
                                                                state.patientTableRows.search.error.type
                                                            ]
                                                        }
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                        <SubmitButton
                                            loading={state.patientTableRows.search.fetching}
                                            className="mx-2"
                                        >
                                            Search
                                        </SubmitButton>
                                        <span
                                            onMouseEnter={toggleToolTipVisible}
                                            onMouseLeave={toggleToolTipVisible}
                                            className="cursor-pointer p-1"
                                        >
                                            <IconManager
                                                type={IconType.INFO_CIRCLE}
                                                size={20}
                                                stroke="#3B98CF"
                                            />
                                        </span>
                                        <TableComponents.PatientSearchToolTip
                                            visible={state.toolTipVisible}
                                            belowIcon={!state.patientTableRows.alerts.length}
                                        />
                                    </form>
                                    <ActionButton linkTo={navigation.patientsAddUrl} className="block">
                                        Add Patient
                                    </ActionButton>
                                </div>
                                <div className="w-full">
                                    <table className="table-auto w-full">
                                        <thead>
                                            <tr className="bg-gray-200">
                                                <TableComponents.SortableColumn
                                                    title="Name"
                                                    toggleSortOrder={() => Sort.toggleNameOrder()}
                                                    sortStatus={Sort.getStatusForField('lastName')}
                                                />
                                                <TableComponents.SortableColumn
                                                    title="Avg Daily Pain"
                                                    toggleSortOrder={() =>
                                                        Sort.toggleOrder('lastAverageDayPainLevel')
                                                    }
                                                    sortStatus={Sort.getStatusForField(
                                                        'lastAverageDayPainLevel'
                                                    )}
                                                />
                                                <TableComponents.SortableColumn title="Pain Sparkline" />
                                                <TableComponents.SortableColumn
                                                    title="Constipation"
                                                    toggleSortOrder={() =>
                                                        Sort.toggleOrder('lastBowelMovementDate')
                                                    }
                                                    sortStatus={Sort.getStatusForField(
                                                        'lastBowelMovementDate'
                                                    )}
                                                />
                                                <TableComponents.SortableColumn
                                                    title="Day of Last Entry"
                                                    toggleSortOrder={() => Sort.toggleOrder('lastEntryDate')}
                                                    sortStatus={Sort.getStatusForField('lastEntryDate')}
                                                />
                                                <TableComponents.SortableColumn
                                                    title="Refill Status"
                                                    toggleSortOrder={() =>
                                                        Sort.toggleOrder('lowestRefillPercentage')
                                                    }
                                                    sortStatus={Sort.getStatusForField(
                                                        'lowestRefillPercentage'
                                                    )}
                                                />
                                                <TableComponents.SortableColumn
                                                    title="Short Acting Usage"
                                                    toggleSortOrder={() =>
                                                        Sort.toggleOrder('shortActingOpioidUsage')
                                                    }
                                                    sortStatus={Sort.getStatusForField(
                                                        'shortActingOpioidUsage'
                                                    )}
                                                />
                                                <TableComponents.SortableColumn
                                                    title="Previous Alert & Contact"
                                                    toggleSortOrder={() => Sort.toggleLastAlertOrder()}
                                                    sortStatus={Sort.getStatusForField(
                                                        (LastTierThreeOrInformationalAlert.tier as any) as keyof PatientDashboardRow
                                                    )}
                                                />
                                                <TableComponents.SortableColumn
                                                    title="Monitoring Frequency"
                                                    toggleSortOrder={() =>
                                                        Sort.toggleOrder('monitoringLevel')
                                                    }
                                                    sortStatus={Sort.getStatusForField('monitoringLevel')}
                                                />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!state.patientTableRows.patients.length ? (
                                                <tr className="w-full text-gray-600 text-center">
                                                    <td
                                                        className="border w-full font-medium px-4 py-3"
                                                        colSpan={9}
                                                    >
                                                        {state.patientFilter ===
                                                        TargetPatients.CURRENT_USER_PATIENTS
                                                            ? 'You are not the primary provider for any patients'
                                                            : 'No patients found'}
                                                    </td>
                                                </tr>
                                            ) : (
                                                state.patientTableRows.patients.map(tableData => (
                                                    <tr
                                                        key={tableData.patientId}
                                                        className="cursor-pointer border-blue-600"
                                                        onClick={() =>
                                                            navigate(
                                                                navigation.getPatientDetailsUrl(
                                                                    tableData.patientId
                                                                )
                                                            )
                                                        }
                                                    >
                                                        <td className="border px-4 py-2">
                                                            {tableData.lastName}, {tableData.firstName}
                                                        </td>
                                                        <td className="border px-4 py-2">
                                                            <TableComponents.PainLevel
                                                                value={tableData.lastAverageDayPainLevel}
                                                            />
                                                        </td>
                                                        <td className="border px-4 py-2">
                                                            <TableComponents.PainLevelSparkLine
                                                                painLevels={tableData.painSparkline}
                                                            />
                                                        </td>
                                                        <td className="border px-4 py-2">
                                                            <TableComponents.ConstipationDays
                                                                lastBowelMovementDate={
                                                                    tableData.lastBowelMovementDate
                                                                }
                                                            />
                                                        </td>
                                                        <td className="border px-4 py-2">
                                                            <TableComponents.DayOfLastEntry
                                                                dayOfLastEntry={tableData.lastEntryDate}
                                                                patientDoesNotHaveAccount={
                                                                    selectedOrganizationPatients.find(
                                                                        ({ id }) => id === tableData.patientId
                                                                    )?.data.welcomeCompleted === false
                                                                }
                                                            />
                                                        </td>
                                                        <td className="border px-4 py-2">
                                                            <TableComponents.RefillStatus
                                                                percentage={tableData.lowestRefillPercentage}
                                                            />
                                                        </td>
                                                        <td className="border px-4 py-2">
                                                            <TableComponents.ShortActingUsage
                                                                opioidRiskStatus={
                                                                    tableData.shortActingOpioidUsage
                                                                }
                                                            />
                                                        </td>
                                                        <td className="border px-4 py-2">
                                                            <TableComponents.PreviousAlertContact
                                                                alert={
                                                                    tableData.lastTierThreeOrInformationalAlert
                                                                }
                                                            />
                                                        </td>
                                                        <td className="border px-4 py-2">
                                                            {toPascalCase(
                                                                MonitoringLevel[tableData.monitoringLevel]
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                    {remainingPatientCount !== undefined && remainingPatientCount > 0 && (
                                        <div className="w-full flex flex-row justify-center items-center py-3 mt-2">
                                            <LoadMoreButton
                                                onClick={loadMorePatients}
                                                remainingCount={remainingPatientCount}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
}
