import FirebaseApp from '../firestore';
import { auth } from 'firebase/app';

export type Credentials = {
    email: string;
    password: string;
};

class AuthManager {
    constructor(private auth: auth.Auth) {}
    get currentUserEmail() {
        return this.auth.currentUser?.email;
    }

    async login({ email, password }: Credentials): Promise<void> {
        await this.auth.signInWithEmailAndPassword(email, password);
    }

    async logout(): Promise<void> {
        return this.auth.signOut();
    }

    async refreshUserSession(): Promise<void> {
        await this.auth.currentUser?.getIdTokenResult(true);
    }

    async createUserAccount({ email, password }: { email: string; password: string }) {
        await this.auth.createUserWithEmailAndPassword(email, password);
    }

    async changePassword({
        email,
        password,
        newPassword,
    }: { newPassword: string } & Credentials): Promise<void> {
        await this.login({ email, password });
        await this.auth.currentUser?.updatePassword(newPassword);
    }

    async changeEmail({ email, password, newEmail }: { newEmail: string } & Credentials): Promise<void> {
        await this.login({ email, password });
        await this.auth.currentUser?.verifyBeforeUpdateEmail(newEmail);
        await this.logout();
    }
}

export default new AuthManager(FirebaseApp.auth());
