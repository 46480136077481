import React, { useContext, useEffect, useRef } from 'react';
import { BrowserStorageKeys, FormSubmission, ReactSelectState, SelectOption } from '../types';
import Select from '../components/Select';
import { useImmer } from 'use-immer';
import { OrganizationDocument } from '../database/documents/OrganizationDocument';
import { CurrentUserContext } from '../context/CurrentUserContextProvider';
import DatabaseManager from '../database/DatabaseManager';
import SubmitButton from '../components/SubmitButton';
import { useDispatch } from 'react-redux';
import { changeCurrentUserSelectedOrganization } from '../redux/selectedOrganization/selectedOrganizationActions';
import useNavigation from '../hooks/useNavigation';
import { navigate } from '@reach/router';
import useBrowserStorage from '../hooks/useBrowserStorage';

interface Props {
    isChangingOrganization?: boolean;
    closeModal?: () => void;
}

interface State {
    organizations: OrganizationDocument[];
    selectedOrganizationId?: string;
    loading: boolean;
    submittingForm?: boolean;
    submissionError?: unknown;
}

export default function OrganizationSelect(props: Props) {
    const [state, updateState] = useImmer<State>({ organizations: [], loading: true });
    const currentUser = useContext(CurrentUserContext);
    const navigation = useNavigation(currentUser.claims?.currentOrgId);
    const dispatch = useDispatch();
    const isMounted = useRef(false);
    const providerSelectedTheirPatients = useBrowserStorage({
        type: 'session',
        key: BrowserStorageKeys.PROVIDER_HAS_SELECTED_THEIR_PATIENTS_IN_PATIENT_LIST,
    });

    useEffect(() => {
        (async () => {
            isMounted.current = true;
            if (isMounted.current && currentUser.claims) {
                updateState(draft => void (draft.selectedOrganizationId = currentUser.claims?.currentOrgId));
                try {
                    const organizationIds = currentUser.claims.getOrgIds();
                    const organizations = await DatabaseManager.OrganizationModel.getByIds(organizationIds);
                    updateState(draft => void (draft.organizations = organizations));
                } catch (error) {
                    console.log(error);
                }
                updateState(draft => void (draft.loading = false));
            }
            return () => {
                isMounted.current = false;
            };
        })();
    }, [currentUser.claims]);

    const handleSubmit = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        updateState(draft => void (draft.submittingForm = true));
        if (currentUser.claims && state.selectedOrganizationId) {
            try {
                dispatch(
                    changeCurrentUserSelectedOrganization({
                        currentUserClaims: currentUser.claims,
                        newOrganizationId: state.selectedOrganizationId,
                    })
                );
                //once a user changes their organization, clear their selection of patient sub set in patient list
                providerSelectedTheirPatients.clearFromStorage();
                if (currentUser.claims) {
                    await navigation.directUserBasedOnRole({
                        claims: currentUser.claims,
                        viewContext: currentUser.viewContext,
                        organizationId: state.selectedOrganizationId,
                    });
                } else {
                    await navigate(navigation.unauthorizedUrl);
                }
            } catch (error) {
                console.log(error);
                updateState(draft => void (draft.submissionError = error));
            }
            updateState(draft => void (draft.submittingForm = false));
            props.closeModal?.();
        }
    };

    const organizationSelect: ReactSelectState = {
        options: state.organizations.map(({ data, id }) => ({ label: data.name, value: id })),
        get selectedOption() {
            return this.options.find(({ value }) => value === state.selectedOrganizationId);
        },
        handleSelect(option) {
            updateState(draft => void (draft.selectedOrganizationId = (option as SelectOption).value));
        },
    };

    const containerClassNames = !props.isChangingOrganization
        ? 'bg-gray-100 border border-gray-400 rounded-md py-5 shadow-md'
        : 'bg-gray-100 border-none shadow-none rounded-none w-7/8 mx-auto';

    return (
        <form className={containerClassNames} onSubmit={handleSubmit}>
            <h3 className="text-base text-center mb-2">
                {props.isChangingOrganization
                    ? 'Please select one of your organizations'
                    : 'You are a part of multiple organizations. Please select one to continue.'}
            </h3>
            <div className="py-2 px-3 w-11/12 mx-auto">
                <Select
                    isLoading={props.isChangingOrganization && !state.selectedOrganizationId}
                    options={organizationSelect.options}
                    selectedOption={organizationSelect.selectedOption}
                    onChange={organizationSelect.handleSelect}
                    placeholder={organizationSelect.selectedOption ? undefined : 'Select organization'}
                />
                <p className="text-xs text-gray-600 text-center block mt-2">
                    This will make all dashboard content specific to the selected organization
                </p>
                <SubmitButton
                    className="w-full mt-3"
                    loading={state.submittingForm}
                    disabled={!state.selectedOrganizationId}
                >
                    {props.isChangingOrganization ? 'Change Organization' : 'Continue'}
                </SubmitButton>
            </div>
        </form>
    );
}
