import { firestore } from 'firebase/app';
import { FirestoreAdapter } from '../FirebaseAdapter';
import { DocumentSnapshot } from '../FirestoreTypes';
import { Timestamps } from '../schemas/Timestamps';
import { immerable } from 'immer';

export abstract class BaseDocument<Schema extends Timestamps> extends FirestoreAdapter {
    get id(): string {
        return this.snapshot.id;
    }
    get ref() {
        return this.snapshot.ref;
    }
    get path() {
        return this.ref.path;
    }

    data: Schema = this.snapshot.data()!;
    protected db = require('../DatabaseManager').DatabaseManager.getInstance(this.firestore);

    protected constructor(public snapshot: DocumentSnapshot<Schema>, documentType: string) {
        super(snapshot.ref.firestore, documentType);
        this.checkForExistence(this.snapshot);
        this[immerable] = true;
    }

    protected checkForExistence(snapshot: DocumentSnapshot): void {
        if (!snapshot.exists) {
            // todo throw custom error
            throw `"${this.snapshot.id}" does not exist.`;
        }
    }

    async save() {
        try {
            await this.ref.update({
                ...this.data,
                updatedAt: firestore.FieldValue.serverTimestamp(),
            });
            // we must manually set the updatedTime for our local copy until we fetch from the database again
            this.data.updatedAt = firestore.Timestamp.now();
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'BaseDocument.save()',
                error,
                path: this.path,
            });
            throw error;
        }
    }

    async update(data: Partial<Schema>): Promise<this> {
        console.log('making update in basedocument', data);
        try {
            this.data = { ...this.data, ...data };
            await this.save();
            return this;
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'BaseDocument.update()',
                error,
                path: this.path,
                documentType: this.documentType,
            });
            throw error;
        }
    }
}
