import { AlertDocument } from '../../../../database/documents/AlertDocument';
import { EntryDocument } from '../../../../database/documents/EntryDocument';
import dateFNSFormat from 'date-fns/format';
import dateFNSIsAfter from 'date-fns/isAfter';
import { Timestamp } from '../../../../database/FirestoreTypes';
import { PatientDocument } from '../../../../database/documents/PatientDocument';
import { toPascalCase, translateCause } from '../../../../utils';
import { UserMedicationDocument } from '../../../../database/documents/UserMedicationDocument';
import { OpioidType } from '../../../../database/schemas/Medication';
import dateFNSSubDays from 'date-fns/subDays';

export type ActivityLog = { [date: string]: ActivityLogItem[] };
type ActivityLogFormatterArgs = { previousActivityLog?: ActivityLog };
export type ActivityLogFeed = [string, { activity: string[]; timeStamp: string }[]][];
export type ActivityLogItem = { activity: string[]; timeStamp: Timestamp };

function formatDateFromTimeStamp(timeStamp: Timestamp): string {
    return dateFNSFormat(timeStamp.toDate(), 'MMMM do');
}

function formatTimeFromTimeStamp(timeStamp: Timestamp): string {
    return dateFNSFormat(timeStamp.toDate(), 'h:mm a');
}

/*
 * PATIENT ACTIVITY
 * */

function derivePrescriptionInfoFromMedication(medication: UserMedicationDocument): string {
    if (!medication.data.prescription) return '';
    return `${medication.data.prescription.count.prescribed -
        (medication.data.prescription.count.remaining ?? 0)} x ${medication.data.unit.amount}${
        medication.data.unit.measure
    } ${medication.data.name}`;
}

function sortActivitiesByTime(activityLog) {
    Object.keys(activityLog).forEach(
        key =>
            (activityLog[key] = activityLog[key].sort((a, b) =>
                dateFNSIsAfter(a.timeStamp.toDate(), b.timeStamp.toDate()) ? -1 : 1
            ))
    );
}

export async function formatPatientEntries({
    entries,
    patient,
    previousActivityLog = {},
}: {
    patient: PatientDocument;
    entries: EntryDocument[];
} & ActivityLogFormatterArgs): Promise<ActivityLog> {
    const medications = await patient.getAllMedications();
    return entries.reduce((activityLog: ActivityLog, entry) => {
        const entryDate = formatDateFromTimeStamp(entry.data.createdAt);
        const activityLogMedication = entry.data.medicationTaken?.map(({ medicationId }) =>
            medications.find(({ id }) => id === medicationId)
        );
        if (entry.data.painLevel !== undefined) {
            const activities = [
                `Pain rating: ${entry.data.painLevel ? `${entry.data.painLevel}/10` : 'Not recorded'}`,
            ];
            if (activityLogMedication?.[0]) {
                activities.push(derivePrescriptionInfoFromMedication(activityLogMedication[0]));
            }
            const activityLogItem = {
                activity: activities,
                timeStamp: entry.data.createdAt,
            };
            activityLog[entryDate]
                ? activityLog[entryDate].push(activityLogItem)
                : (activityLog[entryDate] = [activityLogItem]);
            sortActivitiesByTime(activityLog);
        }
        return activityLog;
    }, previousActivityLog);
}

export async function formatPatientResources({
    patient,
    previousActivityLog = {},
}: { patient: PatientDocument } & ActivityLogFormatterArgs): Promise<ActivityLog> {
    const resourceDocuments = await patient.getResources();
    return patient.data.resources.reduce((activityLog: ActivityLog, resource) => {
        const activityLogResource = resourceDocuments.find(document => document.id === resource.resourceId)
            ?.data;
        if (resource.assignedTime) {
            const resourceAssignedDate = formatDateFromTimeStamp(resource.assignedTime);
            const activityLogItem: ActivityLogItem = {
                activity: [
                    `${toPascalCase(activityLogResource?.type)} Assigned: "${activityLogResource?.title}"`,
                ],
                timeStamp: resource.assignedTime,
            };
            activityLog[resourceAssignedDate]
                ? activityLog[resourceAssignedDate].push(activityLogItem)
                : (activityLog[resourceAssignedDate] = [activityLogItem]);
        }
        if (resource.completedTime) {
            const resourceCompletedDate = formatDateFromTimeStamp(resource.completedTime);
            const activityLogItem: ActivityLogItem = {
                activity: [
                    `${toPascalCase(activityLogResource?.type)} Completed: "${activityLogResource?.title}"`,
                ],
                timeStamp: resource.completedTime,
            };
            activityLog[resourceCompletedDate]
                ? activityLog[resourceCompletedDate].push(activityLogItem)
                : (activityLog[resourceCompletedDate] = [activityLogItem]);
        }
        sortActivitiesByTime(activityLog);
        return activityLog;
    }, previousActivityLog);
}

export async function formatPatientAlerts({
    alerts,
    previousActivityLog = {},
}: {
    alerts: AlertDocument[];
} & ActivityLogFormatterArgs): Promise<ActivityLog> {
    return alerts.reduce((activityLog: ActivityLog, alert) => {
        //alerts passed will be resolved and will therefore have acknowledgedAt timestamp defined
        const alertDate = formatDateFromTimeStamp(alert.data.acknowledgedAt!);
        const activityLogItem: ActivityLogItem = {
            timeStamp: alert.data.acknowledgedAt!,
            activity: [`Tier ${alert.data.tier.toString()} Alert: ${translateCause(alert.data.cause)}`],
        };
        activityLog[alertDate]
            ? activityLog[alertDate].push(activityLogItem)
            : (activityLog[alertDate] = [activityLogItem]);
        sortActivitiesByTime(activityLog);
        return activityLog;
    }, previousActivityLog);
}

export async function derivePatientActivityLog({
    entries,
    patient,
    alerts,
}: {
    entries: EntryDocument[];
    patient: PatientDocument;
    alerts: AlertDocument[];
}): Promise<ActivityLogFeed> {
    let activityLog = await formatPatientEntries({ entries, patient });
    activityLog = await formatPatientResources({ patient, previousActivityLog: activityLog });
    activityLog = await formatPatientAlerts({ alerts, previousActivityLog: activityLog });
    console.log(activityLog);
    return Object.entries(activityLog)
        .sort(([aDate], [bDate]) => (dateFNSIsAfter(new Date(aDate), new Date(bDate)) ? 1 : -1))
        .map(([date, log]) => [
            date,
            log.map(item => ({ ...item, timeStamp: formatTimeFromTimeStamp(item.timeStamp) })),
        ]);
}

/*
 * PATIENT PILL HISTORY
 * */

export type DataPoint = { date: string; shortActing: number; averagePain: number | null };

export function deriveShortestInterval({
    medications,
    entries,
}: {
    medications: UserMedicationDocument[];
    entries: EntryDocument[];
}): number {
    const uniqueMedicationIds = entries.reduce((acc: Set<string>, val) => {
        val.data.medicationTaken?.forEach(({ medicationId }) => acc.add(medicationId));
        return acc;
    }, new Set<string>());
    const shortActingOpioids = [...uniqueMedicationIds]
        .map(id => medications.find(medication => medication.id === id))
        .filter(medication => medication?.data.prescription?.opioid === OpioidType.shortActing);
    return Math.min(...shortActingOpioids.map(opioid => opioid!.data.prescription!.frequency));
}

export function derivePatientPainPillHistory({
    medications,
    entries,
    maxInterval,
}: {
    medications: UserMedicationDocument[];
    entries: EntryDocument[];
    maxInterval: number;
}): DataPoint[] {
    const dataPoints = generateDaysForInterval(maxInterval - 1).map(date => ({
        date,
        averagePain: null,
        shortActing: 0,
    }));
    return entries.reduce((data: DataPoint[], { data: entry }) => {
        const entryDate = dateFNSFormat(entry.createdAt.toDate(), 'MMM do');
        const dataPoint = data.find(({ date }) => date === entryDate)!;
        if (entry.averageDayPainLevel && dataPoint) {
            dataPoint.averagePain = entry.averageDayPainLevel;
        }
        if (entry.medicationTaken) {
            //determine if there is a short acting opioid taken for that day
            const isShortActing = entry.medicationTaken.filter(({ medicationId }) =>
                medications.some(
                    ({ id, data }) =>
                        id === medicationId && data.prescription?.opioid === OpioidType.shortActing
                )
            );
            !!isShortActing.length && (dataPoint.shortActing += 1);
        }
        return data;
    }, dataPoints);
}

function generateDaysForInterval(interval: number): string[] {
    const days: string[] = [];
    for (let i = interval; i >= 0; i--) {
        days.push(dateFNSFormat(dateFNSSubDays(new Date(), i), 'MMM do'));
    }
    return days;
}
