import React, { useCallback, useContext, useEffect } from 'react';
import { translateUserRole, UserFormKeys, UserRoles } from '../../../database/schemas/User';
import { ProviderDocument } from '../../../database/documents/ProviderDocument';
import { FormSubmission, InputChange, ValidatableString } from '../../../types';
import { useImmer } from 'use-immer';
import { useDispatch } from 'react-redux';
import { setToastAlert, setToastError } from '../../../redux/currentSession/currentSessionActions';
import { formatPhoneNumber, isValidPhoneNumber } from '../../../utils';
import TextInput from '../../../components/TextInput';
import ActionButton from '../../../components/ActionButton';
import IconManager, { IconType } from '../../../components/IconManager';
import ToolTip from '../../../components/ToolTip';
import { Theme } from '../../../theme';
import FunctionsManager from '../../../functions/FunctionsManager';
import { CurrentUserContext } from '../../../context/CurrentUserContextProvider';
import SubmitButton from '../../../components/SubmitButton';
import {
    updateUserInSelectedOrganization,
    updateUserRoleInSelectedOrganization,
} from '../../../redux/selectedOrganization/selectedOrganizationActions';
import { OrganizationUser } from '../../../redux/selectedOrganization/selectedOrganizationReducer';

interface Props {
    userRole: UserRoles.orgAdmin | UserRoles.provider;
    selectedUser?: OrganizationUser;
}

interface State {
    form: {
        firstName: string;
        lastName: string;
        phoneNumber: ValidatableString;
        roles: UserRoles[];
    };
    updatingRole?: boolean;
    toolTipVisible: boolean;
}

const initialFormState = (userRole: Props['userRole']): State['form'] => ({
    firstName: '',
    lastName: '',
    phoneNumber: { value: '' },
    roles: [userRole],
});

export default function UserProfile(props: Props) {
    const [state, updateState] = useImmer<State>({
        form: initialFormState(props.userRole),
        toolTipVisible: false,
    });
    const currentUser = useContext(CurrentUserContext);
    const dispatch = useDispatch();
    const validatableFields = [UserFormKeys.phoneNumber];

    useEffect(() => {
        if (props.selectedUser) {
            const { data, access } = props.selectedUser;
            updateState(draft => {
                draft.form.firstName = data.firstName;
                draft.form.lastName = data.lastName;
                if (
                    access.isOrgAdminIn(currentUser.claims?.currentOrgId!) &&
                    !draft.form.roles.includes(UserRoles.orgAdmin)
                ) {
                    draft.form.roles.push(UserRoles.orgAdmin);
                }
                if (access.isProviderIn(currentUser.claims?.currentOrgId!)) {
                    draft.form.phoneNumber.value =
                        formatPhoneNumber((props.selectedUser as ProviderDocument)!.data.phoneNumber ?? '') ??
                        '';
                    if (!draft.form.roles.includes(UserRoles.provider)) {
                        draft.form.roles.push(UserRoles.provider);
                    }
                }
            });
        }
    }, [props.selectedUser]);

    const handleInput = (e: InputChange) => {
        e.persist();
        const isValidatableField = validatableFields.includes(e.target.name as UserFormKeys);
        updateState(draft => {
            if (isValidatableField) {
                draft.form[e.target.name].isValid = undefined;
                draft.form[e.target.name].value = e.target.value;
            } else {
                draft.form[e.target.name] = e.target.value;
            }
        });
    };

    const updateUserName = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        let { firstName, lastName } = state.form;
        firstName = firstName.trim();
        lastName = lastName.trim();
        if (
            props.selectedUser?.data.firstName !== firstName ||
            props.selectedUser.data.lastName !== lastName
        ) {
            try {
                const updatedUser = await props.selectedUser!.update({ firstName, lastName });
                dispatch(updateUserInSelectedOrganization(updatedUser));
                dispatch(setToastAlert("This user's name has been updated"));
            } catch (error) {
                console.log(error);
                dispatch(setToastError("An error occurred while updating this user's name"));
            }
        }
    };

    const updateUserPhoneNumber = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        const { phoneNumber } = state.form;
        if (!state.form.phoneNumber?.value || !isValidPhoneNumber(state.form.phoneNumber.value)) {
            return updateState(draft => void (draft.form.phoneNumber!.isValid = false));
        }
        try {
            const unformattedNumber = phoneNumber!.value
                .split('')
                .filter(char => /\d/g.test(char))
                .join('');
            const selectedProvider = props.selectedUser as ProviderDocument;
            if (
                !selectedProvider.data.phoneNumber ||
                unformattedNumber !== selectedProvider.data.phoneNumber
            ) {
                const updatedProvider = await selectedProvider.update({
                    phoneNumber: unformattedNumber.trim(),
                });
                updateState(draft => {
                    draft.form.phoneNumber!.value = formatPhoneNumber(draft.form.phoneNumber!.value)!;
                });
                dispatch(updateUserInSelectedOrganization(updatedProvider));
                dispatch(setToastAlert("This user's phone number has been successfully updated"));
            }
        } catch (error) {
            console.log(error);
            dispatch(setToastError("An error occurred while updating this user's phone number"));
        }
    };

    const toggleToolTipVisible = useCallback(() => {
        updateState(draft => void (draft.toolTipVisible = !draft.toolTipVisible));
    }, []);

    const updateUserRole = async (e: FormSubmission) => {
        e.preventDefault();
        try {
            const toastAlertMessage = props.selectedUser!.data.isOrgAdmin
                ? "This provider's org admin permissions have been revoked"
                : 'This provider has been granted org admin permissions';
            updateState(draft => void (draft.updatingRole = true));
            const userOrgAdminStatus = props.selectedUser!.access.isOrgAdminIn(
                currentUser.claims?.currentOrgId!
            );
            const userIsProvider = props.selectedUser!.access.isProviderIn(currentUser.claims?.currentOrgId!);
            await FunctionsManager.user.updateOrganizationRole({
                organizationAdmin: !userOrgAdminStatus,
                provider: userIsProvider,
                userId: props.selectedUser!.id,
            });
            const updatedUser = await props.selectedUser!.updateUserRoles({
                organizationId: currentUser.claims?.currentOrgId!,
                orgAdmin: !userOrgAdminStatus,
                provider: userIsProvider,
            });
            dispatch(updateUserRoleInSelectedOrganization(updatedUser));
            //update user's local copy of roles
            updateState(draft => {
                if (draft.form.roles.includes(UserRoles.orgAdmin)) {
                    draft.form.roles.splice(draft.form.roles.indexOf(UserRoles.orgAdmin));
                } else {
                    draft.form.roles.push(UserRoles.orgAdmin);
                }
            });
            dispatch(setToastAlert(toastAlertMessage));
        } catch (error) {
            console.log(error);
            dispatch(
                setToastError("An error occurred while attempting to update this provider's permissions")
            );
        }
        updateState(draft => void (draft.updatingRole = false));
    };

    return (
        <div className="w-full">
            <form
                className="w-full mt-2 mb-3 flex flex-row justify-start items-end"
                onSubmit={updateUserName}
            >
                <div className="flex flex-col w-full md:w-3/5">
                    <label className="w-full mb-2 block font-semibold" htmlFor={UserFormKeys.firstName}>
                        <span className="text-gray-700">First Name</span>
                        <TextInput
                            className="py-1"
                            name={UserFormKeys.firstName}
                            value={state.form.firstName}
                            onChange={handleInput}
                        />
                    </label>
                    <label className="w-full block font-semibold" htmlFor={UserFormKeys.lastName}>
                        <span className="text-gray-700">Last Name</span>
                        <TextInput
                            className="py-1"
                            name={UserFormKeys.lastName}
                            value={state.form.lastName}
                            onChange={handleInput}
                        />
                    </label>
                </div>
                <ActionButton
                    type="submit"
                    className="ml-2 mt-5 self-center"
                    disabled={
                        state.form.firstName === props.selectedUser?.data.firstName &&
                        state.form.lastName === props.selectedUser.data.lastName
                    }
                >
                    Update
                </ActionButton>
            </form>
            {props.userRole === UserRoles.provider && (
                <form
                    className="w-full my-2 flex flex-row justify-start items-start"
                    onSubmit={updateUserPhoneNumber}
                >
                    <label className="w-full md:w-3/5 block font-semibold" htmlFor={UserFormKeys.phoneNumber}>
                        <span className="text-gray-700">Phone Number</span>
                        <TextInput
                            className={`py-1 ${
                                state.form.phoneNumber.isValid === false ? 'border border-red-500' : ''
                            }`}
                            type="tel"
                            name={UserFormKeys.phoneNumber}
                            value={state.form.phoneNumber.value}
                            onChange={handleInput}
                        />
                        <p
                            className={`text-xs text-left text-${
                                state.form.phoneNumber!.isValid === false ? 'red-500' : 'gray-600'
                            } block ml-1 mt-1`}
                        >
                            {state.form.phoneNumber!.isValid === false
                                ? 'Please enter a valid phone number'
                                : 'This will be the number patients will be prompted to contact in the case of an emergency'}
                        </p>
                    </label>
                    <ActionButton
                        type="submit"
                        className="ml-2 mt-6"
                        disabled={
                            state.form.phoneNumber.value ===
                            (props.selectedUser as ProviderDocument)?.data.phoneNumber
                        }
                    >
                        Update
                    </ActionButton>
                </form>
            )}
            <div className="w-full my-2 flex flex-col justify-start items-start">
                <span className="text-gray-700 font-semibold block">Email</span>
                <p className="block text-gray-600 text-sm">{props.selectedUser?.data.email}</p>
            </div>
            <form
                className="w-full mt-2 mb-3 relative flex flex-row justify-between items-center"
                onSubmit={updateUserRole}
            >
                <div className="w-full">
                    <span className="block cursor-pointer font-semibold text-gray-700 flex flex-row justify-start items-center">
                        Roles{' '}
                        <IconManager
                            onMouseEnter={toggleToolTipVisible}
                            onMouseLeave={toggleToolTipVisible}
                            type={IconType.INFO_CIRCLE}
                            size={18}
                            className="ml-1"
                        />
                    </span>
                    <p className="block mt-1 text-gray-600 text-sm">
                        {state.form.roles.map(role => translateUserRole(role)).join(', ')}
                    </p>
                    <ToolTip width="23rem" visible={state.toolTipVisible}>
                        <div className="flex flex-col">
                            <h5 className="block mb-2 text-lg">Symmetry Roles</h5>
                            <p className="block mb-2">
                                <span className="font-semibold">Providers</span> can have patients assigned to
                                them and can be delegates for providers.
                            </p>
                            <p className="block">
                                <span className="font-semibold">Organization Admins</span> can add new
                                providers, update Opioid Agreements, and manage the settings of the
                                organization.
                            </p>
                        </div>
                    </ToolTip>
                </div>
                <div className="w-full">
                    {currentUser.claims?.isOrgAdminInCurrentOrganization() && (
                        //@ts-ignore
                        <SubmitButton color={Theme.lightBlue} loading={state.updatingRole}>
                            {state.form.roles.includes(UserRoles.orgAdmin) ? 'Revoke' : 'Grant'} Org Admin
                            Role
                        </SubmitButton>
                    )}
                </div>
            </form>
        </div>
    );
}
