import { UpdateStateArgs } from '../../../../types';
import { UserMedicationSchema } from '../../../../database/schemas/Patient';

//enum designed to specifically work with lodash's set method
//https://lodash.com/docs/4.17.15#set
export enum MedicationFormKeys {
    name = 'name',
    dose = 'dose',
    doseMin = 'prescription.dose.min',
    doseMax = 'prescription.dose.max',
    unitAmount = 'unit.amount',
    unitMeasure = 'unit.measure',
    archived = 'archived',
    prescriptionCountPrescribed = 'prescription.count.prescribed',
    prescriptionCountRemaining = 'prescription.count.remaining',
    prescriptionPrn = 'prescription.prn',
    prescriptionFrequency = 'prescription.frequency',
    prescriptionOpioid = 'prescription.opioid',
    delivery = 'delivery',
}

export type MedicationFormOnChange = (
    formState: UpdateStateArgs<{ [key in MedicationFormKeys]: any }>
) => void;

export type ClientMedicationSchema = Omit<UserMedicationSchema, 'createdAt' | 'updatedAt'>;
