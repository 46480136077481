import { Timestamps } from './Timestamps';

export interface UserSchema extends Timestamps {
    isAppAdmin: boolean;
    isOrgAdmin: boolean;
    isProvider: boolean;
    isPatient: boolean;
    organizations: UserOrganizations; // if isAppAdmin, this will an be empty object
    authId: string | null;
    firstName: string;
    lastName: string;
    email: string; // email is also the username
}

export enum UserFormKeys {
    firstName = 'firstName',
    lastName = 'lastName',
    email = 'email',
    password = 'password',
    phoneNumber = 'phoneNumber',
    roles = 'roles',
}

export interface UserOrganizations {
    // the index string is the organization id
    [index: string]: {
        roles: OrganizationRolesObject;
    };
}

export interface OrganizationRolesObject {
    0: boolean; // admin
    1: boolean; // provider
    2: boolean; // patient
}

// this is organizations property stored on a user's auth record and is used in the UserAccess class
export interface OrganizationsAndRoles {
    [index: string]: OrganizationRolesObject;
}

export enum UserRoles {
    orgAdmin,
    provider,
    patient,
    appAdmin,
}

export function translateUserRole(role: UserRoles) {
    switch (role) {
        case UserRoles.orgAdmin:
            return 'Organization Admin';
        case UserRoles.patient:
            return 'Patient';
        case UserRoles.provider:
            return 'Provider';
        case UserRoles.appAdmin:
            return 'Application Admin';
    }
}
