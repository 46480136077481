import React, { ReactNode } from 'react';
import LoadingSpinner from './LoadingSpinner';
import { useSelector } from 'react-redux';
import { ReduxState } from '../redux/store';
import { selectLoadingCurrentUser } from '../redux/currentSession/currentSessionSelectors';

export default function PageContainer({ children }: { children: ReactNode }) {
    const loadingCurrentUser = useSelector((state: ReduxState) => selectLoadingCurrentUser(state));
    if (loadingCurrentUser) {
        return <LoadingSpinner type="page" />;
    } else {
        return (
            <div className="mx-auto flex flex-col" style={{ maxWidth: '70rem' }}>
                <div className="w-full flex-grow py-4 px-5 mx-auto bg-white">{children}</div>
            </div>
        );
    }
}
