import React, { useContext, useEffect, useRef } from 'react';
import { FetchRequest, RouteProps } from '../../../../types';
import DelegateSettings from './DelegateSettings';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../redux/store';
import { selectSelectedOrganization } from '../../../../redux/selectedOrganization/selectedOrganizationSelectors';
import { ProviderDocument } from '../../../../database/documents/ProviderDocument';
import { useImmer } from 'use-immer';
import { selectToastAlert } from '../../../../redux/currentSession/currentSessionSelectors';
import { setToastError } from '../../../../redux/currentSession/currentSessionActions';
import ToastAlert from '../../../../components/ToastAlert';
import { UrlParams } from '../../../../routes';
import { CurrentUserContext } from '../../../../context/CurrentUserContextProvider';
import UserProfile from '../UserProfile';
import { UserRoles } from '../../../../database/schemas/User';

interface Props extends RouteProps {
    userId?: UrlParams.userId;
}

export interface State {
    selectedProvider: FetchRequest<{
        document: ProviderDocument | undefined;
        delegates: ProviderDocument[];
        delegators: ProviderDocument[];
    }>;
}

const initialState: State = {
    selectedProvider: { fetching: true, data: { document: undefined, delegators: [], delegates: [] } },
};

export default function ProviderDetails(props: Props) {
    const selectedOrganization = useSelector((state: ReduxState) => selectSelectedOrganization(state));
    const toastAlert = useSelector((state: ReduxState) => selectToastAlert(state));
    const dispatch = useDispatch();
    const [state, updateState] = useImmer<State>(initialState);
    const isMounted = useRef<boolean>(false);
    const currentUser = useContext(CurrentUserContext);

    useEffect(() => {
        (async () => {
            isMounted.current = true;
            if (props.userId) {
                if (currentUser.claims?.currentOrgId) {
                    try {
                        const selectedProvider = await selectedOrganization?.getProviderById(props.userId);
                        const { delegates, delegators } = await selectedProvider!.getDelegationData(
                            currentUser.claims.currentOrgId
                        );
                        updateState(draft => {
                            draft.selectedProvider.data.document = selectedProvider;
                            draft.selectedProvider.data.delegates = delegates;
                            draft.selectedProvider.data.delegators = delegators;
                        });
                    } catch (error) {
                        console.log(error);
                        dispatch(
                            setToastError("An error occurred while trying to get the provider's information")
                        );
                    }
                    updateState(draft => void (draft.selectedProvider.fetching = false));
                }
            }

            return () => {
                isMounted.current = false;
            };
        })();
    }, [props.userId]);

    const addDelegateToLocalState = (delegateOrDelegator: ProviderDocument) => {
        updateState(draft => void draft.selectedProvider.data.delegates.push(delegateOrDelegator));
    };

    const removeDelegateOrDelegatorFromLocalState = (
        delegateOrDelegatorId: string,
        isDelegator?: boolean
    ) => {
        updateState(draft => {
            const { delegates, delegators } = draft.selectedProvider.data;
            const targetGroup = isDelegator ? delegators : delegates;
            targetGroup.splice(
                targetGroup.findIndex(({ id }) => id === delegateOrDelegatorId),
                1
            );
        });
    };

    return (
        <>
            {toastAlert.visible && <ToastAlert message={toastAlert.message} />}
            <div className="px-5 pt-3 w-full flex flex-row justify-start">
                <h4 className="ml-4 text-lg font-semibold text-gray-900">Provider Details</h4>
            </div>
            <div className="w-full flex flex-row bg-gray-100 p-2">
                <div className="w-full md:p-3 flex flex-row">
                    <div className="rounded-md bg-white mx-4 border border-gray-300 mt-2 p-4 flex flex-col flex-1">
                        <UserProfile
                            userRole={UserRoles.provider}
                            selectedUser={state.selectedProvider.data.document}
                        />
                    </div>
                    <div className="rounded-md bg-white mx-4 border border-gray-300 mt-2 p-4 flex flex-col flex-1">
                        <h4 className="text-lg font-semibold text-gray-900">Delegate Settings</h4>
                        <DelegateSettings
                            addDelegateToLocalState={addDelegateToLocalState}
                            removeDelegateOrDelegatorFromLocalState={removeDelegateOrDelegatorFromLocalState}
                            selectedProvider={state.selectedProvider}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
