import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { FetchRequest, FormSubmission, InputChange, RouteProps, ValidatableString } from '../../types';
import { useImmer } from 'use-immer';
import { OrganizationDocument } from '../../database/documents/OrganizationDocument';
import DatabaseManager from '../../database/DatabaseManager';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../redux/store';
import { selectToastAlert } from '../../redux/currentSession/currentSessionSelectors';
import { setToastError } from '../../redux/currentSession/currentSessionActions';
import ToastAlert from '../../components/ToastAlert';
import isAuthed from '../../hooks/isAuthed';
import { UserRoles } from '../../database/schemas/User';
import { Link, navigate } from '@reach/router';
import useNavigation from '../../hooks/useNavigation';
import LoadingSpinner from '../../components/LoadingSpinner';
import ActionButton from '../../components/ActionButton';
import Modal from '../../components/Modal';
import TextInput from '../../components/TextInput';
import SubmitButton from '../../components/SubmitButton';
import ErrorMessage from '../../components/ErrorMessage';
import { Theme } from '../../theme';
import FunctionsManager from '../../functions/FunctionsManager';
import { CurrentUserContext } from '../../context/CurrentUserContextProvider';
import { isValidEmail } from '../../utils';
import LoadMoreButton from '../../components/LoadMoreButton';

interface State {
    organizations: FetchRequest<OrganizationDocument[]>;
    paginatedOrganizations: OrganizationDocument[];
    activePage: number;
    addingOrganization?: boolean;
    organizationToAdd: { organizationName: string; orgAdminEmail: ValidatableString; submitting: boolean };
}

enum FormKeys {
    organizationName = 'organizationName',
    orgAdminEmail = 'orgAdminEmail',
}

const initialState: State = {
    organizations: { fetching: true, data: [], error: null },
    organizationToAdd: { organizationName: '', orgAdminEmail: { value: '' }, submitting: false },
    paginatedOrganizations: [],
    activePage: 1,
};

export default function OrganizationList(_: RouteProps) {
    isAuthed([UserRoles.appAdmin]);
    const pageIncrement = 8;
    const [state, updateState] = useImmer<State>(initialState);
    const navigation = useNavigation();
    const toastAlert = useSelector((state: ReduxState) => selectToastAlert(state));
    const dispatch = useDispatch();
    const currentUser = useContext(CurrentUserContext);
    const isMounted = useRef<boolean>(false);
    const validatableFields = [FormKeys.orgAdminEmail];

    useEffect(() => {
        (async () => {
            isMounted.current = true;
            if (isMounted.current && currentUser.document) {
                try {
                    const organizations = await DatabaseManager.OrganizationModel.getAll();
                    updateState(draft => {
                        draft.organizations.data = organizations;
                        draft.paginatedOrganizations = organizations.slice(0, pageIncrement);
                    });
                } catch (error) {
                    console.log(error);
                    dispatch(
                        setToastError('An error occurred while trying to gather the list of organizations')
                    );
                }
                updateState(draft => void (draft.organizations.fetching = false));
            }
            return () => {
                return (isMounted.current = false);
            };
        })();
    }, [currentUser.document]);

    const loadMoreUsers = () => {
        updateState(draft => {
            draft.paginatedOrganizations.push(
                ...draft.organizations.data.slice(draft.activePage * pageIncrement)
            );
            draft.activePage += 1;
        });
    };

    const toggleModal = useCallback(() => {
        updateState(draft => {
            draft.addingOrganization = !draft.addingOrganization;
            draft.organizationToAdd.organizationName = '';
            draft.organizations.error = null;
        });
    }, []);

    const addOrganization = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        if (isValidEmail(state.organizationToAdd.orgAdminEmail.value)) {
            updateState(draft => void (draft.organizationToAdd.submitting = true));
            try {
                const addedOrganization = await DatabaseManager.OrganizationModel.create({
                    name: state.organizationToAdd.organizationName,
                    requireOpioidRiskTool: true,
                });
                await FunctionsManager.invite.organizationAdmin({
                    email: state.organizationToAdd.orgAdminEmail.value,
                    organizationId: addedOrganization.id,
                });
                updateState(draft => void draft.organizations.data.push(addedOrganization));
                await navigate(navigation.getOrganizationAdminsUrl(addedOrganization.id));
            } catch (error) {
                console.log(error);
                updateState(draft => void (draft.organizations.error = true));
            }
            updateState(draft => void (draft.organizationToAdd.submitting = false));
        } else {
            updateState(draft => void (draft.organizationToAdd.orgAdminEmail.isValid = false));
        }
    };

    const handleInput = (e: InputChange) => {
        e.persist();
        const isValidatableField = validatableFields.includes(e.target.name as FormKeys);
        updateState(draft => {
            if (isValidatableField) {
                draft.organizationToAdd[e.target.name] = { isValid: undefined, value: e.target.value };
            } else {
                draft.organizationToAdd[e.target.name] = e.target.value;
            }
        });
    };

    const {
        paginatedOrganizations,
        organizations: { data: organizations, fetching },
    } = state;

    return (
        <div className="w-full flex flex-row bg-white mx-auto max-w-4xl p-2">
            {toastAlert.visible && <ToastAlert message={toastAlert.message} />}
            <div className="mt-5 border border-gray-400 p-4 flex ml-5 flex-col w-full rounded-md bg-gray-100">
                <h2 className="text-blue-900 block text-xl font-semibold">Organization List</h2>
                <div className="w-full">
                    <h3 className="text-lg block text-blue-700 mt-2 pl-3">Name</h3>
                </div>
                {fetching ? (
                    <LoadingSpinner type="page" />
                ) : (
                    <>
                        <ul className="list-none w-full px-2">
                            {!paginatedOrganizations.length ? (
                                <div>There are no organizations to display</div>
                            ) : (
                                paginatedOrganizations.map(organization => (
                                    <Link
                                        key={organization.id}
                                        to={navigation.getOrganizationAdminsUrl(organization.id)}
                                    >
                                        <div
                                            className={`round-md bg-white hover:bg-${Theme.offWhite} border border-gray-400 px-3 py-4 my-3 rounded-md flex flex-row justify-start items-center`}
                                        >
                                            <h5 className="block">{organization.data.name}</h5>
                                        </div>
                                    </Link>
                                ))
                            )}
                        </ul>
                        <ActionButton onClick={toggleModal} className="mt-1 ml-2 w-1/2 lg:w-1/3">
                            Add Organization
                        </ActionButton>
                        {paginatedOrganizations.length < organizations.length && (
                            <div className="mx-auto">
                                <LoadMoreButton
                                    onClick={loadMoreUsers}
                                    remainingCount={
                                        organizations.length - paginatedOrganizations.length < 10
                                            ? organizations.length - paginatedOrganizations.length
                                            : 10
                                    }
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
            <Modal isOpen={!!state.addingOrganization} closeModal={toggleModal}>
                <form onSubmit={addOrganization} className="pb-3">
                    <div className="w-7/8 mx-auto px-4">
                        <h2 className="text-center block mb-3 text-gray-900">
                            Please enter the name of the organization you'd like to add as well as the email
                            for a user you'd like to set as an administrator for this organization
                        </h2>
                        <label htmlFor={FormKeys.organizationName} className="block mb-4">
                            <span className="block font-semibold text-gray-700">Organization Name</span>
                            <TextInput
                                className="py-1 mb-3"
                                value={state.organizationToAdd.organizationName}
                                onChange={handleInput}
                                name={FormKeys.organizationName}
                            />
                        </label>
                        <label htmlFor={FormKeys.orgAdminEmail}>
                            <span className="block font-semibold text-gray-700">
                                Organization Admin Email
                            </span>
                            <TextInput
                                className={`py-1 mb-3 ${
                                    state.organizationToAdd.orgAdminEmail.isValid === false
                                        ? 'border border-red-500'
                                        : ''
                                }`}
                                value={state.organizationToAdd.orgAdminEmail.value}
                                onChange={handleInput}
                                name={FormKeys.orgAdminEmail}
                            />
                            {state.organizationToAdd.orgAdminEmail.isValid === false && (
                                <ErrorMessage>Please enter a valid email address</ErrorMessage>
                            )}
                        </label>
                        <SubmitButton
                            loading={state.organizationToAdd.submitting}
                            disabled={!state.organizationToAdd.organizationName}
                            className="w-full mt-4"
                        >
                            Create Organization
                        </SubmitButton>
                        {state.organizations.error && (
                            <ErrorMessage className="mt-2">
                                An error occurred while attempting to add the organization. Please check your
                                internet connection & try again.
                            </ErrorMessage>
                        )}
                    </div>
                </form>
            </Modal>
        </div>
    );
}
