import { firestore } from 'firebase/app';
import { Timestamp } from '../FirestoreTypes';
import { Timestamps } from './Timestamps';
import { DeliveryType, OpioidType } from './Medication';
import { UserSchema } from './User';

export type PatientDashboardRow = Pick<PatientSchema, 'monitoringLevel' | 'lastName' | 'firstName'> & {
    lastAverageDayPainLevel: number | null;
    painSparkline: (number | null)[] | null;
    lastBowelMovementDate: string | null;
    lastEntryDate: string | null;
    shortActingOpioidUsage: OpioidRiskStatus;
    lowestRefillPercentage: number | null;
    lastTierThreeOrInformationalAlert: AlertSchema | { tier: null; acknowledgedAt: null };
};

export interface PatientSchema extends UserSchema, PatientDashboardRow {
    // we need a top level organizationId field on patients for indexing and firestore security queries
    isPatientInOrganization: string;
    dob: string; // date of birth - YYYY-MM-DD
    reminders: number[]; // List of times, in format of number of minutes after midnight. i.e. 1:00am = 60.
    gender: Gender;
    resources: PatientResource[];
    welcomeCompleted: boolean;
    monitoringLevel: MonitoringLevel;
    transitionMonitoringLevelOn: Timestamp | null;
    assignedPrescriber: string; // providerId
    emergencyPhoneNumber: string;
    painGoal: number;
    opioidAgreement?: UserOpioidAgreement;
    opioidRiskTool?: OpioidRiskTool;
    mrn?: string; // only Providers can modify
    lastLongActingOpioidTaken: {
        takenAt: null | Timestamp;
        twentyFourHourAlert: boolean;
        seventyTwoHourAlert: boolean;
    };
    lastMedicationTaken: {
        takenAt: null | Timestamp;
        threeDayAlert: boolean;
        fiveDayAlert: boolean;
    };
}

export enum PatientKeys {
    dob = 'dob',
    baselinePainScore = 'baselinePainScore',
    siteIds = 'siteIds',
    reminders = 'reminders',
    gender = 'gender',
    resources = 'resources',
    alerts = 'alerts',
    welcomeCompleted = 'welcomeCompleted',
    monitoringLevel = 'monitoringLevel',
    assignedPrescriber = 'assignedPrescriber',
    emergencyPhoneNumber = 'emergencyPhoneNumber',
    opioidAgreement = 'opioidAgreement',
    opioidAgreementSeen = 'opioidAgreement.seen',
    opioidAgreementSigned = 'opioidAgreement.signed',
    opioidAgreementAssignedOpioidAgreementId = 'opioidAgreement.assignedOpioidAgreementId',
    opioidRiskTool = 'opioidRiskTool',
    opioidRiskToolScore = 'opioidRiskTool.score',
    opioidRiskToolUpdatedAt = 'opioidRiskTool.updatedAt',
    mrn = 'mrn',
    firstName = 'firstName',
    lastName = 'lastName',
    email = 'email',
    authId = 'authId',
    painGoal = 'painGoal',
}

export interface EntrySchema extends Timestamps {
    painLevel: number;
    averageDayPainLevel?: number;
    medicationTaken?: (PillOrLiquidMedicationEntry | PatchMedicationEntry)[];
    bowelMovement?: boolean;
    date: string; // YYYY-MM-DD
}

export interface BaseMedicationEntry {
    medicationId: string;
    photo?: string;
}

export interface PillOrLiquidMedicationEntry extends BaseMedicationEntry {
    type: MedicationEntryType.pill | MedicationEntryType.liquid;
    count: number;
}

export interface PatchMedicationEntry extends BaseMedicationEntry {
    type: MedicationEntryType.patch;
    event: PatchMedicationEntryEvent;
}

export enum MedicationEntryType {
    pill = 'pill',
    liquid = 'liquid',
    patch = 'patch',
}

export enum PatchMedicationEntryEvent {
    changed,
    removed,
    wearing,
}

export interface UserMedicationSchema extends Timestamps {
    name: string;
    unit: {
        amount: number; // 3
        measure: string; // e.g. "mg" for pills, "mg/mL" for liquid, "mcg/hr" for patch
    };
    delivery: DeliveryType;
    prescription: Prescription | null;
    archived: boolean;
    archivedAt?: Timestamp;
}

export interface Prescription {
    // dose is the number of pills, milliliters (liquid), or hours (patches)
    dose: {
        min: number;
        max: number;
    };
    count: {
        prescribed: number;
        remaining: number | null;
    };
    prn: boolean;
    frequency: number; // number of hours
    opioid: OpioidType | null;
}

export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
}

export enum MonitoringLevel {
    none,
    daily,
    hourly,
}

export interface OpioidRiskTool {
    score: string;
    updatedAt: Timestamp;
}

export enum RiskStatus {
    LOW = 'Low',
    MEDIUM = 'Medium',
    HIGH = 'High',
}

export enum OpioidRiskStatus {
    NONE,
    LOW,
    MEDIUM,
    HIGH,
}

export interface AlertSchema extends AlertTierAndCause, Timestamps {
    acknowledgedAt: Timestamp | null;
    organizationId: string;
    date: string; // YYYY-MM-DD
}

export interface AlertTierAndCause {
    tier: AlertTier;
    cause: AlertCause;
}

export interface DefaultAlertSchema extends AlertSchema {
    type: AlertType.default;
}

export interface MedicationAlertSchema extends AlertSchema {
    type: AlertType.medication;
    details: AlertDetails;
}

export enum AlertType {
    default = 'default',
    medication = 'medication',
}

export type AlertDetails = {
    medicationId: string;
    entryCount: number;
    totalAmount: number;
};

export enum AlertTier {
    tierOne = 1,
    tierTwo = 2,
    tierThree = 3,
    informational = 4,
}

export enum AlertCause {
    // miscellaneous - frontend triggers
    scoredHighOnOpioidRiskTool = 1000,
    // miscellaneous - backend triggers
    didNotRegisterAfterThreeNotifications = 2000,
    didNotCompleteOpioidAgreementAfterThreeDays,
    transitionedFromHourlyToDailyMonitoring,
    // entry - backend triggers
    didNotReportMedsTakenForFiveDays = 3001,
    tookMoreThanTwelveShortActingOpioidsInOneDay,
    painScoreGteSevenforThreeDaysWithNoPainMeds = 3004,
    painScoreOfTenForThreeDays,
    noBowelMovementForFiveDays = 3009,
    tookTooManyPills,
}

export interface PatientResource {
    resourceId: string;
    assignedTime?: firestore.Timestamp;
    completedTime?: firestore.Timestamp;
}

export interface UserOpioidAgreement {
    // this is the next agreement the Patient needs to sign
    // if present, the mobile app should prompt the Patient to complete it
    assignedOpioidAgreementId: string | null;
    // this is the last signed opioid agreement, even if expired
    // if null, the Patient has never signed an opioid agreement
    currentOpioidAgreementId: string | null;
    // this is the path to the last signed opioid agreement, even if expired
    // if null, the Patient has never signed an opioid agreement
    pdf: string | null;
    // this is the date the currentOpioidAgreementId expires/expired
    expirationDate: string; // YYYY-MM-DD
}
