import React, { useEffect } from 'react';
import { FetchRequest, FormSubmission, InputChange, RouteProps, ValidatableString } from '../../types';
import SplashPageContainer from '../../components/SplashPageContainer';
import SplashPageForm from '../../components/SplashPageForm';
import useURLQueryParams from '../../hooks/useURLQueryParams';
import { useImmer } from 'use-immer';
import FunctionsManager from '../../functions/FunctionsManager';
import useNavigation from '../../hooks/useNavigation';
import ErrorMessage from '../../components/ErrorMessage';
import TextInput from '../../components/TextInput';
import { UserFormKeys } from '../../database/schemas/User';
import { Theme } from '../../theme';
import MaskablePasswordInput from '../../components/MaskablePasswordInput';
import SubmitButton from '../../components/SubmitButton';
import DatabaseManager from '../../database/DatabaseManager';
import AuthManager from '../../AuthManager';
import { navigate } from '@reach/router';
import { useDispatch } from 'react-redux';
import { setPendingAccountCreationInfo } from '../../redux/currentSession/currentSessionActions';
import { AccountCreationInfo } from '../../redux/currentSession/currentSessionReducer';
import { GetEmailByInviteIdResponse } from '../../functions/userTypes';
import LoadingSpinner from '../../components/LoadingSpinner';
//@ts-ignore
import Logo from '../../img/logo-white.png';

interface State {
    userInfo: FetchRequest<GetEmailByInviteIdResponse | undefined>;
    form: {
        firstName: string;
        lastName: string;
        password: ValidatableString;
        phoneNumber?: ValidatableString;
        submitting: boolean;
        valid: boolean;
        error: boolean;
    };
}

const initialFormState: State['form'] = {
    firstName: '',
    lastName: '',
    password: { value: '' },
    phoneNumber: { value: '' },
    submitting: false,
    valid: false,
    error: false,
};

export default function AccountSetup(_: RouteProps) {
    const [state, updateState] = useImmer<State>({
        userInfo: { fetching: true, data: undefined, error: null },
        form: initialFormState,
    });
    const { inviteId } = useURLQueryParams<{ inviteId: string }>();
    const navigation = useNavigation();
    const validatableFields = [UserFormKeys.password, UserFormKeys.phoneNumber];
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            if (inviteId && !state.userInfo.data) {
                try {
                    const { email, isProvider } = await FunctionsManager.user.getEmailByInviteId({
                        inviteId,
                    });
                    updateState(draft => {
                        draft.userInfo.data = { email, isProvider };
                    });
                } catch (error) {
                    console.log(error);
                    updateState(draft => void (draft.userInfo.error = true));
                }
                updateState(draft => void (draft.userInfo.fetching = false));
            }
        })();
    }, []);

    useEffect(() => {
        updateState(
            draft =>
                void (draft.form.valid =
                    !!state.form.firstName && !!state.form.lastName && !!state.form.password)
        );
    }, [state.form.firstName, state.form.lastName, state.form.password]);

    const handleInput = (e: InputChange) => {
        e.persist();
        const isValidatableField = validatableFields.includes(e.target.name as UserFormKeys);
        updateState(draft => {
            if (isValidatableField) {
                draft.form[e.target.name] = { isValid: undefined, value: e.target.value };
            } else {
                draft.form[e.target.name] = e.target.value;
            }
        });
    };

    const handleSubmit = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        const { userInfo, form } = state;
        try {
            updateState(draft => void (draft.form.submitting = true));
            if (form.password.value.length < 8) {
                return updateState(draft => void (draft.form.password.isValid = false));
            }

            //need to set as current user and navigate accordingly
            const userAccountInfo: AccountCreationInfo = {
                firstName: form.firstName,
                lastName: form.lastName,
            };

            if (userInfo.data?.isProvider) {
                userAccountInfo.phoneNumber = state.form.phoneNumber?.value;
            }

            dispatch(setPendingAccountCreationInfo(userAccountInfo));
            await AuthManager.createUserAccount({
                email: userInfo.data!.email,
                password: state.form.password.value,
            });
            const [user] = await DatabaseManager.UserModel.query({
                queries: [['email', '==', userInfo.data!.email]],
            });
            setTimeout(async () => {
                if (user.access.isAppAdmin()) {
                    await navigate(navigation.orgAdminsUrl);
                } else {
                    const organizationId = Object.keys(user.data.organizations)[0];
                    user.access.isProviderIn(organizationId)
                        ? await navigate(navigation.getPatientsUrl(organizationId))
                        : await navigate(navigation.getOrganizationAdminsUrl(organizationId));
                }
                updateState(draft => void (draft.form.submitting = false));
            }, 1500);
        } catch (error) {
            console.error(error);
            //todo: need to distinguish between errors relating to fetching user email & form submission errors
            updateState(draft => {
                draft.form.error = true;
                draft.form.submitting = false;
            });

            await AuthManager.logout();
        }
    };

    return (
        <SplashPageContainer>
            <div className="mx-auto text-center">
                <img src={Logo} alt="Symmetry logo" height={200} className="mx-auto mb-4" />
            </div>
            <SplashPageForm onSubmit={handleSubmit}>
                <h2 className={`text-${Theme.darkBlue} text-xl mb-2 text-center font-semibold`}>
                    Account Setup
                </h2>
                {(state.userInfo.error || state.form.error) && (
                    <ErrorMessage>
                        {state.userInfo.error
                            ? 'An error occurred while attempting to gather your email. Please ensure the invite is still valid.'
                            : 'An error occurred while attempting to create your account. Please check your internet connection & try again.'}
                    </ErrorMessage>
                )}
                {state.userInfo.fetching ? (
                    <LoadingSpinner type="page" />
                ) : (
                    <>
                        <div className="block font-semibold">
                            <span className="text-gray-700 block">Email</span>
                            <span className="text-gray-600 block font-normal text-sm">
                                {state.userInfo.data?.email}
                            </span>
                        </div>
                        <div className="mb-4">
                            <div className="w-full my-2">
                                <label className="block font-semibold mb-2" htmlFor={UserFormKeys.firstName}>
                                    <span className="text-gray-700">First Name</span>
                                    <TextInput
                                        name={UserFormKeys.firstName}
                                        value={state.form.firstName}
                                        onChange={handleInput}
                                        className="py-1"
                                    />
                                </label>
                                <label className="block font-semibold mb-2" htmlFor={UserFormKeys.lastName}>
                                    <span className="text-gray-700">Last Name</span>
                                    <TextInput
                                        name={UserFormKeys.lastName}
                                        value={state.form.lastName}
                                        onChange={handleInput}
                                        className="py-1"
                                    />
                                </label>
                                {state.userInfo.data?.isProvider && (
                                    <label
                                        className="block font-semibold mb-2"
                                        htmlFor={UserFormKeys.phoneNumber}
                                    >
                                        <span className="text-gray-700">Phone Number</span>
                                        <span className="text-gray-500 text-xs ml-1 font-normal">
                                            (optional)
                                        </span>
                                        <TextInput
                                            name={UserFormKeys.phoneNumber}
                                            value={state.form.phoneNumber?.value ?? ''}
                                            onChange={handleInput}
                                            className="py-1"
                                        />
                                    </label>
                                )}
                                <label className="block font-semibold mb-2" htmlFor={UserFormKeys.password}>
                                    <span className="text-gray-700">Password</span>
                                    <MaskablePasswordInput
                                        tooShort={state.form.password.isValid === false}
                                        onChange={handleInput}
                                        value={state.form.password.value}
                                        name={UserFormKeys.password}
                                        type="selfControlled"
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="w-full flex flex-row justify-center items-center">
                            <SubmitButton loading={state.form.submitting}>Complete Signup</SubmitButton>
                        </div>
                    </>
                )}
            </SplashPageForm>
        </SplashPageContainer>
    );
}
