import React, { useCallback, useEffect } from 'react';
import { PatientDocument } from '../../../../../database/documents/PatientDocument';
import { Gender, PatientKeys, RiskStatus } from '../../../../../database/schemas/Patient';
import { useImmer } from 'use-immer';
import { useDispatch } from 'react-redux';
import dateFNSDifferenceInYears from 'date-fns/differenceInYears';
import dateFNSParseIso from 'date-fns/parseISO';
import { FormSubmission } from '../../../../../types';
import { updatePatientDocumentProp } from '../../../../../redux/selectedPatient/selectedPatientActions';
import { getTimestamp } from '../../../../../utils';
import SubmitButton from '../../../../../components/SubmitButton';
import { Theme } from '../../../../../theme';

interface RiskAssessmentFormProps {
    patient: PatientDocument;
    organizationName?: string;
    closeModal: () => void;
}
type RiskScore = { [key in Gender]: number };
type RiskQuestion = { question: string; response?: boolean; score: RiskScore; focused?: boolean };

const riskAssessmentQuestions: RiskQuestion[] = [
    {
        question: 'Do you have any family history of alcohol abuse?',
        score: { [Gender.MALE]: 3, [Gender.FEMALE]: 1 },
    },
    {
        question: 'Do you have any family history of illegal drug abuse?',
        score: { [Gender.MALE]: 3, [Gender.FEMALE]: 2 },
    },
    {
        question: 'Do you have any family history of medication drug abuse?',
        score: { [Gender.MALE]: 4, [Gender.FEMALE]: 4 },
    },
    {
        question: 'Do you have any personal history of alcohol abuse?',
        score: { [Gender.MALE]: 3, [Gender.FEMALE]: 3 },
    },
    {
        question: 'Do you have any personal history of illegal drug abuse?',
        score: { [Gender.MALE]: 4, [Gender.FEMALE]: 4 },
    },
    {
        question: 'Do you have any personal history of medication drug abuse?',
        score: { [Gender.MALE]: 5, [Gender.FEMALE]: 5 },
    },
    {
        question: 'Do you have any history of preadolescent sexual abuse?',
        score: { [Gender.MALE]: 0, [Gender.FEMALE]: 3 },
    },
    {
        question: 'Do you have a diagnosis of ADD, OCD, bipolar, or schizophrenia?',
        score: { [Gender.MALE]: 2, [Gender.FEMALE]: 2 },
    },
    {
        question: 'Do you have a history of depression?',
        score: { [Gender.MALE]: 1, [Gender.FEMALE]: 1 },
    },
];

export default function RiskAssessmentForm(props: RiskAssessmentFormProps): JSX.Element {
    const [state, updateState] = useImmer<{ riskQuestions: RiskQuestion[]; formValid: boolean }>({
        riskQuestions: riskAssessmentQuestions,
        formValid: false,
    });
    const dispatch = useDispatch();
    const handleResponse = useCallback((questionIndex: number, response: boolean) => {
        updateState(draft => {
            const question = draft.riskQuestions[questionIndex];
            question.response = response;
            question.focused = false;
        });
    }, []);

    const calculateRiskScore = (): RiskStatus => {
        const { dob, gender } = props.patient.data;
        const patientAge = dateFNSDifferenceInYears(new Date(), dateFNSParseIso(dob));
        const riskScore = state.riskQuestions.reduce(
            (score, question) => {
                score += question.response ? question.score[gender] : 0;
                return score;
            },
            patientAge >= 16 && patientAge <= 45 ? 1 : 0
        );
        if (riskScore <= 3) {
            return RiskStatus.LOW;
        } else if (riskScore <= 7) {
            return RiskStatus.MEDIUM;
        } else {
            return RiskStatus.HIGH;
        }
    };

    useEffect(() => {
        updateState(draft => {
            draft.formValid = !draft.riskQuestions.some(question => question.response === undefined);
        });
    }, [state.riskQuestions]);

    const focusLastUnansweredQuestion = () => {
        for (let i = state.riskQuestions.length - 1; i >= 0; i--) {
            updateState(draft => {
                draft.riskQuestions[i].focused = state.riskQuestions[i].response === undefined;
            });
        }
    };

    const handleSubmit = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        if (state.formValid) {
            await Promise.all([
                dispatch(
                    updatePatientDocumentProp({
                        path: PatientKeys.opioidRiskToolScore,
                        value: calculateRiskScore(),
                    })
                ),
                dispatch(
                    updatePatientDocumentProp({
                        path: PatientKeys.opioidRiskToolUpdatedAt,
                        value: getTimestamp(),
                    })
                ),
            ]);
            props.closeModal();
        } else {
            focusLastUnansweredQuestion();
        }
    };

    return (
        <form className="w-full" onSubmit={handleSubmit}>
            <h2 className="text-3xl text-gray-900 font-semibold">Risk Assessment</h2>
            <p className="text-gray-700 block mt-2">
                If you are uncomfortable with completing this, please speak to the nurse and she will assist
                you.
            </p>
            <ul className="mt-2">
                {state.riskQuestions.map(({ question, response, focused }, i) => (
                    <div
                        key={question}
                        className={focused ? 'border border-red-500 rounded-md px-1' : 'px-1'}
                    >
                        <div className="flex flex-row items-center justify-between py-2">
                            <span className="block w-3/4 font-thin">{question}</span>
                            <span className="block flex flex-row items-center">
                                <button
                                    onClick={() => handleResponse(i, true)}
                                    type="button"
                                    className={`mr-3 text-${
                                        response === true ? 'blue-500 font-semibold' : 'gray-600 font-normal'
                                    }`}
                                >
                                    Yes
                                </button>
                                <button
                                    onClick={() => handleResponse(i, false)}
                                    type="button"
                                    className={`text-${
                                        response === false ? 'blue-500 font-semibold' : 'gray-600 font-normal'
                                    }`}
                                >
                                    No
                                </button>
                            </span>
                        </div>
                        <hr />
                    </div>
                ))}
            </ul>
            <div className="w-full flex flex-col justify-center mt-2">
                <SubmitButton
                    className={`bg-${Theme.lightBlue} hover:bg-${Theme.lightBlueHover} rounded-md text-white mx-auto text-lg font-semibold px-2 py-1 border-none focus:outline-none`}
                >
                    Submit ORT Assessment
                </SubmitButton>
                <button type="button" className="text-gray-600 mt-1">
                    Cancel
                </button>
            </div>
        </form>
    );
}
