import { RouteComponentProps } from '@reach/router';
import { ChangeEvent, Dispatch as ReactDispatch, FormEvent, MouseEvent } from 'react';
import { ActionMeta, ValueType } from 'react-select';

/**
 * REACT COMPONENTS
 */
//extend Route component props by default
export type RouteProps = RouteComponentProps;
export type SelectOption = { label: string; value: string };
export type UpdateStateArgs<T> = { prop: keyof T; value: ValueOf<T> };
export type Dispatch<A> = ReactDispatch<A>;
/**
 * FORM/CLICK EVENTS
 */

export type FormSubmission = FormEvent<HTMLFormElement>;
export type InputChange<T = HTMLInputElement> = ChangeEvent<T>;
export type ButtonClick = MouseEvent<HTMLButtonElement>;
export type ValueOf<T> = T[keyof T];
export type ValidatableString = { value: string; isValid?: boolean };

/**
 * NETWORK
 */
export type FetchRequest<T, E = unknown> = { fetching?: boolean; data: T; error?: E | null };
//code variants will probably expand?
export type FirestoreError = { code: 'auth/user-not-found'; message: string };

/**
 * REACT-SELECT
 */
export type ReactSelectChangeEvent = (
    value: ValueType<ReactSelectOption>,
    actionMeta: ActionMeta<any>
) => void;
export type ReactSelectOption = { label: string; value: string };
export type ReactSelectValue = ValueType<ReactSelectOption>;
export type ReactSelectState = {
    options: SelectOption[];
    selectedOption: SelectOption | undefined;
    handleSelect: (option: ReactSelectValue) => void;
};

export enum BrowserStorageKeys {
    VIEW_CONTEXT = 'VIEW_CONTEXT',
    PROVIDER_HAS_DISMISSED_CONTACT_NUMBER_ENCOURAGEMENT = 'PROVIDER_HAS_DISMISSED_CONTACT_NUMBER_ENCOURAGEMENT',
    PROVIDER_HAS_SELECTED_THEIR_PATIENTS_IN_PATIENT_LIST = 'PROVIDER_HAS_SELECTED_THEIR_PATIENTS_IN_PATIENT_LIST',
}
