import React, { useCallback, useContext } from 'react';
import IconManager, { IconType } from '../../../../components/IconManager';
import { ProviderDocument } from '../../../../database/documents/ProviderDocument';
import { State as ParentState } from './ProviderDetails';
import ActionButton from '../../../../components/ActionButton';
import { useImmer } from 'use-immer';
import Modal from '../../../../components/Modal';
import { FormSubmission, ReactSelectState, SelectOption } from '../../../../types';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import Select from '../../../../components/Select';
import SubmitButton from '../../../../components/SubmitButton';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../redux/store';
import { selectSelectedOrganizationProviders } from '../../../../redux/selectedOrganization/selectedOrganizationSelectors';
import { CurrentUserContext } from '../../../../context/CurrentUserContextProvider';
import ErrorManager, { ErrorType } from '../../../../ErrorManager';
import { setToastError } from '../../../../redux/currentSession/currentSessionActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';

const deriveDelegateName = (delegate?: ProviderDocument) =>
    `${delegate?.data.firstName} ${delegate?.data.lastName}`;

interface Props {
    selectedProvider: Pick<ParentState, 'selectedProvider'>['selectedProvider'];
    addDelegateToLocalState: (delegateOrDelegator: ProviderDocument, isDelegator?: boolean) => void;
    removeDelegateOrDelegatorFromLocalState: (delegateOrDelegatorId: string, isDelegator?: boolean) => void;
}

enum ModalScenarios {
    REMOVING_DELEGATE,
    REMOVING_DELEGATOR,
    ADDING_DELEGATE,
}

interface State {
    modalScenario?: ModalScenarios;
    delegateToRemove?: ProviderDocument;
    delegateToAdd?: ProviderDocument;
    submittingForm?: boolean;
}

export default function DelegateSettings(props: Props) {
    const [state, updateState] = useImmer<State>({});
    const selectedOrganizationProviders = useSelector((state: ReduxState) =>
        selectSelectedOrganizationProviders(state)
    );
    const currentUser = useContext(CurrentUserContext);
    const dispatch = useDispatch();

    const toggleModal = useCallback((scenario?: State['modalScenario']) => {
        updateState(draft => {
            draft.modalScenario = scenario;
        });
    }, []);

    const setDelegateToAdd = useCallback(delegateId => {
        updateState(draft => {
            draft.delegateToAdd = selectedOrganizationProviders.find(({ id }) => id === delegateId);
        });
    }, []);

    const addDelegate = async (): Promise<void> => {
        if (state.delegateToAdd) {
            const addedDelegate = await props.selectedProvider.data.document!.addDelegate(
                currentUser.claims!.currentOrgId!,
                state.delegateToAdd.id
            );
            if (!addedDelegate) {
                throw new ErrorManager('Error adding delegate or delegator', ErrorType.misc);
            }
            props.addDelegateToLocalState(addedDelegate);
        }
    };

    const removeDelegateOrDelegator = async (): Promise<void> => {
        if (state.delegateToRemove) {
            await props.selectedProvider?.data.document!.removeDelegate(
                currentUser.claims!.currentOrgId!,
                state.delegateToRemove.id
            );
            props.removeDelegateOrDelegatorFromLocalState(
                state.delegateToRemove.id,
                state.modalScenario === ModalScenarios.REMOVING_DELEGATOR
            );
        }
    };

    const handleSubmit = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        updateState(draft => void (draft.submittingForm = true));
        try {
            if (state.modalScenario !== undefined) {
                state.modalScenario === ModalScenarios.ADDING_DELEGATE
                    ? await addDelegate()
                    : await removeDelegateOrDelegator();
            }
        } catch (error) {
            console.log(error);
            dispatch(
                setToastError("An error occurred while attempting to update this providers's delegates")
            );
        }
        toggleModal();
        updateState(draft => void (draft.submittingForm = false));
    };

    const presentRemoveModal = useCallback((delegateToRemove: ProviderDocument) => {
        updateState(draft => void (draft.delegateToRemove = delegateToRemove));
        toggleModal(ModalScenarios.REMOVING_DELEGATE);
    }, []);

    const { data, fetching } = props.selectedProvider;

    return (
        <>
            {fetching ? (
                <LoadingSpinner type="page" />
            ) : (
                <>
                    <div className="flex flex-col justify-start items-start py-3 my-2">
                        <h4 className="font-semibold text-gray-700">Provider Delegates</h4>
                        {!data.delegates.length ? (
                            <div className="text-gray-600 text-left my-2 w-full">
                                This provider does not have any delegates
                            </div>
                        ) : (
                            <ul className="py-2 text-left lg:w-3/4 w-full">
                                {data.delegates.map(provider => (
                                    <li key={provider.id}>
                                        <Delegate
                                            delegateName={deriveDelegateName(provider)}
                                            presentRemoveModal={() => presentRemoveModal(provider)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        )}
                        <ActionButton
                            className="mt-1 w-1/2 lg:w-1/3"
                            onClick={() => toggleModal(ModalScenarios.ADDING_DELEGATE)}
                        >
                            Add Delegate
                        </ActionButton>
                    </div>
                    <div className="flex flex-col justify-start items-start py-3 my-2">
                        <h4 className="font-semibold text-gray-700">Delegating to Provider</h4>
                        {!data.delegators.length ? (
                            <div className="text-gray-600 text-left my-2 w-full">
                                This provider does not have any delegators
                            </div>
                        ) : (
                            <ul className="py-2 text-left lg:w-3/4 w-full">
                                {data.delegators.map(provider => (
                                    <li key={provider.id}>
                                        <Delegate
                                            delegateName={deriveDelegateName(provider)}
                                            presentRemoveModal={() => presentRemoveModal(provider)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </>
            )}
            <DelegateModal
                modalScenario={state.modalScenario}
                toggleModal={() => toggleModal()}
                setDelegateToAdd={setDelegateToAdd}
                delegateToAdd={state.delegateToAdd}
                delegateToRemove={state.delegateToRemove}
                delegateSelectOptions={selectedOrganizationProviders.filter(
                    ({ id }) =>
                        !data.delegates.find(provider => provider.id === id) && data.document?.id !== id
                )}
                onSubmit={handleSubmit}
                submittingForm={state.submittingForm}
                selectedProvider={data.document}
            />
        </>
    );
}

interface DelegateProps {
    delegateName?: string;
    presentRemoveModal: () => void;
}

function Delegate(props: DelegateProps): JSX.Element {
    return (
        <div
            className="bg-gray-100 border border-gray-400 w-full text-gray-800 mb-2 px-4 py-3 rounded-md relative"
            role="alert"
        >
            <strong className="font-bold">{props.delegateName}</strong>
            <button onClick={props.presentRemoveModal} className="absolute top-0 bottom-0 right-0 px-4 py-3">
                <IconManager
                    type={IconType.CLOSE}
                    size={20}
                    strokeWidth={3}
                    fill="#3B98CF"
                    className="cursor-pointer"
                />
            </button>
        </div>
    );
}

interface DelegateModalProps {
    modalScenario: State['modalScenario'];
    toggleModal: () => void;
    delegateToAdd?: State['delegateToAdd'];
    setDelegateToAdd: (delegateId: string) => void;
    delegateToRemove?: State['delegateToRemove'];
    delegateSelectOptions: ProviderDocument[];
    onSubmit: (e: FormSubmission) => void;
    submittingForm: State['submittingForm'];
    selectedProvider?: ProviderDocument;
}

function DelegateModal(props: DelegateModalProps): JSX.Element {
    const isRemovingDelegateOrDelegator =
        props.modalScenario === ModalScenarios.REMOVING_DELEGATOR ||
        props.modalScenario === ModalScenarios.REMOVING_DELEGATE;
    const ActiveModal = isRemovingDelegateOrDelegator ? ConfirmationModal : Modal;

    const delegateSelect: ReactSelectState = {
        options: props.delegateSelectOptions.map(provider => ({
            label: deriveDelegateName(provider),
            value: provider.id,
        })),
        get selectedOption() {
            return this.options.find(({ value }) => value === props.delegateToAdd?.id);
        },
        handleSelect(option) {
            props.setDelegateToAdd((option as SelectOption).value);
        },
    };

    return (
        <ActiveModal
            onConfirm={props.onSubmit}
            isOpen={props.modalScenario !== undefined}
            closeModal={props.toggleModal}
        >
            {isRemovingDelegateOrDelegator ? (
                <p>
                    <span>
                        Are you sure you want to remove{' '}
                        <span className="font-semibold inline">
                            {deriveDelegateName(props.delegateToRemove)}
                        </span>{' '}
                        as one of {props.selectedProvider?.data.firstName}'s{' '}
                        {props.modalScenario === ModalScenarios.REMOVING_DELEGATE
                            ? 'delegates'
                            : 'delegators'}
                        ?
                    </span>
                </p>
            ) : (
                <form className="w-full md:w-11/12 mx-auto my-2 p-3" onSubmit={props.onSubmit}>
                    <h4 className="text-lg text-gray-800 text-center mb-2 block">
                        Select a fellow provider to add to {props.selectedProvider?.data.firstName}'s list of
                        delegates
                    </h4>
                    <Select
                        options={delegateSelect.options}
                        selectedOption={delegateSelect.selectedOption}
                        placeholder={delegateSelect.selectedOption ? undefined : 'Select prescriber'}
                        onChange={delegateSelect.handleSelect}
                    />
                    <p className="text-xs text-gray-600 px-3 text-center block my-2">
                        The provider you select will receive an email notifying them that they've been added
                        as a delegate for your patients
                    </p>
                    <SubmitButton loading={props.submittingForm} className="mt-3 w-full">
                        Add Provider
                    </SubmitButton>
                </form>
            )}
        </ActiveModal>
    );
}
