import { Dispatch } from '../../types';
import {
    GroupedOpioidAgreements,
    OrganizationUser,
    SelectedOrganizationActions,
    SelectedOrganizationActionTypes,
} from './selectedOrganizationReducer';
import { Thunk } from '../reduxTypes';
import DatabaseManager from '../../database/DatabaseManager';
import { OpioidAgreementDocument } from '../../database/documents/OpioidAgreementDocument';
import { UserRoles } from '../../database/schemas/User';
import { UserDocument } from '../../database/documents/UserDocument';
import { PatientDocument } from '../../database/documents/PatientDocument';
import { ProviderDocument } from '../../database/documents/ProviderDocument';
import { Claims } from '../../Claims';
import { clearSelectedPatient } from '../selectedPatient/selectedPatientActions';
import { setLoadingCurrentUser } from '../currentSession/currentSessionActions';
import FunctionsManager from '../../functions/FunctionsManager';
import AuthManager from '../../AuthManager';

type DispatchSelectedOrganizationActions = Dispatch<SelectedOrganizationActionTypes>;
type SelectedOrganizationThunk = Thunk<SelectedOrganizationActions>;

export const getSelectedOrganizationDataForUser = ({
    currentUserClaims,
    organizationId = currentUserClaims.currentOrgId!,
}: {
    currentUserClaims: Claims;
    organizationId?: string;
}): SelectedOrganizationThunk => async (dispatch: DispatchSelectedOrganizationActions): Promise<void> => {
    const currentOrganization = await DatabaseManager.OrganizationModel.get(organizationId);
    const providers = await currentOrganization.getProviders();
    const groupedAndSortedOpioidAgreements = groupAndSortOpioidAgreements(
        await currentOrganization.getAllOpioidAgreements()
    );
    const orgAdmins: (ProviderDocument | UserDocument)[] = [];
    const patients: PatientDocument[] = [];
    if (currentUserClaims.currentRoles?.[UserRoles.orgAdmin]) {
        orgAdmins.push(...(await currentOrganization.getOrgAdmins()));
    }
    if (currentUserClaims.currentRoles?.[UserRoles.provider]) {
        patients.push(...(await currentOrganization.getPatients()));
    }
    dispatch({
        type: SelectedOrganizationActions.SET_SELECTED_ORGANIZATION_DATA_FOR_USER,
        payload: {
            document: currentOrganization,
            patients,
            providers,
            opioidAgreements: groupedAndSortedOpioidAgreements,
            orgAdmins,
        },
    });
};

export const changeCurrentUserSelectedOrganization = ({
    currentUserClaims,
    newOrganizationId,
}: {
    currentUserClaims: Claims;
    newOrganizationId: string;
}): SelectedOrganizationThunk => async (dispatch): Promise<void> => {
    await Promise.all([
        dispatch(clearSelectedOrganization()),
        dispatch(clearSelectedPatient()),
        dispatch(setLoadingCurrentUser(true)),
    ]);
    await FunctionsManager.user.setCurrentOrganization({ organizationId: newOrganizationId });
    dispatch(
        getSelectedOrganizationDataForUser({
            currentUserClaims,
            organizationId: newOrganizationId,
        })
    );
    await AuthManager.refreshUserSession();
    setTimeout(async () => {
        dispatch(setLoadingCurrentUser(false));
    }, 3000);
};

export const clearSelectedOrganization = (): SelectedOrganizationThunk => async (
    dispatch: DispatchSelectedOrganizationActions
): Promise<void> => {
    dispatch({ type: SelectedOrganizationActions.CLEAR_SELECTED_ORGANIZATION });
};

export const addOpioidAgreement = (
    opioidAgreement: OpioidAgreementDocument
): SelectedOrganizationThunk => async (dispatch): Promise<void> => {
    dispatch({ type: SelectedOrganizationActions.ADD_OPIOID_AGREEMENT, payload: opioidAgreement });
};

export const updateOpioidAgreementTitle = ({
    opioidAgreementId,
    title,
}: {
    opioidAgreementId: string;
    title: string;
}): SelectedOrganizationThunk => async (dispatch): Promise<void> => {
    dispatch({
        type: SelectedOrganizationActions.UPDATE_OPIOID_AGREEMENT_TITLE,
        payload: { title, opioidAgreementId },
    });
};

export const toggleOpioidAgreementArchivedStatus = (
    opioidAgreement: OpioidAgreementDocument
): SelectedOrganizationThunk => async (dispatch): Promise<void> => {
    dispatch({
        type: SelectedOrganizationActions.TOGGLE_OPIOID_AGREEMENT_ARCHIVED_STATUS,
        payload: opioidAgreement,
    });
    await opioidAgreement.toggleArchivedStatus();
};

export const updateUserInSelectedOrganization = (
    updatedUser: OrganizationUser
): SelectedOrganizationThunk => async (dispatch: DispatchSelectedOrganizationActions): Promise<void> => {
    dispatch({
        type: SelectedOrganizationActions.UPDATE_USER_IN_SELECTED_ORGANIZATION,
        payload: updatedUser,
    });
};

export const updateUserRoleInSelectedOrganization = (
    updatedUser: OrganizationUser
): SelectedOrganizationThunk => async (dispatch: DispatchSelectedOrganizationActions): Promise<void> => {
    dispatch({
        type: SelectedOrganizationActions.UPDATE_USER_ROLE_IN_SELECTED_ORGANIZATION,
        payload: updatedUser,
    });
};

//UTIL
function groupAndSortOpioidAgreements(opioidAgreements: OpioidAgreementDocument[]): GroupedOpioidAgreements {
    const groupedOpioidAgreements = opioidAgreements.reduce(
        (groupedAgreements: GroupedOpioidAgreements, agreement) => {
            agreement.data.archivedAt
                ? groupedAgreements.archived.push(agreement)
                : groupedAgreements.active.push(agreement);
            return groupedAgreements;
        },
        { active: [], archived: [] }
    );
    //sort by createdAt
    groupedOpioidAgreements.active.sort((a, b) => a.data.createdAt.seconds - b.data.createdAt.seconds);
    //sort by archivedAt
    groupedOpioidAgreements.archived.sort((a, b) => a.data.archivedAt!.seconds - b.data.archivedAt!.seconds);
    return groupedOpioidAgreements;
}
