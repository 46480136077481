import React, { useCallback } from 'react';
import Select from '../../../../../components/Select';
import { ReactSelectState, SelectOption } from '../../../../../types';
import ActionButton from '../../../../../components/ActionButton';
import { useImmer } from 'use-immer';
import Modal from '../../../../../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../../redux/store';
import { selectSelectedPatientDocument } from '../../../../../redux/selectedPatient/selectedPatientSelectors';
import { updatePatientDocumentProp } from '../../../../../redux/selectedPatient/selectedPatientActions';
import dateFNSFormat from 'date-fns/format';
import { selectSelectedOrganization } from '../../../../../redux/selectedOrganization/selectedOrganizationSelectors';
import { toPascalCase } from '../../../../../utils';
import { MonitoringLevel, PatientKeys } from '../../../../../database/schemas/Patient';
import RiskAssessmentForm from './RiskAssessmentForm';
import OpioidAgreementDownload from './OpioidAgreementDownload';

interface State {
    takingRiskAssessment: boolean;
}

export default function PatientMonitoring() {
    const [state, updateState] = useImmer<State>({ takingRiskAssessment: false });
    const selectedPatient = useSelector((state: ReduxState) => selectSelectedPatientDocument(state));
    const selectedOrganization = useSelector((state: ReduxState) => selectSelectedOrganization(state));
    const dispatch = useDispatch();
    const patientMonitoringLevel = selectedPatient?.data?.monitoringLevel;
    const monitoringLevelSelect: ReactSelectState = {
        options: [
            { label: 'None', value: String(MonitoringLevel.none) },
            { label: 'Daily', value: String(MonitoringLevel.daily) },
            { label: 'Hourly', value: String(MonitoringLevel.hourly) },
        ],

        get selectedOption() {
            return this.options.find(({ value }) => value === String(patientMonitoringLevel));
        },
        handleSelect(option) {
            dispatch(
                updatePatientDocumentProp({
                    path: PatientKeys.monitoringLevel,
                    value: Number(
                        this.options.find(({ value }) => value === (option as SelectOption).value)?.value
                    ),
                })
            );
        },
    };

    const painGoalSelect: ReactSelectState = {
        options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map(value => ({
            label: value,
            value,
        })),
        get selectedOption() {
            return this.options.find(({ value }) => value === String(selectedPatient?.data.painGoal));
        },
        handleSelect(option) {
            dispatch(
                updatePatientDocumentProp({
                    path: PatientKeys.painGoal,
                    value: Number(
                        this.options.find(({ value }) => value === (option as SelectOption).value)?.value
                    ),
                })
            );
        },
    };

    const toggleModal = useCallback(() => {
        updateState(draft => void (draft.takingRiskAssessment = !draft.takingRiskAssessment));
    }, []);

    return (
        <div className="px-4 py-2">
            <div className="w-full md:w-5/6 flex flex-row items-center justify-start mb-6">
                <h4 className="mr-3 text-gray-700">Monitoring Level:</h4>
                <Select
                    name={PatientKeys.monitoringLevel}
                    options={monitoringLevelSelect.options}
                    selectedOption={monitoringLevelSelect.selectedOption}
                    placeholder={monitoringLevelSelect.selectedOption ? undefined : 'Select level'}
                    onChange={monitoringLevelSelect.handleSelect}
                />
            </div>
            <div className="w-full flex flex-row items-center justify-between mb-6">
                <div className="flex flex-row items-center justify-start">
                    <h4 className="mr-2 text-gray-700">ORT Risk score:</h4>
                    <span className="font-semibold">
                        {toPascalCase(selectedPatient?.data.opioidRiskTool?.score ?? 'Not recorded')}
                    </span>
                </div>
                <ActionButton onClick={toggleModal}>
                    {!selectedPatient?.data.opioidRiskTool ||
                    selectedPatient.data.opioidRiskTool.score.toLocaleLowerCase() === 'none'
                        ? 'Complete ORT'
                        : 'Repeat ORT'}
                </ActionButton>
            </div>
            {selectedPatient?.data.opioidRiskTool?.updatedAt && (
                <span className="text-xs block text-gray-600">
                    Last updated:{' '}
                    {dateFNSFormat(selectedPatient.data.opioidRiskTool.updatedAt.toDate(), 'MMMM do')}
                </span>
            )}
            <div className="flex flex-row items-center justify-start">
                <h4 className="mr-3 text-gray-700">Pain Goal:</h4>
                <div className="w-1/5">
                    <Select
                        options={painGoalSelect.options}
                        selectedOption={painGoalSelect.selectedOption}
                        onChange={painGoalSelect.handleSelect}
                    />
                </div>
            </div>
            <div className="w-full mt-4">
                <h4 className="mr-3 text-xl block text-blue-900 font-semibold">Opioid Agreement</h4>
                <OpioidAgreementDownload />
            </div>
            <Modal isOpen={state.takingRiskAssessment} closeModal={toggleModal}>
                <RiskAssessmentForm
                    organizationName={selectedOrganization?.data.name}
                    patient={selectedPatient!}
                    closeModal={toggleModal}
                />
            </Modal>
        </div>
    );
}
