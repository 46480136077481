import React, { ReactNode, useCallback, useEffect } from 'react';
import { RouteProps } from '../../types';
import isAuthed from '../../hooks/isAuthed';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../redux/store';
import { selectLoadingSelectedOrganization } from '../../redux/selectedOrganization/selectedOrganizationSelectors';
import PageContainer from '../../components/PageContainer';
import Tabulation, { Tab } from '../../components/Tabulation';
import { useImmer } from 'use-immer';
import useNavigation from '../../hooks/useNavigation';
import { AuthRoutes, UrlParams } from '../../routes';
import { globalHistory } from '@reach/router';
import { UserRoles } from '../../database/schemas/User';

interface Props extends RouteProps {
    children: ReactNode;
    organizationId?: UrlParams.organizationId;
}

interface State {
    tabs: Tab[];
    loading: boolean;
}

export default function OrganizationDetails(props: Props) {
    isAuthed([UserRoles.orgAdmin]);
    const navigation = useNavigation(props.organizationId);
    //because org details tabulation affects the url, check to make sure right tab is active on reload of page
    const isActiveTab = (routeToMatch: string) => new RegExp(routeToMatch, 'g').test(window.location.href);

    const [state, updateState] = useImmer<State>({
        tabs: [
            {
                type: 'link',
                to: navigation.providersUrl,
                active: isActiveTab(AuthRoutes.PROVIDERS),
                title: 'Providers',
                key: AuthRoutes.PROVIDERS,
            },
            {
                type: 'link',
                to: navigation.orgAdminsUrl,
                active: isActiveTab(AuthRoutes.ORG_ADMINS),
                title: 'Organization Admins',
                key: AuthRoutes.ORG_ADMINS,
            },
            {
                type: 'link',
                to: navigation.organizationOpioidAgreementsUrl,
                active: isActiveTab(AuthRoutes.OPIOID_AGREEMENTS),
                title: 'Opioid Agreements',
                key: AuthRoutes.OPIOID_AGREEMENTS,
            },
        ],
        loading: true,
    });

    useEffect(() => {
        //subscribe to changes to url to ensure active tab is in sync with routing
        return globalHistory.listen(() => {
            updateState(draft => {
                draft.tabs.map(tab => (tab.active = isActiveTab(tab.key)));
            });
        });
    }, []);

    const loadingSelectedOrganization = useSelector((state: ReduxState) =>
        selectLoadingSelectedOrganization(state)
    );

    const switchTab = useCallback((tabKey: string) => {
        updateState(draft => void draft.tabs.map(tab => (tab.active = tab.key === tabKey)));
    }, []);

    return (
        <PageContainer>
            <Tabulation tabs={state.tabs} loading={loadingSelectedOrganization} switchTab={switchTab}>
                {props.children}
            </Tabulation>
        </PageContainer>
    );
}
