import { useSelector } from 'react-redux';
import { ReduxState } from '../../../redux/store';
import { selectSelectedOrganizationPatients } from '../../../redux/selectedOrganization/selectedOrganizationSelectors';
import { formatPhoneNumber, translateCause } from '../../../utils';
import React, { useContext } from 'react';
import { AlertTableRow, TargetPatients } from './PatientsList';
import styled from 'styled-components';
import useNavigation from '../../../hooks/useNavigation';
import { CurrentUserContext } from '../../../context/CurrentUserContextProvider';
import { navigate } from '@reach/router';
import { Theme } from '../../../theme';

interface Props {
    alerts: AlertTableRow[];
    handlePatientContactedCheckbox: (patientId: string) => void;
    patientFilter: TargetPatients;
}

export default function AlertDashboard(props: Props): JSX.Element {
    const selectedOrganizationPatients = useSelector((state: ReduxState) =>
        selectSelectedOrganizationPatients(state)
    );
    const currentUser = useContext(CurrentUserContext);
    const navigation = useNavigation(currentUser.claims?.currentOrgId);

    const handlePatientContactedCheckbox = async (alertInfo: {
        patientId: string;
        alerts: AlertTableRow['alerts'];
    }): Promise<void> => {
        const targetPatient = selectedOrganizationPatients.find(({ id }) => id === alertInfo.patientId);
        if (targetPatient) {
            await Promise.all(alertInfo.alerts.map(alert => alert.toggleAcknowledged()));
            props.handlePatientContactedCheckbox(targetPatient.id);
        }
    };

    return (
        <div className="w-full mt-5">
            <h2 className="block text-3xl text-gray-900">Alert Dashboard</h2>
            <div className="overflow-auto border" style={{ height: '16.2rem' }}>
                <AlertTable className="w-full relative border-collapse">
                    <thead>
                        <tr>
                            <th className="sticky bg-gray-200 top-0 px-4 py-2 text-left">Name</th>
                            <th className="sticky bg-gray-200 top-0 px-4 py-2 text-left">Patient Contact</th>
                            <th className="sticky bg-gray-200 top-0 px-4 py-2 text-left">Alert Details</th>
                            <th className="sticky bg-gray-200 top-0 px-4 py-2 text-left">
                                Patient Contacted
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.alerts.map(alert => (
                            <tr
                                className="w-full cursor-pointer"
                                key={Number(alert.patientPhoneNumber) * Math.random()}
                                onClick={() => navigate(navigation.getPatientDetailsUrl(alert.patientId))}
                            >
                                <td className="px-4 py-2 border-t border-b">{alert.patientName}</td>
                                <td className="px-4 py-2 border-t border-b">
                                    {formatPhoneNumber(alert.patientPhoneNumber)}
                                </td>
                                <td className="px-4 py-2 border-t border-b">
                                    {alert.alerts.map(({ data }) => (
                                        <span
                                            className="block"
                                            key={data.createdAt.nanoseconds * Math.random()}
                                        >
                                            {translateCause(data.cause)}
                                        </span>
                                    ))}
                                </td>
                                <td
                                    className="px-4 py-2 border-t border-b text-center"
                                    onClick={e => e.stopPropagation()}
                                >
                                    <input
                                        type="checkbox"
                                        className={`form-checkbox text-${Theme.lightBlue} hover:text-${Theme.lightBlueHover} text-2xl mx-auto cursor-pointer`}
                                        checked={!!alert.acknowledgedAt}
                                        onChange={async e => {
                                            e.preventDefault();
                                            await handlePatientContactedCheckbox({
                                                patientId: alert.patientId,
                                                alerts: alert.alerts,
                                            });
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </AlertTable>
            </div>
        </div>
    );
}

const AlertTable = styled.table`
    thead > tr > th {
        background: #edf2f6;
        position: -webkit-sticky;
        position: sticky;
        top: -0.1%;
    }
`;
