import React, { useContext, useEffect } from 'react';
import { RouteProps } from '../../types';
import ToastAlert from '../../components/ToastAlert';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../redux/store';
import { selectToastAlert } from '../../redux/currentSession/currentSessionSelectors';
import CurrentUserProfile from './CurrentUserProfile';
import Delegation from './Delegation';
import { CurrentUserContext } from '../../context/CurrentUserContextProvider';
import isAuthed from '../../hooks/isAuthed';
import useURLQueryParams from '../../hooks/useURLQueryParams';
import { QueryParams } from '../../routes';
import { changeCurrentUserSelectedOrganization } from '../../redux/selectedOrganization/selectedOrganizationActions';

export default function AccountManagement(_: RouteProps) {
    isAuthed();
    const toastAlert = useSelector((state: ReduxState) => selectToastAlert(state));
    const currentUser = useContext(CurrentUserContext);
    const { organizationId } = useURLQueryParams<{ organizationId: QueryParams.organizationId }>();
    const dispatch = useDispatch();

    useEffect(() => {
        if (organizationId && organizationId !== currentUser.claims?.currentOrgId) {
            dispatch(
                changeCurrentUserSelectedOrganization({
                    currentUserClaims: currentUser.claims!,
                    newOrganizationId: organizationId,
                })
            );
        }
    }, [organizationId, currentUser.claims?.currentOrgId]);

    return (
        <div className="w-full flex flex-row bg-white py-5 px-10 mt-3">
            {toastAlert.visible && <ToastAlert message={toastAlert.message} />}
            <div className="flex flex-col w-1/2">
                <h2 className="text-2xl font-semibold px-4 text-blue-900">Edit Profile</h2>
                <div className="rounded-md bg-gray-100 mx-4 border border-gray-300 mt-2 p-4 flex flex-col flex-1">
                    <CurrentUserProfile />
                </div>
            </div>
            {currentUser.claims?.isProviderInCurrentOrganization() && (
                <div className="flex flex-col w-1/2">
                    <h2 className="text-2xl font-semibold px-4 text-blue-900">Delegate Settings</h2>
                    <div className="rounded-md bg-gray-100 mx-4 border border-gray-300 mt-2 p-4 flex flex-col flex-1">
                        <Delegation />
                    </div>
                </div>
            )}
        </div>
    );
}
