import React, { ReactNode } from 'react';
import { Theme } from '../theme';
import LoadingSpinner from './LoadingSpinner';
import styled from 'styled-components';
import { Link } from '@reach/router';

type BaseTabElements = {
    title: string;
    key: string;
    active: boolean;
};

type ComponentTab = BaseTabElements & {
    type: 'component';
    Component: (props?: any) => JSX.Element;
    componentProps?: any;
};
type LinkTab = BaseTabElements & { type: 'link'; to: string };
export type Tab = ComponentTab | LinkTab;

interface Props {
    tabs: Tab[];
    loading: boolean;
    switchTab: (tabKey: string) => void;
    children?: ReactNode;
}
/*
Component is designed to render a list of tab elements that will either (depending on 'type' discriminator) render a React component
when clicked or link to a desired route to allow for nested navigation
 */
export default function Tabulation(props: Props) {
    const renderTabStyles = (isActive: boolean): string => {
        const tabClassNames = `bg-white text-base inline-block py-1 px-2 md:py-2 md:px-4 font-semibold cursor-pointer text-${
            Theme.lightBlue
        } hover:text-${isActive ? Theme.darkBlue : Theme.lightBlueHover}`;
        const activeTabClassNames = `border-l border-t border-r rounded-t text-${Theme.darkBlue}`;
        return isActive ? `${tabClassNames} ${activeTabClassNames}` : tabClassNames;
    };

    return (
        <>
            <ul className="flex">
                {props.tabs.map(tab => (
                    <TabElement key={tab.key} tab={tab}>
                        <span
                            className={renderTabStyles(tab.active)}
                            onClick={() => {
                                props.switchTab(tab.key);
                            }}
                        >
                            {tab.title}
                        </span>
                    </TabElement>
                ))}
            </ul>
            <TabContainer className={`w-full shadow border bg-${Theme.offWhite} py-3 md:p-2`}>
                <ActiveTab loading={props.loading} tabs={props.tabs} children={props.children} />
            </TabContainer>
        </>
    );
}

type ActiveTabProps = Omit<Props, 'switchTab'>;

function ActiveTab(props: ActiveTabProps): JSX.Element {
    if (props.loading) {
        return <LoadingSpinner type="page" />;
    }
    const activeTab = props.tabs.find(({ active }) => active);
    if (activeTab?.type === 'component') {
        return activeTab.componentProps ? (
            <activeTab.Component {...activeTab.componentProps} />
        ) : (
            <activeTab.Component />
        );
    } else {
        return props.children as JSX.Element;
    }
}

interface TabElementProps {
    children: ReactNode;
    tab: Tab;
}

function TabElement({ children, tab }: TabElementProps): JSX.Element {
    const classNames = 'text-sm md:text-lg cursor-pointer';
    return tab.type === 'link' ? (
        <Link className={classNames} to={tab.to}>
            {children}
        </Link>
    ) : (
        <li className={classNames}>{children}</li>
    );
}

const TabContainer = styled.div`
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
`;
