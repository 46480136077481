import React, { createContext, ReactNode, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import firestore from '../../firestore';
import { Claims } from '../Claims';
import DatabaseManager from '../database/DatabaseManager';
import { useImmer } from 'use-immer';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../redux/store';
import { selectPendingAccountCreationInfo } from '../redux/currentSession/currentSessionSelectors';
import ErrorManager, { ErrorType } from '../ErrorManager';
import { UserRoles, UserSchema } from '../database/schemas/User';
import { UserDocument } from '../database/documents/UserDocument';
import AuthManager from '../AuthManager';
import FunctionsManager from '../functions/FunctionsManager';
import {
    clearPendingAccountCreationInfo,
    setLoadingCurrentUser,
} from '../redux/currentSession/currentSessionActions';
import { BrowserStorageKeys } from '../types';

export type CurrentUserState =
    | {
          document: null;
          claims: null;
          viewContext: null;
      }
    | {
          document: UserDocument;
          claims: Claims;
          viewContext: UserRoles | null;
      };

const initialCurrentUserState: CurrentUserState = {
    document: null,
    claims: null,
    viewContext: null,
};

export const CurrentUserContext = createContext<
    CurrentUserState & {
        setViewContext: (viewContext: CurrentUserState['viewContext']) => void;
        updateCurrentUserProp: ({
            prop,
            value,
        }: {
            prop: keyof UserSchema | 'phoneNumber';
            value: string;
        }) => void;
    }
>({
    ...initialCurrentUserState,
    setViewContext: () => {},
    updateCurrentUserProp: () => {},
});

export default function CurrentUserContextProvider({ children }: { children: ReactNode }) {
    const [firebaseUserIdTokenResult, setFirebaseUserIdTokenResult] = useState<
        firebase.auth.IdTokenResult | undefined
    >();
    const [haveRefreshedClaims, setHaveRefreshedClaims] = useState<boolean | null>(false);
    const [state, updateState] = useImmer<CurrentUserState>(initialCurrentUserState);
    const pendingAccountCreationInfo = useSelector((state: ReduxState) =>
        selectPendingAccountCreationInfo(state)
    );
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            if (firebaseUserIdTokenResult) {
                let claims = firebaseUserIdTokenResult.claims as Claims & firebase.auth.IdTokenResult;
                //if user was logged out after changing their email, make sure the firestore document is updated
                if (AuthManager.currentUserEmail !== state.document?.data.email) {
                    const updatedUser = await state.document?.update({
                        email: AuthManager.currentUserEmail!,
                    });
                    updateState(draft => void (draft.document = updatedUser!));
                }
                // if firestoreId not on claims and we haven't already refreshed, then it's a new user - update custom claims
                if (!claims.firestoreId && haveRefreshedClaims === false) {
                    try {
                        await FunctionsManager.user.updateCustomClaims();
                        await firebase.auth().currentUser?.getIdTokenResult(true);
                        setHaveRefreshedClaims(true);
                    } catch (error) {
                        console.log('updateCustomClaims', error);
                        dispatch(setLoadingCurrentUser(false));
                        throw error;
                    }
                    // if we've already tried to refresh, then we know the user doesn't exist
                } else if (!claims.firestoreId && haveRefreshedClaims) {
                    dispatch(setLoadingCurrentUser(false));
                    throw new Error("User doesn't exist in Firestore");
                } else {
                    const finalClaims = new Claims(firebaseUserIdTokenResult);
                    const currentUser = finalClaims.isProviderInCurrentOrganization()
                        ? await DatabaseManager.ProviderModel.get(finalClaims.firestoreId!)
                        : await DatabaseManager.UserModel.get(finalClaims.firestoreId!);
                    if (pendingAccountCreationInfo) {
                        const updatedUser = await currentUser.update({ ...pendingAccountCreationInfo });
                        updateState(draft => void (draft.document = updatedUser!));
                        dispatch(clearPendingAccountCreationInfo());
                    }
                    updateState(draft => {
                        draft.document = currentUser;
                        draft.claims = finalClaims;
                        draft.viewContext = deriveViewContextFromClaims(finalClaims);
                    });
                }
            } else {
                updateState(() => initialCurrentUserState);
                setTimeout(() => {
                    dispatch(setLoadingCurrentUser(false));
                }, 2000);
            }
        })();
    }, [firebaseUserIdTokenResult?.token]);

    useEffect(
        () =>
            firestore.auth().onIdTokenChanged(async user => {
                setFirebaseUserIdTokenResult(await user?.getIdTokenResult());
            }),
        []
    );

    useEffect(
        () =>
            firestore.auth().onAuthStateChanged(async user => {
                setFirebaseUserIdTokenResult(await user?.getIdTokenResult());
            }),
        []
    );

    return (
        <CurrentUserContext.Provider
            value={{
                ...state,
                setViewContext: viewContext => {
                    if (viewContext && !Object.values(UserRoles).includes(viewContext)) {
                        throw new ErrorManager('Invalid view context being set', ErrorType.viewContext);
                    }
                    updateState(draft => void (draft.viewContext = viewContext));
                },
                updateCurrentUserProp: async ({ prop, value }) => {
                    if (state.document!.data[prop]) {
                        await state.document?.update({ [prop]: value });
                        updateState(draft => {
                            draft.document!.data = { ...draft.document!.data, [prop]: value };
                        });
                    }
                },
            }}
        >
            {children}
        </CurrentUserContext.Provider>
    );
}

export function deriveViewContextFromClaims(claims: Claims): CurrentUserState['viewContext'] {
    if (claims.isProviderInCurrentOrganization() && claims.isOrgAdminInCurrentOrganization()) {
        const lastViewContext = localStorage.getItem(BrowserStorageKeys.VIEW_CONTEXT)
            ? Number(localStorage.getItem(BrowserStorageKeys.VIEW_CONTEXT))
            : null;
        return lastViewContext ?? UserRoles.provider;
    } else {
        // if the user is a super admin, or just an org admin or provider (not both)
        return null;
    }
}
