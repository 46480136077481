import firebase from 'firebase';
import { BaseDocument } from './database/documents/BaseDocument';
import { Timestamp } from './database/FirestoreTypes';
import { Timestamps } from './database/schemas/Timestamps';
import isEqual from 'lodash/isequal';
import * as dateFNS from 'date-fns';
import { AlertCause } from './database/schemas/Patient';
import { OrganizationsAndRoles, UserOrganizations } from './database/schemas/User';

export function isValidEmail(email: string): boolean {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email.toLocaleLowerCase());
}

export function truncate(str: string, maxChar: number): string {
    return str.length > maxChar ? `${str.substr(0, maxChar)}...` : str;
}

export function isFirestoreDocument<T>(object: any): object is T {
    return object instanceof BaseDocument;
}

export function convertDocToSchema<T extends BaseDocument<S>, S extends Timestamps>(doc: any): S {
    return isFirestoreDocument<T>(doc) ? doc.data : doc;
}

export function scrollPageTo(position: 'top' | 'bottom'): void {
    window.scrollTo({
        top: position === 'bottom' ? document.body.scrollHeight : 0,
        left: 0,
        behavior: 'smooth',
    });
}

export async function downloadFile(url: string, name: string): Promise<void> {
    const res = await fetch(url);
    const blob = await res.blob();
    const link = document.createElement('a');
    const blobUrl = URL.createObjectURL(blob);
    link.href = blobUrl;
    link.download = name;
    link.click();
    URL.revokeObjectURL(blobUrl);
}

export function getTimestamp(date: Date = new Date()): Timestamp {
    return firebase.firestore.Timestamp.fromDate(date);
}

export function diffObjects<T, R>(object1: T, object2: T): R {
    return (Object.keys(object1).reduce((result, key) => {
        if (!(object2 as Object).hasOwnProperty(key)) {
            result.push(key);
        } else if (isEqual(object1[key], object2[key])) {
            const resultKeyIndex = result.indexOf(key);
            result.splice(resultKeyIndex, 1);
        }
        return result;
    }, Object.keys(object2)) as any) as R;
}

export function renderRelativeDateFromTimeStamp(date: Date): string {
    if (dateFNS.isToday(date)) {
        return 'Today';
    } else if (dateFNS.isYesterday(date)) {
        return 'Yesterday';
    } else {
        const differenceInCalendarDays = dateFNS.differenceInCalendarDays(new Date(), date);
        return `${differenceInCalendarDays} days ago`;
    }
}

export function toPascalCase(str?: string): string | undefined {
    return str
        ?.toLocaleLowerCase()
        .replace(
            /\w\S*/g,
            string => string.charAt(0).toLocaleUpperCase() + string.substr(1).toLocaleLowerCase()
        );
}

export function formatPhoneNumber(phoneNumber: string | number): string | undefined {
    const cleaned = ('' + String(phoneNumber)).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
}

export function unformatPhoneNumber(phoneNumber: string): string {
    return phoneNumber
        .split('')
        .filter(char => /\d/g.test(char))
        .join('');
}

export function isValidPhoneNumber(phoneNumber: string): boolean {
    return /^1?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(phoneNumber);
}

export function translateCause(cause: AlertCause) {
    switch (cause) {
        case AlertCause.scoredHighOnOpioidRiskTool:
            return 'Patient scored high on opioid risk tool';
        case AlertCause.didNotRegisterAfterThreeNotifications:
            return 'Patient did not register after 3 notifications';
        case AlertCause.didNotCompleteOpioidAgreementAfterThreeDays:
            return 'Patient did not complete opioid agreement after 3 days';
        case AlertCause.didNotReportMedsTakenForFiveDays:
            return 'Patient did not report medications taken for 5 days';
        case AlertCause.tookMoreThanTwelveShortActingOpioidsInOneDay:
            return 'Patient took more than 12 short acting opioids in 1 day';
        case AlertCause.painScoreGteSevenforThreeDaysWithNoPainMeds:
            return 'Patient pain score was more than 6 for 3 days with pain medications';
        case AlertCause.painScoreOfTenForThreeDays:
            return 'Patient had pain score of 10 for 3 days';
        case AlertCause.noBowelMovementForFiveDays:
            return 'Patient has not had a bowel movement in 5 days';
        case AlertCause.tookTooManyPills:
            return 'Patient has taken too many pills';
        case AlertCause.transitionedFromHourlyToDailyMonitoring:
            return 'Patient transitioned from hourly to daily monitoring';
    }
}

export function getOrganizationsAndRoles(organizations: UserOrganizations): OrganizationsAndRoles {
    const organizationsAndRoles = {};

    Object.keys(organizations).forEach(organizationId => {
        organizationsAndRoles[organizationId] = organizations[organizationId].roles;
    });

    return organizationsAndRoles;
}

export function getDateFromDateString(date: string) {
    return dateFNS.parse(date, 'yyyy-MM-dd', new Date());
}

//return a formatted date string "YYYY-MM-DD"
export function getDateString(date: Date | Timestamp = new Date()): string {
    if (date instanceof firebase.firestore.Timestamp) {
        date = date.toDate();
    }
    const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    return `${date.getFullYear()}-${month}-${day}`;
}
