import React, { ReactNode } from 'react';

interface Props {
    children: ReactNode;
    visible: boolean;
    width?: string;
    belowIcon?: boolean;
}

export default function ToolTip(props: Props) {
    const spanStyles = props.belowIcon ? { top: '20px' } : { bottom: '20px' };

    return (
        <span
            className={`${
                props.visible
                    ? 'flex flex-row border border-gray-500 text-sm text-gray-600 justify-start items-center'
                    : 'hidden'
            } bg-gray-100 text-left rounded-md shadow-md p-4 z-50 absolute right-0`}
            style={{ ...spanStyles, width: props.width }}
        >
            {props.children}
        </span>
    );
}
