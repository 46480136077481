import { useEffect } from 'react';
import { useImmer } from 'use-immer';
import Navigator from '../routes';

export default function useNavigation(orgId?: string): Navigator {
    const [state, updateState] = useImmer<Navigator>(new Navigator(orgId));

    useEffect(() => {
        updateState(draft => {
            draft.orgId = orgId;
        });
    }, [orgId]);

    return state;
}
