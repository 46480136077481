import React from 'react';
import { AuthRoutes, NonAuthRoutes } from './routes';
import Login from './pages/Login';
import PatientsList from './pages/patients/patientList/PatientsList';
import { Router } from '@reach/router';
import PatientDetails from './pages/patients/PatientDetails';
import Unauthorized from './pages/Unauthorized';
import ResetPassword from './pages/ResetPassword';
import LoadingSpinner from './components/LoadingSpinner';
import NavBar from './components/NavBar';
import ErrorBoundary from './components/ErrorBoundary';
import isAuthed from './hooks/isAuthed';
import AccountManagement from './pages/account/AccountManagement';
import OrganizationDetails from './pages/orgAdmin/OrganizationDetails';
import ProviderDetails from './pages/orgAdmin/tabs/providers/ProviderDetails';
import OpioidAgreementList from './pages/orgAdmin/tabs/opioidAgreements/OpioidAgreementList';
import AddOpioidAgreement from './pages/orgAdmin/tabs/opioidAgreements/AddOpioidAgreement';
import AddPatient from './pages/patients/AddPatient';
import OpioidAgreementDetails from './pages/orgAdmin/tabs/opioidAgreements/OpioidAgreementDetails';
import OrganizationList from './pages/appAdmin/OrganizationList';
import OrganizationAdminList from './pages/appAdmin/OrganizationAdminList';
import OrganizationAdminDetails from './pages/orgAdmin/tabs/orgAdmins/OrganizationAdminDetails';
import UserList from './pages/orgAdmin/tabs/UserList';
import { UserRoles } from './database/schemas/User';
import AccountSetup from './pages/account/AccountSetup';
import OpioidAgreementRenewals from './pages/OpioidAgreementRenewals';
import { selectLoadingCurrentUser } from './redux/currentSession/currentSessionSelectors';
import { useSelector } from 'react-redux';
import { ReduxState } from './redux/store';
import RedirectPatient from './pages/RedirectPatient';

export default function App() {
    isAuthed();
    const loadingCurrentUser = useSelector((state: ReduxState) => selectLoadingCurrentUser(state));
    return loadingCurrentUser ? (
        <LoadingSpinner type="page" />
    ) : (
        <ErrorBoundary>
            <NavBar />
            <Router>
                <RedirectPatient path={NonAuthRoutes.DOWNLOAD_MOBILE_APP} />
                <Login path={NonAuthRoutes.LOGIN} />
                <AccountSetup path={NonAuthRoutes.ACCOUNT_SETUP} />
                <AccountManagement path={AuthRoutes.ACCOUNT} />
                <ResetPassword path={NonAuthRoutes.RESET_PASSWORD} />
                <OrganizationList path={AuthRoutes.ADMIN_ORGANIZATIONS} />
                <OrganizationAdminList path={AuthRoutes.ADMIN_ORGANIZATIONS_VIEW_ORGANIZATION} />
                <OrganizationAdminDetails path={AuthRoutes.ADMIN_ORGANIZATIONS_VIEW_ADMIN} />
                <OpioidAgreementRenewals path={AuthRoutes.OPIOID_AGREEMENT_RENEWALS} />
                <PatientsList path={AuthRoutes.PATIENTS} />
                <PatientDetails path={AuthRoutes.VIEW_PATIENT} />
                <AddPatient path={AuthRoutes.ADD_PATIENT} />
                <OrganizationDetails path={AuthRoutes.VIEW_ORGANIZATION}>
                    <UserList default userRole={UserRoles.provider} path={AuthRoutes.PROVIDERS} />
                    <UserList userRole={UserRoles.orgAdmin} path={AuthRoutes.ORG_ADMINS} />
                    <OrganizationAdminDetails path={AuthRoutes.VIEW_ORG_ADMIN} />
                    <ProviderDetails path={AuthRoutes.VIEW_PROVIDER} />
                    <OpioidAgreementList path={AuthRoutes.OPIOID_AGREEMENTS} />
                    <AddOpioidAgreement path={AuthRoutes.ADD_OPIOID_AGREEMENT} />
                    <OpioidAgreementDetails path={AuthRoutes.VIEW_OPIOID_AGREEMENT} />
                </OrganizationDetails>
                <Unauthorized default />
            </Router>
        </ErrorBoundary>
    );
}
