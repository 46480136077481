import { firestore } from 'firebase';
import { DocumentSnapshot } from '../FirestoreTypes';
import { BaseDocument } from './BaseDocument';
import { OpioidAgreementSchema } from '../schemas/Organization';
import { UserOpioidAgreement } from '../schemas/Patient';

export class OpioidAgreementDocument extends BaseDocument<OpioidAgreementSchema> {
    constructor(snapshot: DocumentSnapshot<OpioidAgreementSchema>) {
        super(snapshot, 'Opioid Agreement');
    }

    toggleArchivedStatus(): Promise<OpioidAgreementDocument> {
        return this.update({ archivedAt: this.data.archivedAt ? null : firestore.Timestamp.now() });
    }

    updateUserAssignedAgreement(currentOpioidAgreement: UserOpioidAgreement): UserOpioidAgreement {
        return {
            assignedOpioidAgreementId: this.id,
            currentOpioidAgreementId: currentOpioidAgreement.currentOpioidAgreementId,
            expirationDate: currentOpioidAgreement.expirationDate,
            pdf: currentOpioidAgreement.pdf,
        };
    }
}
