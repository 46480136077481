import FirebaseApp from '../../firestore';
import { functions } from 'firebase/app';
import * as inviteTypes from './inviteTypes';
import * as userTypes from './userTypes';

class FunctionsManager {
    constructor(private functions: functions.Functions) {}
    invite = {
        patient: async (request: inviteTypes.InvitePatientRequest): Promise<inviteTypes.InviteResponse> => {
            return (await this.functions.httpsCallable('api/invitePatient')(request)).data;
        },
        provider: async (request: inviteTypes.InviteProviderRequest): Promise<inviteTypes.InviteResponse> => {
            const { userId } = (
                await this.functions.httpsCallable('api/inviteProvider')({ email: request.email })
            ).data;
            if (request.isAlsoOrgAdmin) {
                await this.user.updateOrganizationRole({
                    userId,
                    organizationAdmin: true,
                    provider: true,
                });
            }
            return { userId };
        },
        organizationAdmin: async (
            request: inviteTypes.InviteOrganizationAdminRequest
        ): Promise<inviteTypes.InviteResponse> => {
            const { userId } = (
                await this.functions.httpsCallable('api/inviteOrganizationAdmin')({
                    email: request.email,
                    organizationId: request.organizationId,
                })
            ).data;
            if (request.isAlsoProvider) {
                await this.user.updateOrganizationRole({
                    userId,
                    organizationAdmin: true,
                    provider: true,
                });
            }
            return { userId };
        },
        isEligible: async (
            request: inviteTypes.CheckInviteRequest
        ): Promise<inviteTypes.CheckInviteResponse> => {
            return (await this.functions.httpsCallable('api/checkEligibility')(request)).data;
        },
        changeInvitedPatientEmail: async (
            request: inviteTypes.ChangeInvitedPatientEmailRequest
        ): Promise<void> => {
            await this.functions.httpsCallable('/api/changeInvitedPatientEmail')(request);
        },
    };

    user = {
        toggleStatus: async (
            request: userTypes.ToggleUserStatusQuery
        ): Promise<userTypes.ToggleUserStatusResponse> => {
            return (await this.functions.httpsCallable('api/toggleUserStatus')(request)).data;
        },
        updateOrganizationRole: async (request: userTypes.ChangeOrgRoleRequest): Promise<void> => {
            await this.functions.httpsCallable('api/updateOrganizationRole')(request);
        },
        setCurrentOrganization: async (request: userTypes.SetCurrentOrganizationRequest): Promise<void> => {
            await this.functions.httpsCallable('api/setCurrentOrganization')(request);
        },
        removeSelfAsDelegate: async (request: userTypes.RemoveSelfAsDelegateRequest): Promise<void> => {
            await this.functions.httpsCallable('api/removeSelfAsDelegate')(request);
        },
        updateDelegates: async (request: userTypes.UpdateDelegatesRequest): Promise<void> => {
            await this.functions.httpsCallable('api/updateDelegates')(request);
        },
        getEmailByInviteId: async (
            request: userTypes.GetEmailByInviteIdRequest
        ): Promise<userTypes.GetEmailByInviteIdResponse> => {
            return (await this.functions.httpsCallable('api/getEmailByInviteId')(request)).data;
        },
        queryPatients: async (
            request: userTypes.PatientQueryRequest
        ): Promise<userTypes.PatientQueryResponse> => {
            return (await this.functions.httpsCallable('api/queryPatients')(request)).data;
        },
        updateCustomClaims: async (): Promise<void> => {
            await this.functions.httpsCallable('api/updateCustomClaims')();
        },
        sendResetPasswordEmail: async (request: userTypes.SendPasswordResetEmailRequest): Promise<void> => {
            await this.functions.httpsCallable('api/sendPasswordResetEmail')(request);
        },
    };
}

export default new FunctionsManager(FirebaseApp.functions());
