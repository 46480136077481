import {
    GroupedAlerts,
    MedicationFormType,
    SelectedPatientActions,
    SelectedPatientActionTypes,
    SelectedPatientState,
    UpdatePatientArgs,
} from './selectedPatientReducer';
import DatabaseManager from '../../database/DatabaseManager';
import { Dispatch } from '../../types';
import { Thunk } from '../reduxTypes';
import { UserMedicationDocument } from '../../database/documents/UserMedicationDocument';
import { formatPhoneNumber, isFirestoreDocument } from '../../utils';
import { ReduxState } from '../store';
import {
    selectSelectedPatientActiveAlerts,
    selectSelectedPatientDocument,
    selectSelectedPatientUpdates,
    selectUpdatedSelectedPatient,
} from './selectedPatientSelectors';
import { PatientDocument } from '../../database/documents/PatientDocument';
import { InvitePatientRequest } from '../../functions/inviteTypes';
import { PatientKeys, UserMedicationSchema } from '../../database/schemas/Patient';
import { setToastAlert, setToastError } from '../currentSession/currentSessionActions';
import FunctionsManager from '../../functions/FunctionsManager';

type DispatchSelectedPatientActions = Dispatch<SelectedPatientActionTypes>;
type SelectedPatientThunk = Thunk<SelectedPatientActionTypes>;

export const getPatientById = (patientId: string): SelectedPatientThunk => async (
    dispatch: DispatchSelectedPatientActions
): Promise<void> => {
    const selectedPatient = await DatabaseManager.PatientModel.get(patientId);
    const [medications, entries] = await Promise.all([
        selectedPatient.getActiveMedications(),
        selectedPatient.getAllEntries(),
    ]);

    const { active, resolved } = await selectedPatient.getAlertsAboveTierOne();
    const form: InvitePatientRequest | {} = {};

    for (const prop in selectedPatient.data) {
        if (prop in PatientKeys) {
            form[prop] = selectedPatient.data[prop];
        }
    }
    dispatch({
        type: SelectedPatientActions.SET_SELECTED_PATIENT,
        payload: {
            form: {
                ...form,
                emergencyPhoneNumber: formatPhoneNumber((form as InvitePatientRequest).emergencyPhoneNumber),
            },
            medications,
            document: selectedPatient,
            entries,
            alerts: { active, resolved },
        } as SelectedPatientState,
    });
};

export const saveChangesToPatient = (): SelectedPatientThunk => async (
    dispatch, //will ultimately dispatch another thunk
    getState: () => ReduxState
): Promise<void> => {
    const reduxState = getState();
    const patientDocument = selectSelectedPatientDocument(reduxState);
    const updatedPatientFields = selectSelectedPatientUpdates(reduxState);
    const updatedPatient = selectUpdatedSelectedPatient(reduxState);
    const activeAlerts = selectSelectedPatientActiveAlerts(reduxState);

    if (!updatedPatientFields.length) {
        return;
    }

    try {
        if (patientDocument) {
            if (updatedPatientFields.includes('document')) {
                await patientDocument.save();
            }
            if (updatedPatientFields.includes('form')) {
                const { email, ...patientForm } = updatedPatient.form;
                await patientDocument.update({ ...patientForm });
                if (patientDocument.data.email !== email) {
                    await FunctionsManager.invite.changeInvitedPatientEmail({
                        patientId: patientDocument.id,
                        newEmail: email,
                    });
                }
            }
            if (updatedPatientFields.includes('medications')) {
                saveChangesForPatientMedications({
                    medications: updatedPatient.medications,
                    patient: patientDocument,
                });
            }
            if (updatedPatientFields.includes('alerts')) {
                await Promise.all(activeAlerts.map(alert => alert.save()));
                const { active, resolved } = await patientDocument.getAlertsAboveTierOne();
                dispatch(
                    updateGroupedAlerts({
                        active,
                        resolved,
                    })
                );
            }
        }
        dispatch(setToastAlert('Changes saved for patient'));
        dispatch(mergePatientChangesToOriginal());
    } catch (error) {
        console.log(`Save changes to patient: ${error}`);
        dispatch(setToastError('An error occurred while saving changes for patient'));
    }
};

export const updateGroupedAlerts = (groupedAlerts: GroupedAlerts): SelectedPatientThunk => async (
    dispatch: DispatchSelectedPatientActions
): Promise<void> => {
    dispatch({ type: SelectedPatientActions.SET_SELECTED_PATIENT_ALERTS, payload: groupedAlerts });
};

export const mergePatientChangesToOriginal = (): SelectedPatientThunk => async (
    dispatch: DispatchSelectedPatientActions
): Promise<void> => {
    dispatch({ type: SelectedPatientActions.MERGE_UPDATED_PATIENT_FIELDS });
};

export const cancelChangesToPatient = (): SelectedPatientThunk => async (
    dispatch: DispatchSelectedPatientActions
): Promise<void> => {
    dispatch({ type: SelectedPatientActions.CANCEL_CHANGES_FOR_PATIENT });
};

export const handlePatientFormInput = (formState: UpdatePatientArgs): SelectedPatientThunk => async (
    dispatch: DispatchSelectedPatientActions
): Promise<void> => {
    dispatch({ type: SelectedPatientActions.UPDATE_SELECTED_PATIENT_FORM_PROP, payload: formState });
};

export const updatePatientDocumentProp = (formState: UpdatePatientArgs): SelectedPatientThunk => async (
    dispatch: DispatchSelectedPatientActions
): Promise<void> => {
    dispatch({ type: SelectedPatientActions.UPDATE_SELECTED_PATIENT_DOCUMENT_FIELD, payload: formState });
};

export const clearSelectedPatient = (): SelectedPatientThunk => async (
    dispatch: DispatchSelectedPatientActions
): Promise<void> => {
    dispatch({ type: SelectedPatientActions.CLEAR_SELECTED_PATIENT });
};

export const clearSelectedPatientForm = (): SelectedPatientThunk => async (
    dispatch: DispatchSelectedPatientActions
): Promise<void> => {
    dispatch({ type: SelectedPatientActions.CLEAR_SELECTED_PATIENT_FORM });
};

/*
 * MEDICATIONS
 *  */

export const addMedicationForSelectedPatient = (
    medicationForm: UserMedicationSchema
): SelectedPatientThunk => async (dispatch: DispatchSelectedPatientActions): Promise<void> => {
    dispatch({ type: SelectedPatientActions.ADD_SELECTED_PATIENT_MEDICATION, payload: medicationForm });
};

export const removeMedicationForSelectedPatient = (medicationIndex: number): SelectedPatientThunk => async (
    dispatch: DispatchSelectedPatientActions
): Promise<void> => {
    dispatch({
        type: SelectedPatientActions.REMOVE_SELECTED_PATIENT_MEDICATION,
        payload: medicationIndex,
    });
};

export const updatedMedicationForSelectedPatient = ({
    updatedMedication,
    medicationIndex,
}: {
    updatedMedication: UserMedicationDocument;
    medicationIndex: number;
}): SelectedPatientThunk => async (dispatch: DispatchSelectedPatientActions): Promise<void> => {
    dispatch({
        type: SelectedPatientActions.UPDATE_SELECTED_PATIENT_MEDICATION,
        payload: { index: medicationIndex, updatedMedication },
    });
};

/*
 * ALERTS
 * */

export const toggleSelectedPatientAlertResolved = (alertId: string): SelectedPatientThunk => async (
    dispatch: DispatchSelectedPatientActions
): Promise<void> => {
    dispatch({ type: SelectedPatientActions.TOGGLE_ALERT_RESOLVED, payload: alertId });
};

/*
 * UTIL
 * */
function saveChangesForPatientMedications({
    medications,
    patient,
}: {
    medications: MedicationFormType[];
    patient: PatientDocument;
}) {
    medications.forEach(async medication => {
        if (isFirestoreDocument<UserMedicationDocument>(medication)) {
            await medication.save();
        } else {
            await patient.createMedication(medication as UserMedicationSchema);
        }
    });
}
