import React, { useEffect, useRef } from 'react';
import '../../../lib/sparkline.css';
import sparkline from '../../../lib/sparkline';
import { noAccountTablePlaceholder, tablePlaceholder } from './patientTableFormatters';
import { PatientTableRow, SortStatus } from './PatientsList';
import ToolTip from '../../../components/ToolTip';
import IconManager, { IconType } from '../../../components/IconManager';
import { Theme } from '../../../theme';
import { OpioidRiskStatus } from '../../../database/schemas/Patient';
import { getDateFromDateString, renderRelativeDateFromTimeStamp, toPascalCase } from '../../../utils';
import * as dateFNS from 'date-fns';

const baseClassNames = 'block w-full text-center mx-auto px-2 py-1';

function Placeholder(): JSX.Element {
    return <span className={`${baseClassNames} text-black rounded-md`}>{tablePlaceholder}</span>;
}

export function PainLevel(props: { value: number | null }): JSX.Element {
    if (!props.value) {
        return <Placeholder />;
    } else {
        const valueIsHighRisk = props.value >= 7;
        return (
            <span
                className={`${baseClassNames} rounded-md text-${valueIsHighRisk ? 'white' : 'black'} bg-${
                    valueIsHighRisk ? 'red-500' : 'transparent'
                }`}
            >
                {props.value}
            </span>
        );
    }
}

export function ConstipationDays(props: {
    lastBowelMovementDate: PatientTableRow['lastBowelMovementDate'];
}): JSX.Element {
    if (!props.lastBowelMovementDate) {
        return <Placeholder />;
    } else {
        const timeStamp = getDateFromDateString(props.lastBowelMovementDate);
        const daysSinceLastBowelMovement = dateFNS.differenceInCalendarDays(new Date(), timeStamp);
        const isHighRisk = daysSinceLastBowelMovement >= 5;
        return (
            <span
                className={`${baseClassNames} rounded-md text-${isHighRisk ? 'white' : 'black'} bg-${
                    isHighRisk ? 'red-500' : 'transparent'
                }`}
            >
                {daysSinceLastBowelMovement} day{daysSinceLastBowelMovement !== 1 && 's'}
            </span>
        );
    }
}

export function PainLevelSparkLine(props: { painLevels: PatientTableRow['painSparkline'] }): JSX.Element {
    const sparkLineRef = useRef<SVGSVGElement>(null);
    useEffect(() => {
        if (sparkLineRef.current) {
            sparkline(sparkLineRef.current, props.painLevels ?? []);
        }
    }, []);

    return props.painLevels ? (
        <svg ref={sparkLineRef} className="sparkline" width="175" height="75" strokeWidth="3" />
    ) : (
        <Placeholder />
    );
}

export function DayOfLastEntry(props: {
    dayOfLastEntry: PatientTableRow['lastEntryDate'];
    patientDoesNotHaveAccount?: boolean;
}): JSX.Element {
    let classNames = baseClassNames;
    const formattedDayOfLastEntry = (() => {
        if (!props.dayOfLastEntry) {
            return props.patientDoesNotHaveAccount ? noAccountTablePlaceholder : tablePlaceholder;
        } else {
            const timeStamp = getDateFromDateString(props.dayOfLastEntry);
            return renderRelativeDateFromTimeStamp(timeStamp);
        }
    })();
    if (formattedDayOfLastEntry === noAccountTablePlaceholder || formattedDayOfLastEntry.startsWith('3')) {
        classNames += ' bg-yellow-300 text-black rounded-md';
    }
    return <span className={classNames}>{formattedDayOfLastEntry}</span>;
}

export function RefillStatus({
    percentage,
}: {
    percentage: PatientTableRow['lowestRefillPercentage'];
}): JSX.Element {
    return percentage === null ? (
        <Placeholder />
    ) : (
        <div className="w-full bg-gray-100 border rounded-sm">
            <div className="bg-blue-400 text-xs leading-none py-1" style={{ width: `${percentage}%` }} />
        </div>
    );
}

export function ShortActingUsage({
    opioidRiskStatus,
}: {
    opioidRiskStatus: PatientTableRow['shortActingOpioidUsage'];
}) {
    return (
        <span
            className={`rounded-md ${baseClassNames} ${
                opioidRiskStatus === OpioidRiskStatus.HIGH
                    ? 'bg-red-500 text-white'
                    : 'bg-transparent text-black'
            }`}
        >
            {toPascalCase(OpioidRiskStatus[opioidRiskStatus])}
        </span>
    );
}

export function PreviousAlertContact({
    alert,
}: {
    alert?: PatientTableRow['lastTierThreeOrInformationalAlert'];
}) {
    if (!alert) {
        return <Placeholder />;
    }
    return (
        <div className={`text-center  flex flex-col justify-center`}>
            <span className="block">Tier {alert.tier}</span>
            <span className={!alert.acknowledgedAt ? 'bg-yellow-300 px-2 py-1  text-black rounded-md' : ''}>
                {!alert.acknowledgedAt
                    ? 'Not contacted'
                    : dateFNS.format(alert.acknowledgedAt.toDate(), 'MM/dd/yyyy')}
            </span>
        </div>
    );
}

export function PatientSearchToolTip({
    visible,
    belowIcon,
}: {
    visible: boolean;
    belowIcon?: boolean;
}): JSX.Element {
    const codeClassNames = 'text-gray-600 rounded-md p-1 bg-gray-300';
    return (
        <ToolTip visible={visible} belowIcon={belowIcon}>
            <div className="flex flex-col justify-start items-start text-left">
                <h6 className="text-base font-semibold">Search by patient name, email or MRN</h6>
                <div className="mt-2 px-3 leading-loose">
                    <span className="text-base leading-none">
                        You can also filter for certain conditions:
                    </span>
                    <ul className="list-disc">
                        <li>
                            <code className={codeClassNames}>constipation:2</code> - Patients with 2 or more
                            days of constipation
                        </li>
                        <li>
                            <code className={codeClassNames}>lastentry:3</code> - Patients with their last
                            entry 3 or more days ago
                        </li>
                        <li>
                            <code className={codeClassNames}>monitoring:none</code>,{' '}
                            <code className={codeClassNames}>monitoring:daily</code> or{' '}
                            <code className={codeClassNames}>monitoring:hourly</code> - Show patients with the
                            corresponding monitoring status
                        </li>
                        <li>
                            <code className={codeClassNames}>pain:6</code> - Patients with average pain score
                            of 6 or greater
                        </li>
                        <li>
                            <code className={codeClassNames}>shortacting:low</code>,{' '}
                            <code className={codeClassNames}>shortacting:medium</code> or{' '}
                            <code className={codeClassNames}>shortacting:high</code> - Show patients with low,
                            medium or high risk status relating to short-acting opioid consumption
                        </li>
                    </ul>
                </div>
            </div>
        </ToolTip>
    );
}

interface SortableColumn {
    title: string;
    toggleSortOrder?: () => void;
    sortStatus?: SortStatus;
    width?: string;
}

export function SortableColumn(props: SortableColumn): JSX.Element {
    const isActiveSort = props.sortStatus?.isActive || !!props.sortStatus?.priority;
    return (
        <th className="border relative px-4 py-2" style={{ width: props.width }}>
            <button
                className={`font-semibold text-left flex flex-row justify-between w-full ${
                    props.toggleSortOrder ? 'cursor-pointer' : 'cursor-default'
                }`}
                onClick={props.toggleSortOrder}
            >
                <span className="block flex flex-row items-center justify-end absolute top-0 right-0 mt-2 mr-1">
                    {isActiveSort && (
                        <IconManager
                            type={
                                props.sortStatus?.order === 'ascending'
                                    ? IconType.ARROW_UP
                                    : IconType.ARROW_DOWN
                            }
                            stroke="#3B98CF"
                            size={20}
                        />
                    )}
                    {props.sortStatus?.priority && (
                        <span className={`text-sm text-${Theme.lightBlue}`}>
                            {props.sortStatus?.priority}
                        </span>
                    )}
                </span>
                <span
                    className={`block mr-3 ${
                        isActiveSort ? `text-${Theme.lightBlue} hover:text-${Theme.lightBlueHover}` : ''
                    }`}
                    style={{ fontSize: '15px' }}
                >
                    {props.title}
                </span>
            </button>
        </th>
    );
}
