import React, { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../redux/store';
import {
    selectSelectedOrganizationOrgAdmins,
    selectSelectedOrganizationProviders,
} from '../../../redux/selectedOrganization/selectedOrganizationSelectors';
import { selectToastAlert } from '../../../redux/currentSession/currentSessionSelectors';
import { CurrentUserContext } from '../../../context/CurrentUserContextProvider';
import useNavigation from '../../../hooks/useNavigation';
import { translateUserRole, UserFormKeys, UserRoles } from '../../../database/schemas/User';
import ToastAlert from '../../../components/ToastAlert';
import { Link } from '@reach/router';
import { Theme } from '../../../theme';
import ActionButton from '../../../components/ActionButton';
import { useImmer } from 'use-immer';
import Modal from '../../../components/Modal';
import { FormSubmission, InputChange, RouteProps, ValidatableString } from '../../../types';
import { formatPhoneNumber, isValidEmail } from '../../../utils';
import { ProviderDocument } from '../../../database/documents/ProviderDocument';
import TextInput from '../../../components/TextInput';
import SubmitButton from '../../../components/SubmitButton';
import FunctionsManager from '../../../functions/FunctionsManager';
import { setToastAlert, setToastError } from '../../../redux/currentSession/currentSessionActions';
import { InviteStatus } from '../../../functions/userTypes';
import { InviteOrganizationAdminRequest, InviteProviderRequest } from '../../../functions/inviteTypes';
import { OrganizationUser } from '../../../redux/selectedOrganization/selectedOrganizationReducer';
import LoadMoreButton from '../../../components/LoadMoreButton';

interface Props extends RouteProps {
    userRole: UserRoles.orgAdmin | UserRoles.provider;
}

interface State {
    invitingUser: boolean;
    inviteStatus: InviteStatus;
    activePage: number;
    paginatedUsers: OrganizationUser[];
    form: {
        email: ValidatableString;
        roles: UserRoles[];
        submitting: boolean;
    };
}

const initialFormState = (userRole: Props['userRole']): State['form'] => ({
    email: { value: '' },
    submitting: false,
    roles: [userRole],
});

export default function UserList(props: Props) {
    const pageIncrement = 8;
    const selectedOrganizationProviders = useSelector((state: ReduxState) =>
        selectSelectedOrganizationProviders(state)
    );
    const selectedOrganizationOrgAdmins = useSelector((state: ReduxState) =>
        selectSelectedOrganizationOrgAdmins(state)
    );
    const [state, updateState] = useImmer<State>({
        invitingUser: false,
        form: initialFormState(props.userRole),
        inviteStatus: { fetching: false, data: undefined },
        activePage: 1,
        paginatedUsers:
            props.userRole === UserRoles.orgAdmin
                ? selectedOrganizationOrgAdmins.slice(0, pageIncrement)
                : selectedOrganizationProviders.slice(0, pageIncrement),
    });
    const dispatch = useDispatch();
    const toastAlert = useSelector((state: ReduxState) => selectToastAlert(state));
    const currentUser = useContext(CurrentUserContext);
    const navigation = useNavigation(currentUser.claims?.currentOrgId);

    const { role, buttonText, linkTo, placeholderText, toastAlertSuccess, users, formSubmit } = (() => ({
        users:
            props.userRole === UserRoles.provider
                ? selectedOrganizationProviders
                : selectedOrganizationOrgAdmins,
        role: translateUserRole(props.userRole),
        buttonText: props.userRole === UserRoles.provider ? 'Invite Provider' : 'Invite Organization Admin',
        linkTo: (userId: string) =>
            UserRoles.provider
                ? navigation.getProviderDetailsUrl(userId)
                : navigation.getOrgAdminDetailsUrl(userId),
        placeholderText:
            props.userRole === UserRoles.provider
                ? 'This organization does not have any providers'
                : 'This organization does not have any organization admins',
        toastAlertSuccess:
            props.userRole === UserRoles.provider
                ? 'A provider invitation has been sent to the provided email address'
                : 'An organization admin invitation has been sent to the provided email address',
        formSubmit:
            props.userRole === UserRoles.provider
                ? async (request: InviteProviderRequest) => FunctionsManager.invite.provider(request)
                : async (request: InviteOrganizationAdminRequest) =>
                      FunctionsManager.invite.organizationAdmin(request),
    }))();

    const loadMoreUsers = () => {
        updateState(draft => {
            draft.paginatedUsers.push(...users.slice(draft.activePage * pageIncrement));
            draft.activePage += 1;
        });
    };

    const toggleModal = useCallback(() => {
        updateState(draft => void (draft.invitingUser = !draft.invitingUser));
    }, []);

    const handleInput = (e: InputChange) => {
        e.persist();
        updateState(draft => {
            const roleSelection = Number(e.target.value) as UserRoles;
            if (e.target.name === UserFormKeys.email) {
                draft.form.email.value = e.target.value;
            } else {
                draft.form.roles.includes(roleSelection)
                    ? draft.form.roles.splice(
                          draft.form.roles.findIndex(role => role === roleSelection),
                          1
                      )
                    : draft.form.roles.push(roleSelection);
            }
        });
    };

    const handleSubmit = async (e: FormSubmission): Promise<void> => {
        e.preventDefault();
        try {
            const { email } = state.form;
            if (!isValidEmail(email.value)) {
                return updateState(draft => void (draft.form.email.isValid = false));
            }
            updateState(draft => void (draft.form.submitting = true));
            const emailIsEligible = await FunctionsManager.invite.isEligible({
                email: email.value,
                role: props.userRole,
            });
            if (emailIsEligible) {
                let formSubmissionArgs: InviteProviderRequest | InviteOrganizationAdminRequest;
                if (props.userRole === UserRoles.provider) {
                    formSubmissionArgs = { email: email.value, isAlsoOrgAdmin: false };
                    if (state.form.roles.includes(UserRoles.orgAdmin)) {
                        formSubmissionArgs.isAlsoOrgAdmin = true;
                    }
                } else {
                    formSubmissionArgs = {
                        email: email.value,
                        organizationId: currentUser.claims?.currentOrgId!,
                        isAlsoProvider: false,
                    };

                    if (state.form.roles.includes(UserRoles.provider)) {
                        formSubmissionArgs.isAlsoProvider = true;
                    }
                }
                await formSubmit(formSubmissionArgs as any);
                dispatch(setToastAlert(toastAlertSuccess));
                updateState(draft => {
                    draft.form = initialFormState(props.userRole);
                    draft.invitingUser = false;
                });
            } else {
                updateState(draft => void (draft.inviteStatus.error = { emailAlreadyInUse: true }));
            }
        } catch (error) {
            console.log(error);
            dispatch(setToastError('An error occurred while trying to invite the user'));
        }
        updateState(draft => void (draft.form.submitting = false));
    };
    const listItemClassNames = `round-md bg-white hover:bg-${Theme.offWhite} border border-gray-400 px-3 py-4 my-3 rounded-md flex flex-row items-center`;
    return (
        <div className="w-full flex flex-row bg-gray-100 p-2">
            {toastAlert.visible && <ToastAlert message={toastAlert.message} />}
            <div className="mt-2 px-4 py-2 flex flex-col w-3/4">
                <h2 className="text-blue-900 block text-xl font-semibold">{role} List</h2>
                <div className="w-full flex flex-row justify-between items-center mt-3 mb-1 px-2">
                    {!!users.length && <h3 className="text-lg block text-blue-700">Name</h3>}
                    {props.userRole === UserRoles.provider && (
                        <h3 className="text-lg block text-blue-700">Phone Number</h3>
                    )}
                </div>
                <ul className="list-none w-full">
                    {!state.paginatedUsers.length ? (
                        <div className="text-gray-600 py-4">{placeholderText}</div>
                    ) : (
                        state.paginatedUsers.map(user =>
                            user.data.authId ? (
                                <Link key={user.id} to={linkTo(user.id)}>
                                    <div className={`${listItemClassNames} justify-between`}>
                                        <h5 className="block">
                                            {user.data.firstName} {user.data.lastName}
                                        </h5>
                                        {props.userRole === UserRoles.provider && (
                                            <h5 className="block">
                                                {!(user as ProviderDocument).data.phoneNumber ? (
                                                    <span className="text-gray-600">
                                                        No phone number on record
                                                    </span>
                                                ) : (
                                                    formatPhoneNumber(
                                                        (user as ProviderDocument).data.phoneNumber!
                                                    )
                                                )}
                                            </h5>
                                        )}
                                    </div>
                                </Link>
                            ) : (
                                <div
                                    key={user.id}
                                    className={`${listItemClassNames} justify-start text-gray-600`}
                                >
                                    Invite pending for{' '}
                                    <span className={`ml-1 text-${Theme.lightBlue} font-semibold`}>
                                        {user.data.email}
                                    </span>
                                </div>
                            )
                        )
                    )}
                </ul>
                <ActionButton onClick={toggleModal} className="mt-1 w-1/2 lg:w-1/3">
                    {buttonText}
                </ActionButton>
                {state.paginatedUsers.length < users.length && (
                    <div className="mx-auto">
                        <LoadMoreButton
                            onClick={loadMoreUsers}
                            remainingCount={
                                users.length - state.paginatedUsers.length < 10
                                    ? users.length - state.paginatedUsers.length
                                    : 10
                            }
                        />
                    </div>
                )}
            </div>
            <Modal isOpen={state.invitingUser} closeModal={toggleModal}>
                <form className="w-full md:w-7/8 px-4" onSubmit={handleSubmit}>
                    <label className="w-full mb-2 block font-semibold" htmlFor={UserFormKeys.email}>
                        <span className="text-gray-700 block mb-1">Email</span>
                        <p className="text-gray-600 font-normal text-sm block mb-1">
                            An invite to create an account will be sent to this email
                        </p>
                        <div className="relative pb-3">
                            <TextInput
                                className={`py-1 ${
                                    state.inviteStatus.error?.emailAlreadyInUse ||
                                    state.form.email.isValid === false
                                        ? 'border border-red-500'
                                        : ''
                                }`}
                                name={UserFormKeys.email}
                                value={state.form.email.value}
                                onChange={handleInput}
                            />
                            {(state.inviteStatus.error?.emailAlreadyInUse ||
                                state.form.email.isValid === false) && (
                                <p
                                    className="w-full absolute text-xs text-red-500 font-normal bottom-0 left-0 text-center"
                                    style={{ bottom: '-10px' }}
                                >
                                    {state.inviteStatus.error?.emailAlreadyInUse
                                        ? `There is already a ${role} account associated with this email`
                                        : 'Please enter a valid email address'}
                                </p>
                            )}
                        </div>
                    </label>
                    <div className="w-full mb-2 block font-semibold">
                        <span className="text-gray-700 block mb-1">Roles</span>
                        <p className="text-gray-600 font-normal text-sm block my-1">
                            The user will have these roles once they have created their account
                        </p>
                        <label className="text-gray-700 block mb-2" htmlFor={UserFormKeys.roles}>
                            <input
                                type="checkbox"
                                className="form-checkbox cursor-pointer"
                                value={UserRoles.provider}
                                onChange={handleInput}
                                checked={state.form.roles.includes(UserRoles.provider)}
                            />
                            <span className="ml-2">Provider</span>
                        </label>
                        <label className="text-gray-700 block mb-1" htmlFor={UserFormKeys.roles}>
                            <input
                                type="checkbox"
                                className="form-checkbox cursor-pointer"
                                onChange={handleInput}
                                value={UserRoles.orgAdmin}
                                checked={state.form.roles.includes(UserRoles.orgAdmin)}
                            />
                            <span className="ml-2">Organization Admin</span>
                        </label>
                    </div>
                    <SubmitButton loading={state.form.submitting} className="w-full mt-4">
                        Send Invite
                    </SubmitButton>
                </form>
            </Modal>
        </div>
    );
}
