import { EntryDocument } from '../documents/EntryDocument';
import { PatientDocument } from '../documents/PatientDocument';
import { Firestore } from '../FirestoreTypes';
import FirebaseApp from '../../../firestore';
import { UserModel } from './UserModel';
import { OrganizationRoles } from '../schemas/OrganizationRoles';
import { EntrySchema, PatientSchema } from '../schemas/Patient';

export class PatientModel extends UserModel<PatientSchema, PatientDocument> {
    constructor(firestore: Firestore) {
        super(firestore, PatientDocument, 'Patient');
    }

    getAllByOrganization(organizationId: string): Promise<PatientDocument[]> {
        return this.query({
            queries: [
                [`organizations.${organizationId}.roles.${OrganizationRoles.patient}` as any, '==', true],
            ],
        });
    }

    //-- ENTRY OBJECT METHODS --//
    createEntry(patientId: string, data: EntrySchema): Promise<EntryDocument> {
        return this.createDocument<EntrySchema, EntryDocument>(
            EntryDocument,
            `${this.rootCollection}/${patientId}/entries`,
            data
        );
    }
}

export default new PatientModel(FirebaseApp.firestore());
