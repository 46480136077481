import { AlertDocument } from '../../../database/documents/AlertDocument';
import { PatientDocument } from '../../../database/documents/PatientDocument';
import { AlertDashboardData, AlertTableRow, CurrentUserPatientsData } from './PatientsList';
import { AlertTier } from '../../../database/schemas/Patient';
import { OrganizationDocument } from '../../../database/documents/OrganizationDocument';

export const noAccountTablePlaceholder = 'No account created';
export const tablePlaceholder = '---';

export async function deriveAndFormatTierThreeAndInfoAlerts({
    selectedOrganization,
    onlyForCurrentUserPatients,
    currentUserIdAndDelegatorsIds,
}: CurrentUserPatientsData & {
    selectedOrganization: OrganizationDocument;
}): Promise<AlertTableRow[]> {
    const aboveTierTwoAlerts = await selectedOrganization.getActiveTierThreeAndInfoAlerts({
        onlyForCurrentUserPatients,
        currentUserIdAndDelegatorsIds,
    });
    const formattedAlerts: AlertDashboardData = { tierThree: [], informational: [] };
    //local cache
    const patients: { [patientId: string]: PatientDocument } = {};

    const generateAlertTableRow = (rowData: {
        alert: AlertDocument;
        patient: PatientDocument;
    }): AlertTableRow => ({
        acknowledgedAt: null,
        assignedPrescriberId: rowData.patient.data.assignedPrescriber,
        patientPhoneNumber: rowData.patient.data.emergencyPhoneNumber,
        createdAt: rowData.alert.data.createdAt,
        patientName: derivePatientName(rowData.patient),
        alerts: [rowData.alert],
        patientId: rowData.patient.id,
    });

    for (const alert of aboveTierTwoAlerts) {
        //check to see if we've already fetched patient (aka it exists in our local cache)
        let patient = patients[alert.patientId];
        if (!patient) {
            patient = await alert.getPatient();
            patients[alert.patientId] = patient;
        }

        if (alert.data.tier === AlertTier.tierThree) {
            const previousPatientAlert = formattedAlerts.tierThree.find(
                ({ patientId }) => patientId === patient.id
            );
            if (previousPatientAlert) {
                previousPatientAlert.alerts.push(alert);
            } else {
                formattedAlerts.tierThree.push(generateAlertTableRow({ patient, alert }));
            }
        } else {
            const previousPatientAlert = formattedAlerts.informational.find(
                ({ patientId }) => patientId === patient.id
            );
            if (previousPatientAlert) {
                previousPatientAlert.alerts.push(alert);
            } else {
                formattedAlerts.informational.push(generateAlertTableRow({ patient, alert }));
            }
        }
    }

    const sortAlerts = (aTableRow: AlertTableRow, bTableRow: AlertTableRow) => {
        if (aTableRow.alerts.length !== bTableRow.alerts.length) {
            return bTableRow.alerts.length - aTableRow.alerts.length;
        }
        return bTableRow.createdAt.seconds - aTableRow.createdAt.seconds;
    };
    return [...formattedAlerts.tierThree.sort(sortAlerts), ...formattedAlerts.informational.sort(sortAlerts)];
}

function derivePatientName(patientDocument: PatientDocument): string {
    return `${patientDocument.data.lastName}, ${patientDocument.data.firstName}`;
}

export function deriveTableRowDataFromPatients(patients: PatientDocument[]) {
    return patients.map(({ tableRowData }) => tableRowData);
}
