import { DocumentSnapshot } from '../FirestoreTypes';
import { AlertDocument } from './AlertDocument';
import { BaseDocument } from './BaseDocument';
import { ProviderDocument } from './ProviderDocument';
import { PatientDocument } from './PatientDocument';
import { OpioidAgreementSchema, OrganizationSchema } from '../schemas/Organization';
import { OpioidAgreementDocument } from './OpioidAgreementDocument';
import { UserRoles } from '../schemas/User';
import dateFNSAddDays from 'date-fns/addDays';
import dateFNsSubDays from 'date-fns/subDays';
import { getDateString } from '../../utils';
import { PatientQueryRequest, PatientQueryResponse } from '../../functions/userTypes';
import FunctionsManager from '../../functions/FunctionsManager';
import { CurrentUserPatientsData } from '../../pages/patients/patientList/PatientsList';
import { OrganizationUser } from '../../redux/selectedOrganization/selectedOrganizationReducer';

export class OrganizationDocument extends BaseDocument<OrganizationSchema> {
    private opioidAgreementPath = `${this.path}/opioidAgreements`;
    constructor(snapshot: DocumentSnapshot<OrganizationSchema>) {
        super(snapshot, 'Organization');
    }

    async getOrgAdmins(): Promise<OrganizationUser[]> {
        try {
            return await this.db.UserModel.query({
                queries: [
                    ['isOrgAdmin', '==', true],
                    [`organizations.${this.id}.roles.${UserRoles.orgAdmin}` as any, '==', true],
                ],
            });
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'OrganizationDocument.getOrgAdmins()',
                error,
                path: this.path,
            });
            throw error;
        }
    }

    async getUpcomingOpioidAgreementRenewals(delegatorIds: string[]): Promise<PatientDocument[]> {
        try {
            const today = getDateString();
            const thirtyDaysOut = getDateString(dateFNSAddDays(new Date(), 30));
            const thirtyDaysAgo = getDateString(dateFNsSubDays(new Date(), 30));
            const patients = await this.db.PatientModel.query({
                queries: [
                    ['isPatientInOrganization', '==', this.id],
                    ['assignedPrescriber', 'in', delegatorIds],
                    ['opioidAgreement.expirationDate', '>=', today],
                    ['opioidAgreement.expirationDate', '<=', thirtyDaysOut],
                ],
            });
            return patients.filter(patient => patient.data.lastEntryDate >= thirtyDaysAgo);
        } catch (error) {
            console.log(error);
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'OrganizationDocument.getUpcomingOpioidAgreementRenewals()',
                error,
                path: this.path,
            });
            throw error;
        }
    }

    async getProviders(): Promise<ProviderDocument[]> {
        try {
            return await this.db.ProviderModel.getAllByOrganization(this.id);
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'OrganizationDocument.getProviders()',
                error,
                path: this.path,
            });
            throw error;
        }
    }

    async queryPatients(
        queryParams: PatientQueryRequest & CurrentUserPatientsData
    ): Promise<Omit<PatientQueryResponse, 'ids' | 'total'> & { patients: PatientDocument[] }> {
        const query: PatientQueryRequest = {
            orderBy: queryParams.orderBy,
            limit: 10,
        };
        const { currentUserIdAndDelegatorsIds, onlyForCurrentUserPatients, ...params } = queryParams;
        for (const param in params) {
            if (queryParams[param] !== undefined) {
                query[param] = queryParams[param];
            }
        }

        if (onlyForCurrentUserPatients) {
            query.queries = [
                ...(query.queries ?? []),
                ['assignedPrescriber', 'in', queryParams.currentUserIdAndDelegatorsIds],
            ];
        }

        const { ids, remaining } = await FunctionsManager.user.queryPatients(query);
        const patients = await this.getPatients(ids);
        return {
            patients: ids.map(id => patients.find(patient => patient.id === id)!),
            remaining,
        };
    }

    async getProviderById(providerId: string): Promise<ProviderDocument> {
        try {
            return await this.db.ProviderModel.get(providerId);
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'OrganizationDocument.getProviderById()',
                error,
                path: this.path,
            });
            throw error;
        }
    }

    async getPatients(patientIds?: string[]): Promise<PatientDocument[]> {
        try {
            return patientIds
                ? await this.db.PatientModel.getByIds(patientIds)
                : await this.db.PatientModel.getAllByOrganization(this.id);
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'OrganizationDocument.getPatients()',
                error,
                path: this.path,
            });
            throw error;
        }
    }

    createOpioidAgreement(
        data: Omit<OpioidAgreementSchema, 'createdAt' | 'archivedAt'>
    ): Promise<OpioidAgreementDocument> {
        return this.createDocument(OpioidAgreementDocument, this.opioidAgreementPath, {
            ...data,
            archivedAt: null,
        });
    }

    getOpioidAgreement(id: string): Promise<OpioidAgreementDocument> {
        return this.getDocument(OpioidAgreementDocument, `${this.opioidAgreementPath}/${id}`);
    }

    getAllOpioidAgreements(): Promise<OpioidAgreementDocument[]> {
        return this.getAllDocuments(OpioidAgreementDocument, this.opioidAgreementPath);
    }

    async getActiveTierThreeAndInfoAlerts({
        currentUserIdAndDelegatorsIds,
        onlyForCurrentUserPatients,
    }: CurrentUserPatientsData): Promise<AlertDocument[]> {
        try {
            const alerts = await this.queryCollectionGroup(AlertDocument, 'alerts', {
                queries: [
                    ['acknowledgedAt', '==', null],
                    ['tier', 'in', [0, 3]],
                    ['organizationId', '==', this.id],
                ],
            });
            if (!onlyForCurrentUserPatients) {
                return alerts;
            } else {
                const cachedPatientIds: { include: string[]; exclude: string[] } = {
                    include: [],
                    exclude: [],
                };
                const filteredAlerts: AlertDocument[] = [];

                for (let i = 0; i < alerts.length; i++) {
                    if (cachedPatientIds.exclude.includes(alerts[i].patientId)) {
                        continue;
                    }
                    if (cachedPatientIds.include.includes(alerts[i].patientId)) {
                        filteredAlerts.push(alerts[i]);
                        continue;
                    }
                    const alertBelongsToCurrentUserPatient = currentUserIdAndDelegatorsIds.includes(
                        await alerts[i].getAssignedPrescriber()
                    );

                    if (alertBelongsToCurrentUserPatient) {
                        filteredAlerts.push(alerts[i]);
                        cachedPatientIds.include.push(alerts[i].patientId);
                    } else {
                        cachedPatientIds.exclude.push(alerts[i].patientId);
                    }
                }
                return filteredAlerts;
            }
        } catch (error) {
            console.error('ERROR 👇🏼');
            console.dir({
                method: 'PatientDocument.getActiveTierFourAndInformationalAlerts()',
                error,
                path: this.path,
                collectionPath: 'alerts',
                organizationId: this.id,
            });
            throw error;
        }
    }
}
